import React from "react";

const ListingPlaceholder = () => {
  return (
    <div className="cursor-pointer w-full shadow-lg items-center flex flex-col rounded-xl bg-gray-200 transition duration-300">
      <div className="h-48 bg-gray-300 w-full rounded-t-lg object-cover"></div>
      <div className="flex w-full flex-col justify-center p-6">
        <div className="h-4 bg-gray-300 rounded mb-4 animate-shimmer"></div>
        <div className="h-4 w-1/2 bg-gray-300 rounded animate-shimmer"></div>
      </div>
    </div>
  );
};

export default ListingPlaceholder;
