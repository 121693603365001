import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import React from "react";
import { toast } from "react-toastify";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { useTranslation } from "react-i18next";


export default function OAuth() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  async function onGoogleClick() {
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();

      // Add the necessary scope to request the phone number
      provider.addScope(
        "https://www.googleapis.com/auth/user.phonenumbers.read"
      );

      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Extract the phone number from the user info
      const phoneNumber = user.phoneNumber;

      // Check for the user
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);
      const [firstName = "", lastName = ""] = (
        user.displayName || ""
      ).split(" ");


      if (!docSnap.exists()) {
        await setDoc(docRef, {
          firstName: firstName,
          lastName: lastName,
          email: user.email,
          phoneNumber: phoneNumber, // Add the phone number to the user's document
          timestamp: serverTimestamp(),
        });
      }

      navigate("/");
    } catch (error) {
      console.error("Error during Google OAuth:", error);
      if (error.code === "auth/popup-closed-by-user") {
        toast.error("Google popup was closed by the user");
      } else {
         toast.error(t("error.googleAuthFailed"));
      }
    }
  }

  return (
    <button
      type="button"
      onClick={onGoogleClick}
      className="flex items-center justify-center w-full bg-white border border-gray-300 px-7 py-3 uppercase text-sm shadow-md hover:shadow-lg active:shadow-lg transition duration-150 ease-in-out rounded"
    >
      <FcGoogle className="text-sm md:text-base bg-white rounded-full mr-2" />
      Continue with Google
    </button>
  );
}
