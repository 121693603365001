import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { collection, getDocs, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { GrPrevious, GrNext } from "react-icons/gr";
import { toast } from "react-toastify";
import { PiHeartBold, PiHeartFill } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import Spinner from "../components/Spinner";
import Bar from "../components/Bar";




export default function FavoriteListings() {
  const { t } = useTranslation();
  const auth = getAuth();
  const navigate = useNavigate();
  const [favoriteListings, setFavoriteListings] = useState([]);
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const listingsPerPage = 8;

  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListing = indexOfLastListing - listingsPerPage;
  const currentListings = listings.slice(
    indexOfFirstListing,
    indexOfLastListing
  );
  const totalPages = Math.ceil(listings.length / listingsPerPage);

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status

    async function fetchData() {
      try {
        setLoading(true);
        const allFavoriteListings = [];
        const collections = ["users", "agents", "admins"];

        for (const collectionName of collections) {
          const userDocRef = doc(db, collectionName, auth.currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            allFavoriteListings.push(...(userData.favoriteListings || []));
          }
        }

        // Fetch all listings
        const listingRef = collection(db, "listings");
        const querySnap = await getDocs(listingRef);
        const allListings = querySnap.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        // Filter listings based on combined favorite listings
        const userFavoriteListings = allListings.filter(
          (listing) =>
            allFavoriteListings.includes(listing.id) &&
            listing.data.status === "approved"
        );

        if (isMounted) {
          setListings(userFavoriteListings);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    fetchData();

    return () => {
      isMounted = false; // Cleanup flag on unmount
    };
  }, [auth.currentUser.uid]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const getUserRole = async (uid) => {
    // Check users collection
    let userDocRef = doc(db, "users", uid);
    let userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      return { role: "users", userDocRef };
    }

    // Check admins collection
    userDocRef = doc(db, "admins", uid);
    userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      return { role: "admins", userDocRef };
    }

    // Check agents collection
    userDocRef = doc(db, "agents", uid);
    userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      return { role: "agents", userDocRef };
    }

    throw new Error("User role not found");
  };

  const toggleFavorite = async (listingId) => {
    try {
      const uid = auth.currentUser.uid;
      const { userDocRef } = await getUserRole(uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const updatedFavorites = userData.favoriteListings.includes(listingId)
          ? userData.favoriteListings.filter((id) => id !== listingId)
          : [...userData.favoriteListings, listingId];

        await updateDoc(userDocRef, {
          favoriteListings: updatedFavorites,
        });

        setListings((prevListings) =>
          prevListings.filter((listing) => listing.id !== listingId)
        );
      }
    } catch (error) {
      console.error("Error updating favorite listings:", error);
      toast.error("Failed to update favorite listings");
    }
  };
  return (
    <div className="mb-8">
      {loading ? (
        <div className="">
          <Bar loading={loading} />
        </div>
      ) : (
        <div className="max-w-7xl px-8 mt-8 md:mt-16 mx-auto h-full">
          <h2 className="text-xl md:text-3xl font-bold ">
            {" "}
            {t("savedlistings")}
          </h2>
          <div className="items-center mt-2 text-sm md:text-base w-full gap-2 mb-4 flex">
            <div className="w-2 h-2 rounded-full bg-orange-400"></div>
            {t("forrent")}
            <div className="w-2 h-2 rounded-full bg-green-700"></div>
            {t("forsale")}
          </div>
          <section className="bg-gray-50  rounded-xl max-w-7xl border mx-auto flex justify-center items-center flex-col">
            <div className="w-full">
              <div className="mb-4 mt-4 px-4">
                <ul className="">
                  {!loading && listings.length > 0 ? (
                    currentListings.map((listing) => (
                      <li key={listing.id} className="text-xs sm:text-sm">
                        <div className="flex md:px-2 py-2 md:py-6 hover:bg-gray-100 rounded-xl">
                          <div
                            className="flex w-full cursor-pointer "
                            onClick={() =>
                              navigate(`/listingdetails/${listing.id}`)
                            }
                          >
                            <div className="flex  md:w-1/3">
                              <img
                                src={
                                  listing.data.imgUrls ||
                                  "/placeholder-image.jpg"
                                }
                                alt="Listing image"
                                className="w-3/4 lg:w-3/5 h-20 md:h-28 object-cover relative rounded-xl ml-2 mb-2"
                              />
                            </div>
                            <div className="w-1/3 flex flex-col justify-center">
                              <p className="flex text-base sm:text-lg md:text-xl font-semibold capitalize mb-1">
                                {listing.data.listingType}
                              </p>
                              <p className="flex md:hidden capitalize w-2/3 text-gray-700 mb-1">
                                {listing.data.address.slice(0, 10)}
                                {listing.data.address.length > 10 && "..."}
                              </p>
                              <p className="hidden md:flex capitalize w-2/3 text-gray-700 mb-1">
                                {listing.data.address.slice(0, 40)}
                                {listing.data.address.length > 40 && "..."}
                              </p>
                              <p className="flex md:text-black text-black font-semibold">
                                {new Intl.NumberFormat().format(
                                  listing.data.regularPrice
                                )}
                                DH{" "}
                              </p>
                            </div>
                            <div className="flex absolute pl-1 items-center">
                              <span
                                className="flex justify-center items-center border-2 md:border-4 border-gray-100 rounded-full"
                                style={{
                                  backgroundColor:
                                    listing.data.type === "sale"
                                      ? "green"
                                      : "orange",
                                  color:
                                    listing.data.type === "sale"
                                      ? "green"
                                      : "orange",
                                }}
                              >
                                {listing.data.type === "sale" ? (
                                  <div className="text-md w-2 h-2 md:h-3 md:w-3 rounded-full text-green-500" />
                                ) : (
                                  <div className="text-md w-2 h-2 md:h-3 md:w-3 rounded-full text-orange-400" />
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="ml-auto px-8 text-2xl text-right justify-end md:text-4xl transition-all ease-in-out duration-200 hover:opacity-80 flex items-center">
                            {favoriteListings.includes(listing.id) ? (
                              <PiHeartBold
                                className="text-red-500  hover:text-black transition-all ease-in-out duration-200 cursor-pointer"
                                onClick={() => toggleFavorite(listing.id)}
                              />
                            ) : (
                              <PiHeartFill
                                className="text-red-500  hover:text-black transition-all ease-in-out duration-200 cursor-pointer"
                                onClick={() => toggleFavorite(listing.id)}
                              />
                            )}
                          </div>
                        </div>
                        <div className="h-[1px] bg-gray-200 w-full"></div>
                      </li>
                    ))
                  ) : (
                    <div>{t("youhaventsaved")}</div>
                  )}
                </ul>
              </div>
              <div className="hidden md:flex mt-4 mb-4 font-semibold pagination gap-1 justify-center">
                {listings.length > 0 && (
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                )}

                {Array.from({ length: totalPages }, (_, index) => {
                  // Check if the page number is one of the current, previous, or next three pages
                  if (
                    index + 1 === currentPage ||
                    index + 2 === currentPage ||
                    index === currentPage
                  ) {
                    return (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1
                              ? "#ffebeb"
                              : "transparent",
                          border:
                            currentPage === index + 1
                              ? "2px solid red"
                              : "1px solid transparent",
                          color: currentPage === index + 1 ? "black" : "#333",
                          padding: "5px 10px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "40px", // Adjust width as needed for circular buttons
                          height: "40px", // Adjust height as needed for circular buttons
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  // Show ellipses (...) for pages not included in the current, previous, or next three pages
                  else if (index === 0 || index === totalPages - 1) {
                    return (
                      <span key={index} style={{ padding: "5px 10px" }}>
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                {listings.length > 0 && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                )}
              </div>
              <div className="flex md:hidden mt-4 mb-4 font-semibold pagination gap-1 justify-center">
                {listings.length > 0 && (
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                )}

                {Array.from({ length: totalPages }, (_, index) => {
                  // Check if the page number is one of the current, previous, or next three pages
                  if (
                    index + 1 === currentPage ||
                    index + 2 === currentPage ||
                    index === currentPage
                  ) {
                    return (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1
                              ? "#ffebeb"
                              : "transparent",
                          border:
                            currentPage === index + 1
                              ? "2px solid red"
                              : "1px solid transparent",
                          color: currentPage === index + 1 ? "black" : "#333",
                          padding: "5px 10px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "30px", // Adjust width as needed for circular buttons
                          height: "30px", // Adjust height as needed for circular buttons
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  // Show ellipses (...) for pages not included in the current, previous, or next three pages
                  else if (index === 0 || index === totalPages - 1) {
                    return (
                      <span key={index} style={{ padding: "5px 10px" }}>
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                {listings.length > 0 && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                )}
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}
