import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  TextField,
  Typography,
  Grid,
  CircularProgress,
  Button, 
} from "@mui/material";

const FinancingCalculator = ({ regularPrice }) => {
  const { t } = useTranslation();
  const [downPaymentAmount, setDownPaymentAmount] = useState(
    regularPrice * 0.2
  );
  const [downPaymentPercent, setDownPaymentPercent] = useState(20);
  const [loanTerm, setLoanTerm] = useState(30);
  const [interestRate, setInterestRate] = useState(4.5);

  const calculateMonthlyPayment = () => {
    const principal = regularPrice - downPaymentAmount;
    const monthlyInterestRate = interestRate / 100 / 12;
    const numberOfPayments = loanTerm * 12;

    const monthlyPayment =
      (principal * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

    return (Math.round(monthlyPayment * 10) / 10).toFixed(1);
  };

  const handleDownPaymentAmountChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setDownPaymentAmount(value);
    setDownPaymentPercent(((value / regularPrice) * 100).toFixed(2));
  };

  const handleDownPaymentPercentChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setDownPaymentPercent(value);
    setDownPaymentAmount((regularPrice * value) / 100);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("fr-MA", {
      style: "currency",
      currency: "MAD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(amount)
      .replace(".", ",");
  };

  const monthlyPayment = parseFloat(calculateMonthlyPayment());
  const taxAmount = monthlyPayment * 0.2;
  const paymentWithoutTax = monthlyPayment - taxAmount;

  return (
    <Box
      className="financing-calculator flex flex-col md:flex-row items-center justify-between w-full bg-gray-50 mb-8"
      borderColor="gray.300"
      borderRadius={4}
      p={2}
    >
      <Box className="md:w-1/2">
        <Typography variant="h6" marginBottom={1}>
          {t("financingCalculator.title")}
        </Typography>

        <Grid container spacing={2} marginTop={1} marginBottom={2}>
          {/* Unmodifiable Property Price Field */}
          <Grid item xs={12}>
            <TextField
              className="bg-white"
              label={t("financingCalculator.propertyPriceLabel")}
              type="number"
              value={regularPrice}
              fullWidth
              disabled
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              className="bg-white"
              label={t("financingCalculator.downPaymentLabel")}
              type="number"
              value={downPaymentAmount}
              onChange={handleDownPaymentAmountChange}
              fullWidth
              variant="outlined"
              inputProps={{ min: 0, max: regularPrice }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className="bg-white"
              label="%"
              type="number"
              value={downPaymentPercent}
              onChange={handleDownPaymentPercentChange}
              fullWidth
              variant="outlined"
              inputProps={{ min: 0, max: 100 }}
            />
          </Grid>
        </Grid>

        <TextField
          className="bg-white"
          label={t("financingCalculator.loanTermLabel")}
          type="number"
          value={loanTerm}
          onChange={(e) => setLoanTerm(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
          inputProps={{ min: 1 }}
        />

        <TextField
          className="bg-white"
          label={t("financingCalculator.interestRateLabel")}
          type="number"
          step="0.01"
          value={interestRate}
          onChange={(e) => setInterestRate(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
          inputProps={{ min: 0 }}
        />
      </Box>
      {/* Rest of the code remains the same */}
      <Box className="md:w-1/2">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
        >
          <Box position="relative" display="inline-flex">
            <CircularProgress
              variant="determinate"
              value={100}
              size={145}
              thickness={3}
              style={{ color: "#de0000" }}
            />
            <CircularProgress
              variant="determinate"
              value={(taxAmount / monthlyPayment) * 100}
              size={145}
              thickness={3}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                color: "#ab0000",
              }}
            />
            <Typography
              variant="h6"
              component="div"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {formatCurrency(monthlyPayment)}
            </Typography>
            <Typography
              variant="h10"
              component="div"
              style={{
                position: "absolute",
                top: "62%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {t("monthly")}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          marginTop={1}
        >
          <Box display="flex" alignItems="center">
            <Box
              bgcolor="#de0000"
              borderRadius="50%"
              width={10}
              height={10}
              marginRight={1}
            />
            <Typography variant="body2" style={{ marginRight: 8 }}>
              {t("pricewithouttax")}: {formatCurrency(paymentWithoutTax)}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box
              bgcolor="#ab0000"
              borderRadius="50%"
              width={10}
              height={10}
              marginRight={1}
            />
            <Typography variant="body2" style={{ marginRight: 8 }}>
              {t("tax")}: {formatCurrency(taxAmount)}
            </Typography>
          </Box>
          <p className="mt-8 px-4 text-gray-400 text-center text-small">{t("estimateDisclaimer")}</p>
          <div className="hidden">
          {/* Bank Link Button */}
          <Box display="flex" justifyContent="center" marginTop={2}>
            <Button
              variant="contained"
              href="https://www.credithabitat.ma/cr%C3%A9dit-immobilier"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                backgroundColor: "#de0000",
                color: "#fff",
                fontSize: "0.8rem", // Smaller text size
              }}
            >
              {t("financingCalculator.bankOfferButton", "Discover Offer")}
            </Button>
          </Box>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default FinancingCalculator;
