import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { useAuthStatus } from "../Hooks/useAuthStatus";
import Bar from "../components/Bar";
import SignInModal from "../components/SignInModal"; // Import the modal component

export default function Unverified() {
  const {loggedIn, checkingStatus } = useAuthStatus();
  const [showModal, setShowModal] = useState(!loggedIn); // Show modal if not logged in

  if (checkingStatus) {
    return <Bar />;
  }

  return (
    <>
      {!loggedIn && (
        <SignInModal showModal={showModal} setShowModal={setShowModal} />
      )}
      <Outlet />
    </>
  );
}
