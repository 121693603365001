import {
  getAuth,
  updateProfile,
  onAuthStateChanged,
  sendEmailVerification,
} from "firebase/auth";
import {
  collection,
  deleteDoc,
  addDoc,
  serverTimestamp,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { TextField, Typography } from "@mui/material";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { PiSignOutBold } from "react-icons/pi";
import { FormControl, InputLabel, MenuItem, Select, Chip } from "@mui/material";
import { LiaUserEditSolid } from "react-icons/lia";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GrPrevious, GrNext } from "react-icons/gr";
import { MdDelete, MdEdit } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Spinner from "../components/Spinner";
import { IoClose } from "react-icons/io5";
import { RxDot } from "react-icons/rx";
import Bar from "../components/Bar";
import { FaCheck } from "react-icons/fa";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
} from "@mui/material";
import { RxCross2 } from "react-icons/rx";




export default function Profile() {
  const { t } = useTranslation();
  const auth = getAuth();
  const navigate = useNavigate();
  const [changeDetail, setChangeDetail] = useState(false);
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasReviews, setHasReviews] = useState(false);
  const [hasApprovedListing, setHasApprovedListing] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [aboutMe, setAboutMe] = useState("");
  const [specialtiesDisabled, setSpecialtiesDisabled] = useState(true);
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const [agentType, setAgentType] = useState([]);
  const [selectedSection, setSelectedSection] = useState("profile");
  const handleSpecialtiesChange = (event) => {
    const selectedSpecialties = event.target.value;
    if (selectedSpecialties.length <= 3) {
      setSelectedSpecialties(selectedSpecialties);
    } else {
      toast.error("You can only choose up to three specialties");
    }
  };
  const [teamId, setTeamId] = useState(null);
  const [openTeam, setOpenTeam] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [teamAgents, setTeamAgents] = useState([]);
  const [isInTeam, setIsInTeam] = useState(false);
  const [agents, setAgents] = useState([]); // Fetched agents
  const handleOpenTeam = () => {
    setOpenTeam(true);
  };

  const handleCloseTeam = () => {
    setOpenTeam(false);
  };
  const [phoneError, setPhoneError] = useState("");
  const [isTipVisible, setIsTipVisible] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [isTheLeader, setIsTheLeader] = useState(false);
  const [pendingInvites, setPendingInvites] = useState(null);
  const [receivedInvites, setReceivedInvites] = useState([]);



  const handleTipClose = () => {
    setIsTipVisible(false);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const specialties = [
    "residential",
    "commercial",
    "luxury",
    "property",
    "investment",
    "construction",
    "vacation",
    "land",
    "foreclosures",
    "development",
  ];

  const handleDelete = (value) => {
    setSelectedSpecialties((prevSelected) =>
      prevSelected.filter((selected) => selected !== value)
    );
  };

  const [isVerified, setIsVerified] = useState(false);
  const [user, setUser] = useState(null);
  const [sendingVerification, setSendingVerification] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setIsVerified(user.emailVerified);
      } else {
        setUser(null);
        setIsVerified(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleResendVerification = async () => {
    if (!user) return;
    setSendingVerification(true);
    setError("");
    try {
      await sendEmailVerification(user);
      setEmailSent(true);
    } catch (error) {
      setError(t("failedemail"));
    } finally {
      setSendingVerification(false);
    }
  };

  const [citiesDisabled, setCitiesDisabled] = useState(true);
  const [selectedCities, setSelectedCities] = useState([]);
  const handleCitiesChange = (event) => {
    const selectedCities = event.target.value;

    if (selectedCities.length <= 3) {
      setSelectedCities(selectedCities);
    } else {
      toast.error("You can only choose up to three Cities");
    }
  };

  const cities = [
    "Agadir",
    "Al Hoceima",
    "Azemmour",
    "Beni Mellal",
    "Boujdour",
    "Casablanca",
    "Chefchaouen",
    "Dakhla",
    "El Jadida",
    "Erfoud",
    "Essaouira",
    "Fes",
    "Fnideq",
    "Guelmim",
    "Ifrane",
    "Kénitra",
    "Khouribga",
    "Laayoune",
    "Larache",
    "Marrakech",
    "Meknes",
    "Mohammedia",
    "Nador",
    "Ouarzazate",
    "Oujda",
    "Rabat",
    "Safi",
    "Salé",
    "Tangier",
    "Taza",
    "Tétouan",
    "Tiznit",
  ];
  const handleCitiesDelete = (value) => {
    setSelectedCities((prevSelected) =>
      prevSelected.filter((selected) => selected !== value)
    );
  };
  const [languageDisabled, setLanguageDisabled] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;

    if (selectedLanguage.length <= 3) {
      setSelectedLanguage(selectedLanguage);
    } else {
      toast.error("You can only choose up to three Language");
    }
  };

  const language = ["arabic", "tamazight", "english", "french", "spanish"];
  const handleLanguageDelete = (value) => {
    setSelectedLanguage((prevSelected) =>
      prevSelected.filter((selected) => selected !== value)
    );
  };

  const [formData, setFormData] = useState({
    firstName: auth.currentUser.displayName.split(" ")[0] || "",
    lastName: auth.currentUser.displayName.split(" ")[1] || "",
    agency: auth.currentUser.displayName.split(" ")[2] || "",
    email: auth.currentUser.email,
    phoneNumber: "",
    photoURL: auth.currentUser.photoURL || "",
    aboutMe: "",
    instagram: "",
    facebook: "",
    youtube: "",
    youtubeVideo: "",
  });

  const { firstName, lastName, agency, email, photoURL } = formData;
  const [isAgentUser, setIsAgentUser] = useState(false);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [userStatus, setUserStatus] = useState(null);
  const [isMyProfileOpen, setIsMyProfileOpen] = useState(false);

  useEffect(() => {
    let isMounted = true; // Track if the component is mounted

    async function fetchData() {
      try {
        setLoading(true);
        const userUid = auth.currentUser.uid;

        // Check if the user is an agent
        const agentStatus = await isAgent();
        if (isMounted) setIsAgentUser(agentStatus);

        // Check if the user is an admin
        const adminStatus = await isAdmin();
        if (isMounted) setIsAdminUser(adminStatus);

        let userDocRef;
        if (isAdminUser) {
          userDocRef = doc(db, "admins", userUid);
        } else if (isAgentUser) {
          userDocRef = doc(db, "agents", userUid);
        } else {
          userDocRef = doc(db, "users", userUid);
        }

        const userDoc = await getDoc(userDocRef);

        if (isMounted) {
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const status = userData.status;

            setUserStatus(status);
            setAboutMe(userData.aboutMe || "");
            setSelectedSpecialties(userData.specialties || []);
            setSelectedLanguage(userData.language || []);
            setSelectedCities(userData.cities || []);
            setAgentType(userData.agentType || []);

            // Set social media links
            setFormData({
              ...formData,
              instagram: userData.instagram || "",
              facebook: userData.facebook || "",
              youtube: userData.youtube || "",
              youtubeVideo: userData.youtubeVideo || "",
            });

            const userPhoneNumber = userData.phoneNumber;
            if (!userPhoneNumber) {
              setPhoneError(t("pleaseenteryourphone"));
            } else {
              setPhoneNumber(userPhoneNumber);
            }
            // Fetch reviews for the agent if the user is an agent
            if (isAgentUser) {
              const reviewsRef = collection(db, "agent_reviews");
              const q = query(reviewsRef, where("agentId", "==", userUid));
              const querySnap = await getDocs(q);
              const hasReviews = !querySnap.empty; // Check if there are any reviews
              setHasReviews(hasReviews); // Store the result in state if needed
            }
            // Fetch user listings to check for approved status
            const listingRef = collection(db, "listings");
            const listingQuery = query(
              listingRef,
              where("userRef", "==", userUid),
              where("status", "==", "approved") // Check for approved status
            );
            const listingQuerySnap = await getDocs(listingQuery);
            const hasApprovedListings = !listingQuerySnap.empty; // Check if there are any approved listings
            setHasApprovedListing(hasApprovedListings); // Store the result in state if needed
          } else {
            setUserStatus(null);
          }

          // Fetch user listings
          const listingRef = collection(db, "listings");
          const q = query(
            listingRef,
            where("userRef", "==", userUid),
            orderBy("timestamp", "desc")
          );
          const querySnap = await getDocs(q);
          let listings = [];
          querySnap.forEach((doc) => {
            listings.push({
              id: doc.id,
              data: doc.data(),
            });
          });
          if (isMounted) setListings(listings);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        if (isMounted) setLoading(false);
      }
    }

    fetchData();

    return () => {
      isMounted = false; // Clean up the mounted flag
    };
  }, [isAgentUser, isAdminUser, t]);

  useEffect(() => {
    const fetchTeamInfo = async () => {
      try {
        const userUid = auth.currentUser.uid;

        // First, query to check if the user is the leader
        const leaderQuery = query(
          collection(db, "teams"),
          where("leader", "==", userUid) // Check if the user is the leader
        );
        const leaderSnap = await getDocs(leaderQuery);

        if (!leaderSnap.empty) {
          setIsInTeam(true);
          const teamData = leaderSnap.docs[0].data();
          const teamId = leaderSnap.docs[0].id;
          setTeamId(teamId);
          setTeamName(teamData.name || "");

          // Ensure the leader is included in the team agents
          const agentIds = [teamData.leader, ...teamData.agentIds]; // Include leader in the list
          const uniqueAgentIds = [...new Set(agentIds)]; // Remove duplicates, if any

          const agentDocs = await Promise.all(
            uniqueAgentIds.map((agentId) => getDoc(doc(db, "agents", agentId)))
          );

          const agentsList = agentDocs.map((doc) => {
            const agentData = doc.data();
            const agentId = doc.id; // Get the agentId from the document

            return {
              id: agentId,
              name: `${agentData.firstName} ${agentData.lastName}`,
              profilePicture: agentData?.photoURL || "/placeholder.png",
              isLeader: teamData.leader === agentId, // Check if this agent is the leader
            };
          });

          setPendingInvites(teamData.pendingInvites || []);
          setIsTheLeader(true);
          setTeamAgents(agentsList);
        } else {
          // If the user is not the leader, query if they are just an agent
          const agentQuery = query(
            collection(db, "teams"),
            where("agentIds", "array-contains", userUid)
          );
          const agentSnap = await getDocs(agentQuery);

          if (!agentSnap.empty) {
            setIsInTeam(true);
            const teamData = agentSnap.docs[0].data();
            const teamId = agentSnap.docs[0].id;
            setTeamId(teamId);
            setTeamName(teamData.name || "");

            // Ensure the leader is included in the team agents
            const agentIds = [teamData.leader, ...teamData.agentIds]; // Include leader in the list
            const uniqueAgentIds = [...new Set(agentIds)]; // Remove duplicates, if any

            const agentDocs = await Promise.all(
              uniqueAgentIds.map((agentId) =>
                getDoc(doc(db, "agents", agentId))
              )
            );

            const agentsList = agentDocs.map((doc) => {
              const agentData = doc.data();
              const agentId = doc.id; // Get the agentId from the document

              return {
                id: agentId,
                name: `${agentData.firstName} ${agentData.lastName}`,
                profilePicture: agentData?.photoURL || "/placeholder.png",
                isLeader: teamData.leader === agentId, // Check if this agent is the leader
              };
            });

            setTeamAgents(agentsList);
          } else {
            setIsInTeam(false);
          }
        }
      } catch (error) {
        console.error("Error fetching team info:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamInfo();
  }, []);


  useEffect(() => {
    async function fetchAgents() {
      const agentsSnap = await getDocs(collection(db, "agents"));
      const agentsList = agentsSnap.docs.map((doc) => ({
        id: doc.id,
        status: doc.data().status,
        agentType: doc.data().agentType,
        name: `${doc.data().firstName} ${doc.data().lastName}`,
        profilePicture: doc.data()?.photoURL || "/placeholder.png",
      }));
      // Sort agents alphabetically by full name
      agentsList.sort((a, b) => a.name.localeCompare(b.name));
      setAgents(agentsList);
    }
    fetchAgents();
  }, []);

  // Handle agent selection
  const handleAgentSelection = (event) => {
    const { value } = event.target;
    setSelectedAgents(typeof value === "string" ? value.split(",") : value);
  };

  const handleCreateTeam = async () => {
    if (!teamName.trim()) {
      alert("Please enter a team name.");
      return;
    }

    try {
      // Add the new team to the database
      const teamRef = await addDoc(collection(db, "teams"), {
        name: teamName,
        leader: auth.currentUser.uid, // Assuming currentUser is defined
        agentIds: selectedAgents,
        pendingInvites: [], // Initialize with no pending invites
        createdAt: serverTimestamp(),
      });

      const newTeamId = teamRef.id; // Get the ID of the newly created team
      const newTeam = {
        id: newTeamId,
        name: teamName,
        leader: auth.currentUser.uid,
        agentIds: selectedAgents,
      };

      // Update the local state immediately
      setIsInTeam(true);
      setIsTheLeader(true);
      setTeamId(newTeamId);
      setTeamName(teamName);
      setPendingInvites([]); // Initialize empty invites
      setTeamAgents([
        ...selectedAgents.map((agentId) => ({
          id: agentId,
          name: "Fetching...", // Placeholder name (optional)
          profilePicture: "/placeholder.png", // Placeholder picture
        })),
        {
          id: auth.currentUser.uid,
          name: "You (Leader)", // Display the current user as the leader
          profilePicture: auth.currentUser.photoURL || "/placeholder.png",
          isLeader: true,
        },
      ]);

      alert("Team created successfully!");
      handleCloseTeam(); // Close the dialog and reset state
    } catch (error) {
      console.error("Error creating team:", error);
      alert("Failed to create team. Please try again.");
    }
  };


  const handleDeleteAgent = async (agentId) => {
    if (!isTheLeader) return;

    try {
      const teamRef = doc(db, "teams", teamId); // Ensure teamId is accessible
      const teamSnapshot = await getDoc(teamRef);

      if (!teamSnapshot.exists()) {
        alert("Team not found.");
        return;
      }

      const teamData = teamSnapshot.data();
      const leaderId = teamData.leader; // Get the leader's ID

      // Ensure that the leader's ID is not removed
      if (agentId === leaderId) {
        alert("You cannot remove the team leader.");
        return;
      }

      const updatedAgentIds = teamData.agentIds.filter((id) => id !== agentId);

      // Update the team document
      await updateDoc(teamRef, {
        agentIds: updatedAgentIds,
      });

      // Update local state
      setTeamAgents((prev) => prev.filter((agent) => agent.id !== agentId));
      alert("Agent removed successfully.");
    } catch (error) {
      console.error("Error deleting agent:", error);
      alert("Failed to delete agent. Please try again.");
    }
  };


const handleAddAgents = async () => {
  if (!selectedAgents.length) {
    alert("Please select at least one agent to invite.");
    return;
  }

  try {
    const teamRef = doc(db, "teams", teamId); // Ensure teamId is accessible

    // Update the team document in Firestore by adding agents to pendingInvites
    await updateDoc(teamRef, {
      pendingInvites: arrayUnion(...selectedAgents),
    });

    // Fetch the details of the selected agents
    const agentDocs = await Promise.all(
      selectedAgents.map((agentId) => getDoc(doc(db, "agents", agentId)))
    );

    const invitedAgents = agentDocs.map((doc) => ({
      id: doc.id,
      name: doc.data().name, // Fetch the agent's name
      profilePicture: doc.data().profilePicture || "/placeholder.png", // Optional profile picture
    }));

    // Update local `agents` state with the newly invited agents
    setAgents((prev) => [...prev, ...invitedAgents]);

    // Update local pendingInvites state immediately
    setPendingInvites((prev) => [...prev, ...selectedAgents]);

    // Clear selected agents
    setSelectedAgents([]);

    alert("Invitations sent successfully!");
  } catch (error) {
    console.error("Error adding agents to pending invites:", error);
    alert("Failed to send invitations. Please try again.");
  }
};

  useEffect(() => {
    const fetchReceivedInvites = async () => {
      try {
        const userUid = auth.currentUser.uid;

        // Query teams where the user's uid is in pendingInvites
        const invitesQuery = query(
          collection(db, "teams"),
          where("pendingInvites", "array-contains", auth.currentUser.uid)
        );

        const invitesSnap = await getDocs(invitesQuery);
        const invites = invitesSnap.docs.map((doc) => ({
          id: doc.id, // Team ID
          name: doc.data().name, // Team Name
          leader: doc.data().leader, // Team Leader
        }));

        setReceivedInvites(invites);
      } catch (error) {
        console.error("Error fetching received invites:", error);
      }
    };

    fetchReceivedInvites();
  }, []);

  const inviteCount = receivedInvites.length;


  const handleAcceptInvite = async (teamId) => {
    if (isInTeam) {
      alert(t("leaveTeamAlert"));
      return;
    }

    try {
      const userUid = auth.currentUser.uid;
      const teamRef = doc(db, "teams", teamId);

      await updateDoc(teamRef, {
        pendingInvites: arrayRemove(userUid), // Remove from pendingInvites
        agentIds: arrayUnion(userUid), // Add to agentIds
      });

      // Update the UI to show that the user is part of the team
      setIsInTeam(true);
      setTeamId(teamId);
      setReceivedInvites((prev) =>
        prev.filter((invite) => invite.id !== teamId)
      );
      alert("Invite accepted successfully!");

      // Fetch updated team information and agents
      const teamSnap = await getDoc(teamRef);
      const teamData = teamSnap.data();
      setTeamName(teamData.name || "");

      const agentDocs = await Promise.all(
        [...new Set([teamData.leader, ...teamData.agentIds])].map((agentId) =>
          getDoc(doc(db, "agents", agentId))
        )
      );
      const agentsList = agentDocs.map((doc) => {
        const agentData = doc.data();
        return {
          id: doc.id,
          name: `${agentData.firstName} ${agentData.lastName}`,
          profilePicture: agentData?.photoURL || "/placeholder.png",
          isLeader: teamData.leader === doc.id,
        };
      });
      setTeamAgents(agentsList);
    } catch (error) {
      console.error("Error accepting invite:", error);
    }
  };


  const handleRefuseInvite = async (teamId) => {
    try {
      const userUid = auth.currentUser.uid;
      const teamRef = doc(db, "teams", teamId);

      await updateDoc(teamRef, {
        pendingInvites: arrayRemove(userUid), // Remove from pendingInvites
      });

      // Update the UI
      setReceivedInvites((prev) =>
        prev.filter((invite) => invite.id !== teamId)
      );
      alert("Invite refused.");
    } catch (error) {
      console.error("Error refusing invite:", error);
    }
  };

const handleDeleteTeam = async () => {
  if (!isTheLeader) {
    alert("Only the leader can delete the team.");
    return;
  }

  if (
    window.confirm(
      "Are you sure you want to delete this team? This action is permanent."
    )
  ) {
    try {
      // Delete the team document from Firestore
      await deleteDoc(doc(db, "teams", teamId));
      setIsInTeam(false);


      alert("Team deleted successfully.");
      handleCloseTeam(); // Close the dialog or reset state if needed
    } catch (error) {
      console.error("Error deleting team:", error);
      alert("Failed to delete the team. Please try again.");
    }
  }
};


 const handleLeaveTeam = async () => {
   try {
     const userUid = auth.currentUser?.uid;
    console.log("teamId:", teamId);


     const teamRef = doc(db, "teams", teamId);

     // Remove the user's UID from the agentIds array in the team document
     await updateDoc(teamRef, {
       agentIds: arrayRemove(userUid),
     });

     // Optionally, update the UI by removing the user from the team
     setTeamAgents((prevAgents) =>
       prevAgents.filter((agent) => agent.id !== userUid)
     );
     setIsInTeam(false); // Update state to reflect that the user is no longer in the team

     alert("You have successfully left the team!");
   } catch (error) {
     console.error("Error leaving team:", error);
   }
 };








  


  function onLogout() {
    auth.signOut();
    window.scrollTo(0, 0); // Scroll to the top
    navigate("/");
  }

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    // Handle changes to phone number
    if (e.target.id === "phoneNumber") {
      setPhoneNumber(e.target.value);
    }
  }

  async function onSubmit() {
    try {
      const usersCollection = isAgentUser ? "agents" : "users";
      const currentUserUid = auth.currentUser.uid;

      // Fetch the current user's document to get the current phone number
      const userDocRef = doc(db, usersCollection, currentUserUid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const currentPhoneNumber = userDocSnapshot.data().phoneNumber;

        // Check if the phone number has actually been changed
        if (currentPhoneNumber !== phoneNumber) {
          // Query the database to see if the new phone number exists
          const existingUsersQuery = query(
            collection(db, usersCollection),
            where("phoneNumber", "==", phoneNumber)
          );
          const querySnapshot = await getDocs(existingUsersQuery);

          if (!querySnapshot.empty) {
            // Phone number already exists
            setPhoneError(t("phonealreadyexists")); // Set error message using translation function
            return; // Exit the function early
          }
        }
      }

      const displayName = `${firstName} ${lastName} ${agency}`;

      // Update display name in Firebase Auth
      await updateProfile(auth.currentUser, {
        displayName,
      });

      // Update the user's information in Firestore
      await updateDoc(userDocRef, {
        firstName,
        lastName,
        agency,
        phoneNumber,
        aboutMe,
        instagram: formData.instagram,
        facebook: formData.facebook,
        youtube: formData.youtube,
        youtubeVideo: formData.youtubeVideo,
        specialties: selectedSpecialties,
        language: selectedLanguage,
        cities: selectedCities,
      });

      toast.success("Profile details updated");
    } catch (error) {
toast.error(t("error.profileUpdateFailed"));
    }
  }

  const isAgent = async () => {
    const agentDocRef = doc(db, "agents", auth.currentUser.uid);
    const agentDoc = await getDoc(agentDocRef);
    return agentDoc.exists();
  };

  const isAdmin = async () => {
    const adminDocRef = doc(db, "admins", auth.currentUser.uid);
    const adminDoc = await getDoc(adminDocRef);
    return adminDoc.exists();
  };

  const renderGreeting = () => {
    const displayName = `${firstName}${" "}${lastName}`;

    const handleImageChange = async (e) => {
      const file = e.target.files[0];
      setSelectedImage(file);

      if (file) {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `profilePictures/${auth.currentUser.uid}`
        );
        await uploadBytes(storageRef, file);
        const imageUrl = await getDownloadURL(storageRef);

        // Update the user's profile picture in Firestore
        const userDocRef = doc(
          db,
          isAgentUser ? "agents" : "users",
          auth.currentUser.uid
        );
        await updateDoc(userDocRef, {
          photoURL: imageUrl,
        });

        // Update the local state
        setFormData((prevState) => ({
          ...prevState,
          photoURL: imageUrl,
        }));

        // Update the current user's photoURL in the auth object
        await updateProfile(auth.currentUser, {
          photoURL: imageUrl,
        });

        toast.success("Profile picture updated");
      }
    };

    if (isAgentUser) {
      return (
        <div className="flex flex-col justify-center items-center w-full mx-auto">
          <div className="flex mt-10">
            <div className="relative flex items-end ">
              <img
                src={
                  photoURL ||
                  "https://firebasestorage.googleapis.com/v0/b/novio-91bc4.appspot.com/o/AnonPFP.png?alt=media&token=7116463e-8872-4869-8099-865f7def8f0c"
                }
                alt="Profile Picture"
                className="h-20 w-20 shadow-lg shadow-gray-400 rounded-full object-cover mr-2"
              />
              <label
                htmlFor="imageInput"
                className="absolute h-6 w-6 top-0 right-2 shadow-lg text-black items-center justify-center bg-white rounded-full p-1 cursor-pointer"
              >
                <input
                  type="file"
                  id="imageInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                <LiaUserEditSolid className="ml-[1px] h-4 w-4 justify-center mx-auto  hover:opacity-40 " />
              </label>
            </div>
          </div>
          {userStatus !== "approved" && (
            <p className="flex relative items-center gap-1 md:text-2xl ">
              <strong>{displayName}</strong>
              <RiVerifiedBadgeFill
                className="absolute -right-5 text-base md:text-lg"
                style={{ color: "orange" }}
              />
            </p>
          )}

          {userStatus === "approved" && (
            <p className="flex relative items-center gap-1 md:text-2xl ">
              <strong>{displayName}</strong>
              <RiVerifiedBadgeFill
                className="absolute -right-5 text-base md:text-lg"
                style={{ color: "red" }}
              />
            </p>
          )}

          {userStatus === "approved" && (
            <p className="flex items-center text-sm">
              {t(
                agentType === "agent"
                  ? "verifiedagent"
                  : agentType === "builder"
                  ? "verifiedbuilder"
                  : agentType === "developer"
                  ? "verifieddeveloper"
                  : agentType === "notary"
                  ? "verifiednotary"
                  : agentType === "architect"
                  ? "verifiedarchitect"
                  : agentType === "photographer"
                  ? "verifiedphotographer"
                  : agentType === "renovator"
                  ? "verifiedrenovator"
                  : ""
              )}
            </p>
          )}
          {userStatus !== "approved" && (
            <p className="flex items-center gap-1 text-sm md:text-base">
              {t("pendingverification")}{" "}
            </p>
          )}
        </div>
      );
    } else {
      return (
        <div className="flex mt-10 flex-col justify-center items-center w-full mx-auto">
          <div className="flex relative">
            <div className="relative flex items-end">
              {" "}
              <img
                src={
                  photoURL ||
                  "https://firebasestorage.googleapis.com/v0/b/novio-91bc4.appspot.com/o/AnonPFP.png?alt=media&token=7116463e-8872-4869-8099-865f7def8f0c"
                }
                alt="Profile Picture"
                className="h-20 w-20 rounded-full object-cover mr-2"
              />
            </div>
          </div>
          <p className="md:text-2xl mt-2">
            <strong>{displayName}</strong>
          </p>
        </div>
      );
    }
  };

  return (
    <div className="bg-gray-50 ">
      {loading ? (
        <div className="">
          <Bar loading={loading} />
        </div>
      ) : (
        <div className="max-w-7xl mx-auto h-full">
          <section className=" max-w-7xl mx-auto flex justify-center items-center flex-col">
            <div className="w-full px-2">
              {isVisible && (
                <>
                  {!isVerified && (
                    <div className="border-2 mx-auto bg-white items-center justify-center text-center flex flex-col my-8 rounded-xl space-y-2 p-4">
                      <div className="w-full ml-auto flex justify-end text-xl">
                        <IoClose
                          onClick={handleClose}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <p className="font-semibold md:text-lg text-gray-600">
                        {t("verifyyouremail")}
                      </p>
                      <p className="text-sm  md:text-md text-gray-600">
                        {t("verifyemail")}
                      </p>
                      {emailSent ? (
                        <p className="text-green-600">{t("emailsent")}</p>
                      ) : (
                        <button
                          onClick={handleResendVerification}
                          disabled={sendingVerification}
                          className="md:w-1/3  text-red-600 hover:text-red-700 px-4 py-2 rounded"
                        >
                          {sendingVerification ? t("sending") : t("resend")}
                        </button>
                      )}
                      {error && <p className="text-red-600">{error}</p>}
                    </div>
                  )}
                </>
              )}
              {isVerified && (
                <>
                  {isAgentUser && agentType === "agent" && (
                    <>
                      {isTipVisible &&
                        (!photoURL || !hasReviews || !hasApprovedListing) && (
                          <div className="w-full rounded-xl border-2 bg-white my-8">
                            <div className="w-full ml-auto flex justify-end text-lg md:text-xl p-4">
                              <IoClose
                                onClick={handleTipClose}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            <div className="px-4 mb-4 text-xs sm:text-sm text-gray-400">
                              <p className="text-lg text-red-500 font-semibold mb-2">
                                {t("tipsforincreasing")} !
                              </p>
                              <p
                                className={`flex gap-1 items-center ${
                                  photoURL ? "text-red-500" : ""
                                }`}
                              >
                                {photoURL && <FaCheck />}
                                {!photoURL && <RxCross2 />}
                                {t(
                                  agentType === "builder" ||
                                    agentType === "homeimprovement" ||
                                    agentType === "developer"
                                    ? "tipsforincreasing4"
                                    : "tipsforincreasing1"
                                )}
                              </p>
                              <p
                                className={`flex gap-1 items-center ${
                                  hasApprovedListing ? "text-red-500" : ""
                                }`}
                              >
                                {hasApprovedListing && <FaCheck />}
                                {!hasApprovedListing && <RxCross2 />}

                                {t("tipsforincreasing3")}
                              </p>
                              <p
                                className={`flex gap-1 items-center ${
                                  hasReviews ? "text-red-500" : ""
                                }`}
                              >
                                {hasReviews && <FaCheck />}
                                {!hasReviews && <RxCross2 />}

                                {t("tipsforincreasing2")}
                              </p>
                            </div>
                          </div>
                        )}
                    </>
                  )}
                </>
              )}
              <div className="mb-8">{renderGreeting()}</div>
              <div className="flex sm:hidden overflow-x-auto whitespace-nowrap gap-2 px-8 py-4 max-w-7xl mx-auto scrollbar-hide ">
                {isAgentUser && (
                  <>
                    <span
                      className={`inline-block px-3 py-1 rounded-lg cursor-pointer bg-gray-200 ${
                        selectedSection === "profile" ? "text-red-600" : ""
                      }`}
                      onClick={() => setSelectedSection("profile")}
                    >
                      {t("general")}
                    </span>

                    <span
                      className={`inline-block px-3 py-1 rounded-lg cursor-pointer bg-gray-200 ${
                        selectedSection === "filter" ? "text-red-600" : ""
                      }`}
                      onClick={() => setSelectedSection("filter")}
                    >
                      {t("details")}
                    </span>
                    <span
                      className={`relative inline-block px-3 py-1 rounded-lg cursor-pointer bg-gray-200 ${
                        selectedSection === "team" ? "text-red-600" : ""
                      }`}
                      onClick={() => setSelectedSection("team")}
                    >
                      {t("team")}
                      {inviteCount > 0 && (
                        <span className="absolute -top-0.5 -right-2 transform -translate-y-1/2 bg-red-500 text-white text-super-small font-bold px-2 py-1 rounded-full">
                          {inviteCount}
                        </span>
                      )}
                    </span>
                    <span
                      className={`inline-block px-3 py-1 rounded-lg cursor-pointer bg-gray-200 ${
                        selectedSection === "socials" ? "text-red-600" : ""
                      }`}
                      onClick={() => setSelectedSection("socials")}
                    >
                      {t("socials")}
                    </span>
                  </>
                )}
              </div>

              {/* My Profile Section */}
              <div className="flex border rounded mb-4 bg-white w-full gap-4">
                <div className="w-1/5 hidden sm:block bg-gray-100">
                  <ul className="items-center mx-auto justify-center w-full flex flex-col">
                    <li
                      className="relative p-4 cursor-pointer w-full text-center hover:bg-gray-300 "
                      onClick={() => setSelectedSection("profile")}
                    >
                      {t("general")}{" "}
                    </li>
                    {isAgentUser && (
                      <>
                        <div className="h-[1px] flex w-full bg-gray-300"></div>
                        <li
                          className="relative p-4 cursor-pointer w-full text-center hover:bg-gray-300 "
                          onClick={() => setSelectedSection("filter")}
                        >
                          {t("details")}{" "}
                        </li>
                      </>
                    )}
                    {isAgentUser && (
                      <>
                        <div className="h-[1px] flex w-full bg-gray-300"></div>
                        <li
                          className="relative p-4 cursor-pointer w-full text-center hover:bg-gray-300 "
                          onClick={() => setSelectedSection("team")}
                        >
                          {t("team")}{" "}
                          {inviteCount > 0 && (
                            <span className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-red-500 text-super-small text-white text-xs font-bold px-2 py-1 rounded-full">
                              {inviteCount}
                            </span>
                          )}
                        </li>
                      </>
                    )}
                    {isAgentUser && (
                      <>
                        <div className="h-[1px] flex w-full bg-gray-300"></div>
                        <li
                          className="relative p-4 cursor-pointer w-full text-center hover:bg-gray-300 "
                          onClick={() => setSelectedSection("socials")}
                        >
                          {t("socials")}{" "}
                        </li>
                      </>
                    )}
                  </ul>
                </div>

                <div className="mb-4 w-full py-4 px-5">
                  <form className="text-md ">
                    {selectedSection === "profile" && (
                      <>
                        <div
                          className="flex items-center justify-between "
                          onClick={() => setIsMyProfileOpen(!isMyProfileOpen)}
                        >
                          <p className="flex ml-auto items-center">
                            <span
                              onClick={() => {
                                changeDetail && onSubmit();
                                setChangeDetail((prevState) => !prevState);
                                setSpecialtiesDisabled(false);
                                setLanguageDisabled(false);
                                setCitiesDisabled(false);
                              }}
                              className="text-black flex items-center gap-0.5 capitalize hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer text-sm md:text-base mb-4"
                            >
                              {changeDetail
                                ? t("applychange")
                                : t("editprofil")}
                              <MdEdit />
                            </span>
                          </p>
                        </div>
                        <div className="md:flex md:gap-2">
                          <TextField
                            className="bg-white"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "black",
                                },
                                "&.Mui-focused": {
                                  boxShadow: "none",
                                },
                                "& input": {
                                  boxShadow: "none",
                                  fontSize: "0.875rem", // Adjusted font size for input text
                                },
                                "& textarea": {
                                  boxShadow: "none",
                                  fontSize: "0.875rem",
                                },
                              },
                            }}
                            id="firstName"
                            label={t("firstname")}
                            value={firstName}
                            disabled={!changeDetail}
                            onChange={onChange}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                          />
                          <TextField
                            className="bg-white"
                            id="lastName"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "black",
                                },
                                "&.Mui-focused": {
                                  boxShadow: "none",
                                },
                                "& input": {
                                  boxShadow: "none",
                                  fontSize: "0.875rem", // Adjusted font size for input text
                                },
                                "& textarea": {
                                  boxShadow: "none",
                                  fontSize: "0.875rem",
                                },
                              },
                            }}
                            label={t("lastname")}
                            value={lastName}
                            disabled={!changeDetail}
                            onChange={onChange}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                          />
                        </div>
                        <div className=" md:flex md:gap-2">
                          <TextField
                            className={phoneNumber ? "bg-white" : "bg-red-300"}
                            id="phoneNumber"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "black",
                                },
                                "&.Mui-focused": {
                                  boxShadow: "none",
                                },
                                "& input": {
                                  boxShadow: "none",
                                  fontSize: "0.875rem", // Adjusted font size for input text
                                },
                                "& textarea": {
                                  boxShadow: "none",
                                  fontSize: "0.875rem",
                                },
                                backgroundColor: phoneNumber
                                  ? "white"
                                  : "#fff0f2",
                              },
                            }}
                            label={t("phonenumber")}
                            value={phoneNumber}
                            onChange={onChange}
                            fullWidth
                            disabled={!changeDetail}
                            variant="outlined"
                            margin="normal"
                          />
                          {phoneError && (
                            <p className="text-red-500 text-sm ">
                              {phoneError}
                            </p>
                          )}
                          <TextField
                            className="bg-white"
                            id="email"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "black",
                                },
                                "&.Mui-focused": {
                                  boxShadow: "none",
                                },
                                "& input": {
                                  boxShadow: "none",
                                  fontSize: "0.875rem", // Adjusted font size for input text
                                },
                                "& textarea": {
                                  boxShadow: "none",
                                  fontSize: "0.875rem",
                                },
                              },
                            }}
                            label="Email"
                            value={email}
                            disabled
                            onChange={onChange}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                          />
                        </div>
                        {isAgentUser && (
                          <>
                            <TextField
                              className="bg-white"
                              id="agency"
                              label={t("company")}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: "black",
                                  },
                                  "&.Mui-focused": {
                                    boxShadow: "none",
                                  },
                                  "& input": {
                                    boxShadow: "none",
                                    fontSize: "0.875rem", // Adjusted font size for input text
                                  },
                                  "& textarea": {
                                    boxShadow: "none",
                                    fontSize: "0.875rem",
                                  },
                                },
                              }}
                              value={agency}
                              disabled={!changeDetail}
                              onChange={onChange}
                              fullWidth
                              variant="outlined"
                              margin="normal"
                            />
                          </>
                        )}
                        <Link to="/forgot-password">
                          <p className="text-sm mt-4 cursor-pointer text-red-500 hover:text-red-600">
                            {t("changepassword")}
                          </p>
                        </Link>{" "}
                      </>
                    )}
                    {selectedSection === "filter" && (
                      <>
                        <div
                          className="flex items-center justify-between "
                          onClick={() => setIsMyProfileOpen(!isMyProfileOpen)}
                        >
                          <p className="flex ml-auto items-center">
                            <span
                              onClick={() => {
                                changeDetail && onSubmit();
                                setChangeDetail((prevState) => !prevState);
                                setSpecialtiesDisabled(false);
                                setLanguageDisabled(false);
                                setCitiesDisabled(false);
                              }}
                              className="text-black flex items-center gap-0.5 capitalize hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer text-sm md:text-base mb-4"
                            >
                              {changeDetail
                                ? t("applychange")
                                : t("editprofil")}
                              <MdEdit />
                            </span>
                          </p>
                        </div>
                        {isAgentUser && (
                          <div>
                            <TextField
                              className="bg-white"
                              id="aboutMe"
                              inputProps={{ maxLength: 200 }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: "black",
                                  },
                                  "&.Mui-focused": {
                                    boxShadow: "none",
                                  },
                                  "& input": {
                                    boxShadow: "none",
                                    fontSize: "0.875rem", // Adjusted font size for input text
                                  },
                                  "& textarea": {
                                    boxShadow: "none",
                                    fontSize: "0.875rem",
                                  },
                                },
                              }}
                              label={t("chooseaboutme")}
                              value={aboutMe}
                              disabled={!changeDetail}
                              onChange={(e) => setAboutMe(e.target.value)}
                              fullWidth
                              multiline
                              rows={4}
                              variant="outlined"
                              margin="normal"
                            />
                            <div className="flex mx-auto justify-end">
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {aboutMe.length}/200
                              </Typography>
                            </div>

                            {/* Specialties */}
                            <h4 className="text-sm mt-2">
                              {t("choosespecialties")} -{" "}
                              {selectedSpecialties.length}
                              /3
                            </h4>

                            <FormControl
                              fullWidth
                              variant="outlined"
                              margin="normal"
                              style={{ backgroundColor: "white" }}
                            >
                              <Select
                                id="specialties"
                                sx={{
                                  height: "100px",
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black", // Change to match the TextField focus border color
                                    },
                                    "&.Mui-focused": {
                                      boxShadow: "none",
                                    },
                                    "& input": {
                                      boxShadow: "none",
                                    },
                                  },
                                }}
                                multiple
                                value={selectedSpecialties}
                                onChange={handleSpecialtiesChange}
                                renderValue={(selected) => (
                                  <div className="flex flex-wrap gap-2">
                                    {selected.map((value) => (
                                      <Chip
                                        key={value}
                                        label={t(value)} // Use translation here
                                        onDelete={() => handleDelete(value)}
                                      />
                                    ))}
                                  </div>
                                )}
                                disabled={!changeDetail || specialtiesDisabled}
                              >
                                {specialties.map((specialty) => (
                                  <MenuItem key={specialty} value={specialty}>
                                    {t(specialty)} {/* Use translation here */}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            {/* Language */}
                            <h4 className="text-sm mt-2">
                              {t("chooselanguages")} - {selectedLanguage.length}
                              /3
                            </h4>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              margin="normal"
                              style={{ backgroundColor: "white" }}
                            >
                              <Select
                                labelId="language-label"
                                id="language"
                                multiple
                                style={{
                                  height: "100px",
                                }}
                                value={selectedLanguage}
                                onChange={handleLanguageChange}
                                renderValue={(selected) => (
                                  <div className="flex flex-wrap gap-2">
                                    {selected.map((value) => (
                                      <Chip
                                        key={value}
                                        label={t(value)}
                                        onDelete={() =>
                                          handleLanguageDelete(value)
                                        }
                                      />
                                    ))}
                                  </div>
                                )}
                                disabled={!changeDetail || languageDisabled}
                              >
                                {language.map((lang) => (
                                  <MenuItem key={lang} value={lang}>
                                    {t(lang)} {/* Use translation here */}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            {/* Cities */}
                            <h4 className="text-sm mt-2">
                              {t("choosecities")} - {selectedCities.length}/3
                            </h4>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              margin="normal"
                              style={{ backgroundColor: "white" }}
                            >
                              <Select
                                labelId="city-label"
                                id="city"
                                multiple
                                style={{
                                  height: "100px",
                                }}
                                value={selectedCities}
                                onChange={handleCitiesChange}
                                renderValue={(selected) => (
                                  <div className="flex flex-wrap gap-2">
                                    {selected.map((value) => (
                                      <Chip
                                        key={value}
                                        label={value}
                                        onDelete={() =>
                                          handleCitiesDelete(value)
                                        }
                                      />
                                    ))}
                                  </div>
                                )}
                                disabled={!changeDetail || citiesDisabled}
                              >
                                {cities.map((city) => (
                                  <MenuItem key={city} value={city}>
                                    {city}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        )}
                      </>
                    )}
                    {selectedSection === "socials" && (
                      <>
                        <div
                          className="flex items-center justify-between "
                          onClick={() => setIsMyProfileOpen(!isMyProfileOpen)}
                        >
                          <p className="flex ml-auto items-center">
                            <span
                              onClick={() => {
                                changeDetail && onSubmit();
                                setChangeDetail((prevState) => !prevState);
                                setSpecialtiesDisabled(false);
                                setLanguageDisabled(false);
                                setCitiesDisabled(false);
                              }}
                              className="text-black flex items-center gap-0.5 capitalize hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer text-sm md:text-base mb-4"
                            >
                              {changeDetail
                                ? t("applychange")
                                : t("editprofil")}
                              <MdEdit />
                            </span>
                          </p>
                        </div>
                        {isAgentUser && (
                          <>
                            <h4 className="text-sm mt-2">
                              {t("choosesocials")}
                            </h4>
                            <div className=" md:flex md:gap-2">
                              <TextField
                                className="bg-white"
                                id="instagram"
                                label={t("@ Instagram Username")}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black",
                                    },
                                    "&.Mui-focused": {
                                      boxShadow: "none",
                                    },
                                    "& input": {
                                      boxShadow: "none",
                                      fontSize: "0.875rem", // Adjusted font size for input text
                                    },
                                    "& textarea": {
                                      boxShadow: "none",
                                      fontSize: "0.875rem",
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    fontSize: "0.875rem", // Adjusted font size for label
                                  },
                                }}
                                value={formData.instagram}
                                disabled={!changeDetail}
                                onChange={onChange}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                              />
                              <TextField
                                className="bg-white"
                                id="facebook"
                                label={t("@ Facebook Username")}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black",
                                    },
                                    "&.Mui-focused": {
                                      boxShadow: "none",
                                    },
                                    "& input": {
                                      boxShadow: "none",
                                      fontSize: "0.875rem", // Adjusted font size for input text
                                    },
                                    "& textarea": {
                                      boxShadow: "none",
                                      fontSize: "0.875rem",
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    fontSize: "0.875rem", // Adjusted font size for label
                                  },
                                }}
                                value={formData.facebook}
                                disabled={!changeDetail}
                                onChange={onChange}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                              />
                              <TextField
                                className="bg-white"
                                id="youtube"
                                label={t("@ Youtube Username")}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black",
                                    },
                                    "&.Mui-focused": {
                                      boxShadow: "none",
                                    },
                                    "& input": {
                                      boxShadow: "none",
                                      fontSize: "0.875rem", // Adjusted font size for input text
                                    },
                                    "& textarea": {
                                      boxShadow: "none",
                                      fontSize: "0.875rem",
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    fontSize: "0.875rem", // Adjusted font size for label
                                  },
                                }}
                                value={formData.youtube}
                                disabled={!changeDetail}
                                onChange={onChange}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                              />
                            </div>
                          </>
                        )}{" "}
                        {isAgentUser && (
                          <>
                            <h4 className="text-sm mt-2">
                              {t("chooseyoutubevideolink")}
                            </h4>
                            <div className=" md:flex md:gap-2">
                              <TextField
                                className="bg-white"
                                id="youtubeVideo"
                                label={t("Youtube video link")}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black",
                                    },
                                    "&.Mui-focused": {
                                      boxShadow: "none",
                                    },
                                    "& input": {
                                      boxShadow: "none",
                                      fontSize: "0.875rem", // Adjusted font size for input text
                                    },
                                    "& textarea": {
                                      boxShadow: "none",
                                    },
                                  },
                                }}
                                value={formData.youtubeVideo}
                                disabled={!changeDetail}
                                onChange={onChange}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                              />
                            </div>
                          </>
                        )}{" "}
                      </>
                    )}
                    {selectedSection === "team" && (
                      <>
                        {/* Displaying Team Details */}
                        {isInTeam ? (
                          <div>
                            <div className="flex w-full items-center justify-between">
                              <h2 className="text-sm md:text-base font-bold">
                                {t("teamLabel")}: {teamName}
                              </h2>
                              {isTheLeader && (
                                <p
                                  type="button"
                                  onClick={handleDeleteTeam}
                                  className="text-red-500 text-sm cursor-pointer rounded"
                                >
                                  {t("deleteTeam")}
                                </p>
                              )}
                              {!isTheLeader && (
                                <p
                                  type="button"
                                  onClick={handleLeaveTeam}
                                  className="text-red-500 text-sm cursor-pointer rounded"
                                >
                                  {t("leaveTeam")}
                                </p>
                              )}
                            </div>
                            {teamAgents.length > 0 && (
                              <h3 className="mt-4 mb-4 text-sm">
                                {t("teamMembers")}
                              </h3>
                            )}
                            <ul>
                              {teamAgents.map((agent) => (
                                <li
                                  key={agent.id}
                                  className="flex  text-sm items-center mb-2"
                                >
                                  <img
                                    src={agent.profilePicture}
                                    alt={agent.name}
                                    className="w-8 h-8 rounded-full mr-2"
                                  />
                                  {agent.name}
                                  {isTheLeader && (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleDeleteAgent(agent.id)
                                      }
                                      className="ml-auto text-red-500 hover:text-red-700"
                                    >
                                      <MdDelete size={20} />
                                    </button>
                                  )}
                                </li>
                              ))}
                            </ul>

                            {/* If the user is the team leader, allow adding agents */}
                            {isTheLeader && (
                              <div className="mt-4">
                                <h3 className="mb-2 text-sm font-semibold">
                                  {t("addAgentsToTeam")}
                                </h3>
                                {/* MUI Select */}
                                <FormControl
                                  fullWidth
                                  sx={{
                                    "& .MuiInputLabel-root": {
                                      fontSize: "0.875rem",
                                    },
                                  }}
                                >
                                  <InputLabel id="add-agents-label">
                                    {t("selectAgents")}
                                  </InputLabel>
                                  <Select
                                    labelId="add-agents-label"
                                    multiple
                                    className="mb-2"
                                    value={selectedAgents}
                                    onChange={(e) =>
                                      setSelectedAgents(e.target.value)
                                    }
                                    sx={{
                                      fontSize: "0.875rem", // Adjust font size for selected text
                                      "& .MuiSelect-select": {
                                        fontSize: "0.875rem", // Adjust font size inside select dropdown
                                      },
                                    }}
                                    renderValue={(selected) =>
                                      selected
                                        .map(
                                          (id) =>
                                            agents.find(
                                              (agent) => agent.id === id
                                            )?.name || t("unknownAgent")
                                        )
                                        .join(", ")
                                    }
                                  >
                                    {agents
                                      .filter(
                                        (agent) =>
                                          !teamAgents.some(
                                            (teamAgent) =>
                                              teamAgent.id === agent.id
                                          ) && agent.status === "approved"
                                      )
                                      .map((agent) => (
                                        <MenuItem
                                          key={agent.id}
                                          value={agent.id}
                                          sx={{ fontSize: "0.875rem" }} // Adjust font size inside dropdown
                                        >
                                          <ListItemAvatar>
                                            <Avatar
                                              src={
                                                agent.profilePicture ||
                                                "/placeholder.png"
                                              }
                                              alt={agent.name}
                                            />
                                          </ListItemAvatar>
                                          <ListItemText primary={agent.name} />
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>

                                {/* Add Agents Button */}
                                <Button
                                  type="button"
                                  onClick={handleAddAgents}
                                  variant="contained"
                                  color="error"
                                  fullWidth
                                  className="mt-4 text-sm"
                                  sx={{
                                    fontSize: "0.75rem",
                                  }} // Adjust font size and padding
                                >
                                  {t("addSelectedAgents")}
                                </Button>
                                {pendingInvites.length > 0 && (
                                  <div className="mt-4 text-sm">
                                    <h4 className="font-semibold">
                                      {t("sentInvites")}
                                    </h4>
                                    <ul>
                                      {pendingInvites.map((agentId) => (
                                        <li key={agentId}>
                                          {agents.find((a) => a.id === agentId)
                                            ?.name || t("unknownAgent")}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="">
                            {/* Show the create team dialog */}
                            <p
                              onClick={() => setOpenTeam(true)}
                              type="button"
                              className="flex text-sm p-2 justify-end cursor-pointer hover:text-red-700 text-black rounded"
                            >
                              + {t("createTeam")}
                            </p>

                            {/* Create Team Dialog */}
                            <Dialog
                              open={openTeam}
                              onClose={() => setOpenTeam(false)}
                            >
                              <DialogContent>
                                <DialogContentText>
                                  <h2 className="text-xl font-semibold mb-4">
                                    {t("createNewTeam")}
                                  </h2>
                                  <div className="mb-4">
                                    <label className="block mb-2 text-gray-700">
                                      {t("teamName")}
                                    </label>
                                    <input
                                      type="text"
                                      value={teamName}
                                      onChange={(e) =>
                                        setTeamName(e.target.value)
                                      }
                                      placeholder={t("enterTeamName")}
                                      className="w-full p-2 border rounded"
                                    />
                                  </div>

                                  <button
                                    type="button"
                                    onClick={handleCreateTeam}
                                    className="w-full p-2 bg-red-600 text-white rounded"
                                  >
                                    {t("createTeam")}
                                  </button>
                                </DialogContentText>
                              </DialogContent>
                            </Dialog>
                          </div>
                        )}
                        {receivedInvites.length > 0 ? (
                          <div className="mt-4">
                            <h4 className="font-semibold">
                              {t("pendingInvites")}
                            </h4>
                            <ul>
                              {receivedInvites.map((invite) => (
                                <li key={invite.id}>
                                  <div className="flex w-full justify-between items-center">
                                    <p>
                                      {t("team")}: {invite.name}
                                    </p>
                                    <div className="space-x-2">
                                      <button
                                        className="text-green-600"
                                        type="button"
                                        onClick={() =>
                                          handleAcceptInvite(invite.id)
                                        }
                                      >
                                        ✔
                                      </button>
                                      <button
                                        className="text-red-600"
                                        type="button"
                                        onClick={() =>
                                          handleRefuseInvite(invite.id)
                                        }
                                      >
                                        ✘
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : (
                          <p className="mt-4"></p>
                        )}
                      </>
                    )}
                  </form>
                </div>
              </div>
              <div className="flex justify-center sm:justify-start whitespace-nowrap mb-16">
                <p
                  onClick={onLogout}
                  className="flex items-center gap-1 mt-8  capitalize hover:text-gray-300 transition ease-in-out duration-200 cursor-pointer font-semibold"
                >
                  <PiSignOutBold />
                  {t("signout")}{" "}
                </p>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}
