import React, { useState } from "react";
import OAuth from "../components/OAuth";
import { Link } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


export default function ForgotPassword() {
   const { t } = useTranslation();
  const [email, setEmail] = useState("");

  function onChange(e) {
    setEmail(e.target.value);
  }

  async function onSubmit(e) {
    e.preventDefault();
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      toast.success("Email was sent");
    } catch (error) {
      toast.error("Could not send reset password");
    }
  }
  return (
    <section>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="hidden md:grid w-full h-48 md:h-screen grid-cols-1 grid-rows-1 overflow-hidden bg-blue-100 relative">
          {/* Background Image */}
          <div className="absolute inset-0 w-full h-full"></div>

          {/* Logo */}
          <div className="relative z-10 flex justify-center items-center w-full h-full">
            <a href="/">
              <img
                src={process.env.PUBLIC_URL + "/LogoBeytty.png"}
                className="w-36 justify-center flex mx-auto md:w-60 hover:opacity-70 transition ease-in-out duration-200"
              />
              <p className="text-lg text-center justify-center flex mx-auto mt-2">
                <p className="flex-col ml-auto">
                  {t("buysell")} {t("Rent")}
                  <strong className=""> {t("yourhome")}</strong>
                </p>{" "}
              </p>
            </a>
          </div>
        </div>
        <div className="flex items-center py-12 bg-white md:my-0 md:h-screen md:shadow-md shadow-black/30">
          <form
            onSubmit={onSubmit}
            className="max-w-md px-8 md:px-4 w-[28rem] mx-auto"
          >
            <a href="/">
              <img
                src={process.env.PUBLIC_URL + "/Logo.png"}
                className="md:hidden mb-8 w-40 text-center mx-auto flex hover:opacity-70 transition ease-in-out duration-200 "
              />
            </a>
            <div className=" justify-start text-center md:text-left text-xl md:text-4xl py-8 text-black">
              {t("resetpassword")}{" "}
            </div>
            <input
              type="email"
              id="email"
              value={email}
              onChange={onChange}
              placeholder={t("enterEmail")}
              className="w-full mb-6 px-4 py-2 text-md color-grey-700 shadow-md bg-white border-gray-300 rounded transition ease-in-out"
            />

            <button
              className="w-full uppercase bg-custom-red rounded text-white px-7 py-3"
              type="submit"
            >
              {t("Reset")}{" "}
            </button>
            <div className="flex flex-col mt-4 mb-4 justify-between whitespace-nowrap text-sm sm:text-md">
              <p className="mb-2">
                {t("haveanaccount")}{" "}
                <Link
                  to="/sign-in"
                  className="text-black hover:text-red-700 transition duration-200 ease-in-out font-semibold"
                >
                  {" "}
                  {t("signin")}{" "}
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
