import React, { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import OAuth from "../components/OAuth";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { db } from "../firebase";
import {
  getFirestore,
  doc,
  setDoc,
  serverTimestamp,
  getDocs,
  query,
  collection,
  where,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import Spinner from "../components/Spinner";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { Helmet } from "react-helmet";



export default function AgentSignUp() {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // State to control loading spinner

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    agency: "",
    license: "",
    email: "",
    password: "",
    phoneNumber: "",
    agentType: "agent",
    photoURL:
      "https://firebasestorage.googleapis.com/v0/b/novio-91bc4.appspot.com/o/AnonPFP.png?alt=media&token=7116463e-8872-4869-8099-865f7def8f0c",
  });
  const [emailError, setEmailError] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("+212");
  const [termsError, setTermsError] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  

  const [phoneNumber, setPhoneNumber] = useState("");

  const {
    firstName,
    lastName,
    email,
    agency,
    license,
    password,
    agentType,
  } = formData;
  const navigate = useNavigate();

  const handlePhoneChange = (value) => {
    if (!value.startsWith("+")) {
      value = `+${value}`;
    }
    setPhoneNumber(value);
  };

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  async function onSubmit(e) {
    e.preventDefault();
    setEmailError("");
    setTermsError("");
    setLastNameError("");
    setFirstNameError("");
    setPhoneError("");
    setPasswordError("");
    // Regular expression to extract domain from email
    setLoading(true);

    const domainRegex = /@([a-zA-Z0-9.-]+)$/;
    const match = email.match(domainRegex);

    // Check for errors in the first step fields
    if (!firstName.trim()) {
      setLoading(false);
      setFirstNameError(t("error.firstNameRequired"));
      return;
    }

    if (!lastName.trim()) {
      setLoading(false);
      setLastNameError(t("error.lastNameRequired"));
      return;
    }

    if (!phoneNumber.trim()) {
      setLoading(false);
      setPhoneError(t("error.phoneRequired"));
      return;
    }



    if (!match) {
      setLoading(false);
      setEmailError(t("error.invalidEmail"));
      return;
    }

    if (!email.trim()) {
      setLoading(false);
      setEmailError(t("error.emailRequired"));
      return;
    }

    if (!password.trim()) {
      setLoading(false);
      setPasswordError(t("error.passwordRequired"));
      return;
    }

    const emailDomain = match[1].toLowerCase();
    const allowedDomains = [
      "gmail.com",
      "yahoo.com",
      "hotmail.com",
      "outlook.com",
      "icloud.com",
      "aol.com",
      "protonmail.com",
      "mail.com",
      "zoho.com",
      "yandex.com",
      "gmx.com",
    ];
    if (!allowedDomains.includes(emailDomain)) {
      setEmailError(t("error.emailDomainInvalid"));
      setLoading(false);
      return;
    }

    // Validate phone number
    const phoneNumberObject = parsePhoneNumberFromString(phoneNumber, "MA"); // Replace 'MA' with the default country code if different
    if (!phoneNumberObject || !phoneNumberObject.isValid()) {
      setLoading(false);
      setPhoneError(t("error.phoneInvalid"));
      return;
    }

    if (!agreeToTerms) {
      setTermsError(t("error.termsRequired"));
      setLoading(false);
      return;
    }
    try {
      // Query for the "users" collection
      const usersCollection = collection(db, "users");
      const usersQuery = query(
        usersCollection,
        where("phoneNumber", "==", phoneNumber)
      );
      const usersSnapshot = await getDocs(usersQuery);

      // Query for the "agents" collection
      const agentsCollection = collection(db, "agents");
      const agentsQuery = query(
        agentsCollection,
        where("phoneNumber", "==", phoneNumber)
      );
      const agentsSnapshot = await getDocs(agentsQuery);

      // Check if either collection contains the phone number
      if (!usersSnapshot.empty || !agentsSnapshot.empty) {
        setLoading(false);
        setPhoneError(t("error.phoneInUse"));
        return;
      }
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = userCredential.user;
      const formDataCopy = { ...formData };
      delete formDataCopy.password;
      formDataCopy.timestamp = serverTimestamp();
      formDataCopy.status = "pending";
      formDataCopy.subscriptionType = "essential";

      formDataCopy.phoneNumber = phoneNumber;

      // Ensure both operations are atomic
      await Promise.all([
        updateProfile(auth.currentUser, {
          displayName: `${firstName} ${lastName} ${agency}`,
        }),
        setDoc(doc(db, "agents", user.uid), formDataCopy),
      ]);

      await sendEmailVerification(user);
      navigate("/email-verification");
    } catch (error) {
      console.error("Firebase authentication error:", error);

      // Switch for Firebase auth error codes
      switch (error.code) {
        case "auth/email-already-in-use":
          setEmailError(t("error.emailInUse"));
          break;
        case "auth/invalid-email":
          setEmailError(t("error.invalidEmail"));
          break;
        case "auth/weak-password":
          setPasswordError(t("error.weakPassword"));
          break;
        // Handle other auth errors as needed
        default:
          setEmailError(t("error.registrationError"));
      }
    } finally {
      setLoading(false);
    }
  }

  const handleOpenTerms = () => {
    setOpenTerms(true);
  };

  const handleCloseTerms = () => {
    setOpenTerms(false);
  };

  return (
    <section>
      <Helmet>
        <title>
          Agent Sign Up - Join Beytty and Grow Your Real Estate Business
        </title>
        <meta
          name="description"
          content="Sign up as a real estate agent on Beytty to reach more clients and grow your business. Create a professional profile, list your properties, and connect with potential buyers and sellers."
        />
        <meta
          name="keywords"
          content="agent sign up, real estate agent registration, join Beytty, grow your business, real estate professionals, Morocco real estate"
        />
        <meta
          property="og:title"
          content="Agent Sign Up - Join Beytty and Grow Your Real Estate Business"
        />
        <meta
          property="og:description"
          content="Sign up as a real estate agent on Beytty to reach more clients and grow your business. Create a professional profile, list your properties, and connect with potential buyers and sellers."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://beytty.com/agent-sign-up" />
        <meta property="og:image" content="https://beytty.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Agent Sign Up - Join Beytty and Grow Your Real Estate Business"
        />
        <meta
          name="twitter:description"
          content="Sign up as a real estate agent on Beytty to reach more clients and grow your business. Create a professional profile, list your properties, and connect with potential buyers and sellers."
        />
        <meta name="twitter:image" content="https://beytty.com/" />

        <title>
          Inscription Agent - Rejoignez Beytty et Développez Votre Activité
          Immobilière
        </title>
        <meta
          name="description"
          content="Inscrivez-vous en tant qu'agent immobilier sur Beytty pour atteindre plus de clients et développer votre activité. Créez un profil professionnel, listez vos propriétés et connectez-vous avec des acheteurs et vendeurs potentiels."
        />
        <meta
          name="keywords"
          content="inscription agent, enregistrement agent immobilier, rejoindre Beytty, développer votre activité, professionnels de l'immobilier, immobilier au Maroc"
        />
        <meta
          property="og:title"
          content="Inscription Agent - Rejoignez Beytty et Développez Votre Activité Immobilière"
        />
        <meta
          property="og:description"
          content="Inscrivez-vous en tant qu'agent immobilier sur Beytty pour atteindre plus de clients et développer votre activité. Créez un profil professionnel, listez vos propriétés et connectez-vous avec des acheteurs et vendeurs potentiels."
        />
        <meta
          name="twitter:title"
          content="Inscription Agent - Rejoignez Beytty et Développez Votre Activité Immobilière"
        />
        <meta
          name="twitter:description"
          content="Inscrivez-vous en tant qu'agent immobilier sur Beytty pour atteindre plus de clients et développer votre activité. Créez un profil professionnel, listez vos propriétés et connectez-vous avec des acheteurs et vendeurs potentiels."
        />
      </Helmet>

      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="hidden md:grid w-full h-48 md:h-screen grid-cols-1 grid-rows-1 overflow-hidden bg-gray-100 relative">
          {/* Background Image */}
          <div className="absolute inset-0 w-full h-full"></div>

          {/* Logo */}
          <div className="relative px-4 z-10 flex justify-center items-center w-full h-full">
            <a href="/">
              <img
                src={process.env.PUBLIC_URL + "/LogoBeyttyPro.png"}
                className="w-36 justify-center flex mx-auto md:w-60 hover:opacity-70 transition ease-in-out duration-200"
              />
              <div className="p-8 flex-col bg-white rounded-xl shadow-lg justify-center flex mx-auto mt-4">
                <p className="font-semibold mb-4 justify-center flex mx-auto mt-2">
                  <p className="flex-col ml-auto">{t("joinbeyttypro")}</p>{" "}
                </p>
                <ul className="text-sm text-gray-500 space-y-2">
                  <li>- {t("signupBenefits1")}</li>
                  <li>- {t("signupBenefits2")}</li>
                  <li>- {t("signupBenefits3")}</li>
                  <li>- {t("signupBenefits4")}</li>
                  <li>- {t("signupBenefits5")}</li>
                </ul>
              </div>
            </a>
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <div className="flex items-center overflow-y-auto py-12 bg-white md:my-0 md:h-screen md:shadow-md shadow-black/30">
            <form
              onSubmit={onSubmit}
              className="max-w-md px-8 md:px-4 w-[28rem] mx-auto"
            >
              <a href="/">
                <img
                  src={process.env.PUBLIC_URL + "/LogoBeyttyPro.png"}
                  alt="Beytty logo"
                  className="md:hidden mb-8 w-40 text-center mx-auto flex hover:opacity-70 transition ease-in-out duration-200 "
                />
              </a>
              <div className=" justify-start text-center md:text-left text-xl md:text-4xl md:py-8 text-black">
                {t("createagentaccount")}
              </div>
              <div className="flex gap-2">
                <input
                  type="text"
                  id="firstName"
                  value={firstName}
                  onChange={onChange}
                  placeholder={t("firstname")}
                  className="w-full mt-6 px-4 py-2 text-md color-grey-700 shadow-md bg-white border-gray-300 rounded transition ease-in-out"
                />
                <input
                  type="text"
                  id="lastName"
                  value={lastName}
                  onChange={onChange}
                  placeholder={t("lastname")}
                  className="w-full mt-6 px-4 py-2 text-md color-grey-700 shadow-md bg-white border-gray-300 rounded transition ease-in-out"
                />
              </div>
              {firstNameError && (
                <p className="text-red-500 text-sm ">{firstNameError}</p>
              )}

              {lastNameError && (
                <p className="text-red-500 text-sm ">{lastNameError}</p>
              )}

              <div className="flex gap-2">

                <input
                  type="text"
                  id="agency"
                  value={agency}
                  onChange={onChange}
                  placeholder={t("companyname")}
                  className="w-full mt-6 px-4 py-2 text-md color-grey-700 shadow-md bg-white border-gray-300 rounded transition ease-in-out"
                />
              </div>

              <p className="mt-6 text-sm">{t("professionTypeQuestion")}</p>
              <select
                id="agentType"
                value={agentType}
                onChange={onChange}
                placeholder={t("selectagenttype")}
                className="w-full mt-2 px-4 py-2 text-md color-grey-700 shadow-md bg-white border-gray-300 rounded capitalize transition ease-in-out"
              >
                <option value="agent">{t("realestateagent")}</option>
                <option value="architect">{t("architect")}</option>
                <option value="renovator">{t("ahomeimprovement")}</option>
                <option value="notary">{t("anotary")}</option>
                <option value="developer">{t("developer")}</option>
                <option value="builder">{t("builder")}</option>
                <option value="photographer">{t("photographer")}</option>
              </select>
        
              <div className="flex mt-6 py-2">
                <PhoneInput
                  className="w-full text-md color-grey-700 shadow-md bg-white border-gray-300 rounded transition ease-in-out"
                  country={"ma"} // Default country, e.g., Morocco
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  inputStyle={{
                    width: "100%",
                    height: "100%",
                    paddingTop: "0.7rem",
                    paddingBottom: "0.7rem",
                    fontSize: "1rem",
                    color: "#4a4a4a",
                    borderRadius: "0.25rem",
                    border: "1px solid #ccc",
                  }}
                  containerStyle={{
                    width: "100%",
                    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                    borderRadius: "0.25rem",
                    backgroundColor: "#fff",
                  }}
                  buttonStyle={{
                    border: "none",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                  }}
                  countryCodeEditable={false}
                />
              </div>
              {phoneError && (
                <p className="text-red-500 text-sm ">{phoneError}</p>
              )}

              <input
                type="email"
                id="email"
                value={email}
                onChange={onChange}
                placeholder="Email"
                className="w-full mt-6 px-4 py-2 text-md color-grey-700 shadow-md bg-white border-gray-300 rounded transition ease-in-out"
              />
              {emailError && (
                <p className="text-red-500 text-sm mb-2">{emailError}</p>
              )}
              <div className="relative mt-6">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={onChange}
                  placeholder={t("password")}
                  className="w-full px-4 py-2 text-md  shadow-md color-grey-700 bg-white border-gray-300 rounded transition ease-in-out"
                />

                {showPassword ? (
                  <AiFillEye
                    className="absolute right-3 top-3 text-xl cursor-pointer"
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  />
                ) : (
                  <AiFillEyeInvisible
                    className="absolute right-3 top-3 text-xl cursor-pointer"
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  />
                )}
              </div>
              {passwordError && (
                <p className="text-red-500 text-sm mb-2">{passwordError}</p>
              )}
              <div className="flex mt-2 items-center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreeToTerms}
                      onChange={(e) => setAgreeToTerms(e.target.checked)}
                      color="primary"
                      sx={{
                        color: "red",
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                    />
                  }
                  sx={{ marginRight: "4px" }}
                />
                <p
                  className=" hover:underline text-sm cursor-pointer  mb-[1px]"
                  onClick={handleOpenTerms}
                >
                  {t("iagreetothe")}{" "}
                  <span className="font-semibold">
                    {t("termsandconditions")}
                  </span>
                </p>
              </div>
              {termsError && (
                <p className="text-red-500 text-sm -mt-2">{termsError}</p>
              )}
              <div className="flex  justify-between whitespace-nowrap text-sm sm:text-md">
                <p className="mb-6 mt-3 ">
                  {t("haveanaccount")}
                  <Link
                    to="/sign-in"
                    className="text-black hover:text-red-700 transition duration-200 ease-in-out underline font-semibold"
                  >
                    {" "}
                    {t("signin")}
                  </Link>
                </p>
              </div>
              <button
                className="w-full mb-20 md:mb-1 uppercase bg-custom-red rounded text-white px-7 py-3"
                type="submit"
              >
                {t("createproaccount")}
              </button>
              <p className="text-small mt-4 opacity-50">{t("privacyNotice")}</p>
            </form>
          </div>
        )}
      </div>
      <Dialog open={openTerms} onClose={handleCloseTerms}>
        <DialogContent>
          <DialogContentText>
            <div className="p-4 max-w-4xl mx-auto text-sm">
              <h1 className="text-xl lg:text-3xl py-4 lg:py-20 text-center font-bold mb-4">
                {t("termsconditions")}
              </h1>
              <p className="mb-4">
                {t("termsconditions_p1", {
                  url: (
                    <a
                      href="https://www.beytty.com"
                      className="text-red-500 underline"
                    >
                      www.beytty.com
                    </a>
                  ),
                  date: "[Date]",
                })}
              </p>

              <h2 className="text-xl font-semibold mb-2">
                {t("section1_title")}
              </h2>

              <p className="mb-4">
                {t("subsection1_1_p1", {
                  amount: "[Amount]",
                  city: "[City]",
                  number: "[Number]",
                  address: "[Address]",
                })}
              </p>
              <p className="mb-2">
                {t("subsection1_1_p2", { director_name: "[Director Name]" })}
              </p>
              <p className="mb-2">
                {t("subsection1_1_p3", { manager_name: "[Manager Name]" })}
              </p>
              <p className="mb-2">
                {t("subsection1_1_p4", { phone_number: "[Phone Number]" })}
              </p>
              <p className="mb-2">
                {t("subsection1_1_p5", {
                  email: (
                    <a
                      href="mailto:contact@beytty.com"
                      className="text-red-500 underline"
                    >
                      contact@beytty.com
                    </a>
                  ),
                })}
              </p>

              <h2 className="text-xl mt-4 font-semibold mb-2">
                {t("section2_title")}
              </h2>
              <ul className="list-disc pl-5">
                <li className="mb-2">
                  <p>{t("definition_listing")}</p>
                </li>
                <li className="mb-2">
                  <p>{t("definition_properties_sold")}</p>
                </li>
                <li className="mb-2">
                  <p>{t("definition_partner")}</p>
                </li>
                <li className="mb-2">
                  <p>{t("definition_real_estate_professional")}</p>
                </li>
                <li className="mb-2">
                  <p>{t("definition_partner_real_estate_professional")}</p>
                </li>
                <li className="mb-2">
                  <p>{t("definition_services")}</p>
                </li>
                <li className="mb-2">
                  <p>
                    {t("definition_site", {
                      url: (
                        <a
                          href="https://www.beytty.com"
                          className="text-red-500 underline"
                        >
                          https://www.beytty.com
                        </a>
                      ),
                    })}
                  </p>
                </li>
                <li className="mb-2">
                  <p>{t("definition_user")}</p>
                </li>
                <li className="mb-2">
                  <p>{t("definition_agent_profile")}</p>
                </li>
              </ul>
              <h2 className="text-xl font-semibold mb-2">
                {t("section3_title")}
              </h2>

              <h3 className="mt-4 text-lg font-semibold mb-1">
                {t("subsection3_1_title")}
              </h3>
              <p className="mb-4">{t("subsection3_1_p1")}</p>
              <p className="mb-4">{t("subsection3_1_p2")}</p>
              <p className="mb-4">{t("subsection3_1_p3")}</p>

              <h3 className="text-lg font-semibold mb-1">
                {t("subsection3_2_title")}
              </h3>
              <p className="mb-4">{t("subsection3_2_p1")}</p>
              <p className="mb-4">{t("subsection3_2_p2")}</p>

              <h3 className="text-lg font-semibold mb-1">
                {t("subsection3_3_title")}
              </h3>
              <p className="mb-4">
                {t("subsection3_3_p1", {
                  your_company_address: "[Your Company Address]",
                  email: (
                    <a
                      href="mailto:contact@beytty.com"
                      className="text-red-500 underline"
                    >
                      contact@beytty.com
                    </a>
                  ),
                })}
              </p>
              <p className="mb-4">{t("subsection3_3_p2")}</p>

              <h3 className="text-lg font-semibold mb-1">
                {t("subsection3_4_title")}
              </h3>
              <p className="mb-4">{t("subsection3_4_p1")}</p>

              <h3 className="text-lg font-semibold mb-1">
                {t("section4_title")}
              </h3>
              <p className="mb-4">
                {t("section4_p1", {
                  email: (
                    <a
                      href="mailto:contact@beytty.com"
                      className="text-red-500 underline"
                    >
                      contact@beytty.com
                    </a>
                  ),
                })}
              </p>

              <h3 className="text-lg font-semibold mb-1">
                {t("section5_title")}
              </h3>
              <p className="mb-4">
                {t("section5_p1", {
                  phone_number: "[Phone Number]",
                  email: (
                    <a
                      href="mailto:contact@beytty.com"
                      className="text-red-500 underline"
                    >
                      contact@beytty.com
                    </a>
                  ),
                })}
              </p>
              <p className="mb-4">{t("section5_p2")}</p>
              <p className="mb-4">{t("section5_p3")}</p>
              <h3 className="text-lg font-semibold mb-1">
                {t("section6_title")}
              </h3>
              <p className="mb-4">{t("section6_p1")}</p>
              <p className="mb-4">{t("section6_p2")}</p>

              <h4 className="text-md font-semibold mb-1">
                {t("section6.1_title")}
              </h4>
              <h5 className="text-md font-semibold mb-1">
                {t("section6.1.1_title")}
              </h5>
              <p className="mb-4">{t("section6.1.1_p1")}</p>

              <h5 className="text-md font-semibold mb-1">
                {t("section6.1.2_title")}
              </h5>
              <p className="mb-4">{t("section6.1.2_p1")}</p>
              <ul className="list-disc pl-5 mb-4">
                <li>{t("section6.1.2_bullet1")}</li>
                <li>{t("section6.1.2_bullet2")}</li>
                <li>{t("section6.1.2_bullet3")}</li>
                <li>{t("section6.1.2_bullet4")}</li>
                <li>{t("section6.1.2_bullet5")}</li>
              </ul>
              <p className="mb-4">{t("section6.1.2_p2")}</p>
              <ul className="list-disc pl-5 mb-4">
                <li>{t("section6.1.2.1_bullet1")}</li>
                <li>{t("section6.1.2.1_bullet2")}</li>
                <li>{t("section6.1.2.1_bullet3")}</li>
                <li>{t("section6.1.2.1_bullet4")}</li>
                <li>{t("section6.1.2.1_bullet5")}</li>
                <li>{t("section6.1.2.1_bullet6")}</li>
                <li>{t("section6.1.2.1_bullet7")}</li>
                <li>{t("section6.1.2.1_bullet8")}</li>
                <li>{t("section6.1.2.1_bullet9")}</li>
                <li>{t("section6.1.2.1_bullet10")}</li>
              </ul>
              <p className="mb-4">{t("section6.1.2_p3")}</p>
              <li>{t("section6.1.2_list3")}</li>
              <li>{t("section6.1.2_list3.1")}</li>

              <h4 className="text-md mt-4 font-semibold mb-1">
                {t("section6.1.3_title")}
              </h4>
              <p className="mb-4">{t("section6.1.3_p1")}</p>

              <h4 className="text-md font-semibold mb-1">
                {t("section6.1.4_title")}
              </h4>
              <p className="mb-4">{t("section6.1.4_p1")}</p>
              <ul className="list-disc pl-5 mb-4">
                <li>{t("section6.1.4_list")}</li>
                <li>{t("section6.1.4_list.1")}</li>
                <li>{t("section6.1.4_list.2")}</li>
                <li>{t("section6.1.4_list.3")}</li>
              </ul>
              <p className="mb-4">{t("section6.1.4_p2")}</p>

              <h4 className="text-md font-semibold mb-1">
                {t("section6.2_title")}
              </h4>
              <p className="mb-4">{t("section6.2_p1")}</p>
              <ul className="list-disc pl-5 mb-4">
                <li>{t("section6.2_list")}</li>
                <li>{t("section6.2_list.1")}</li>
                <li>{t("section6.2_list.2")}</li>
                <li>{t("section6.2_list.3")}</li>
                <li>{t("section6.2_list.4")}</li>
                <li>{t("section6.2_list.5")}</li>
              </ul>
              <p className="mb-4">{t("section6.2_p2")}</p>
              <ul className="list-disc pl-5">
                <li>{t("section6_contact_list")}</li>
                <li>{t("section6_contact_list.1")}</li>
              </ul>
              <h4 className="mt-4 text-md font-semibold mb-1">
                {t("section6.3_title")}
              </h4>
              <p className="mb-4">{t("section6.3_p1")}</p>
              <p className="mb-4">{t("section6.3_p2")}</p>

              <h5 className="text-md font-semibold mb-1">
                {t("section6.3.1_title")}
              </h5>
              <ul className="list-disc pl-5 mb-4">
                <li>
                  <strong>{t("section6.3.1_cookie1_title")}:</strong>{" "}
                  {t("section6.3.1_cookie1_description")}
                </li>
                <li>
                  <strong>{t("section6.3.1_cookie2_title")}:</strong>{" "}
                  {t("section6.3.1_cookie2_description")}
                </li>
                <li>
                  <strong>{t("section6.3.1_cookie3_title")}:</strong>{" "}
                  {t("section6.3.1_cookie3_description")}
                </li>
                <li>
                  <strong>{t("section6.3.1_cookie4_title")}:</strong>{" "}
                  {t("section6.3.1_cookie4_description")}
                </li>
                <li>
                  <strong>{t("section6.3.1_cookie5_title")}:</strong>{" "}
                  {t("section6.3.1_cookie5_description")}
                </li>
                <li>
                  <strong>{t("section6.3.1_cookie6_title")}:</strong>{" "}
                  {t("section6.3.1_cookie6_description")}
                </li>
              </ul>

              <p className="mb-4">{t("section6.3_p3")}</p>

              <p className="mb-4">{t("section6.3_p4")}</p>

              <ul className="list-disc pl-5 mb-4">
                <li>
                  <strong>{t("section6.3_p5_title")}:</strong>{" "}
                  {t("section6.3_p5_description")}
                </li>
                <li>
                  <strong>{t("section6.3_p6_title")}:</strong>{" "}
                  {t("section6.3_p6_description")}
                </li>
                <li>
                  <strong>{t("section6.3_p7_title")}:</strong>{" "}
                  {t("section6.3_p7_description")}
                </li>
              </ul>

              <p className="mb-4">{t("section6.3_p8")}</p>
              <p className="mb-4">
                <a
                  href="https://www.youronlinechoices.com/fr/controler-ses-cookies/"
                  className="text-red-500 underline"
                >
                  {" "}
                  {t("section6.3_p10")}
                </a>{" "}
                {t("section6.3_p9_separator")}{" "}
                <a
                  href="https://www.allaboutcookies.org/fr/"
                  className="text-red-500 underline"
                >
                  {t("section6.3_p11")}
                </a>
                .
              </p>

              <h5 className="text-md font-semibold mb-1">
                {t("section6.3_p12.1")}
              </h5>
              <ul className="list-disc pl-5 mb-4">
                <li>
                  <strong>{t("section6.3_p12.2")}</strong>
                  <ul className="list-disc pl-5">
                    <li>{t("section6.3_p12.3")}</li>
                    <li>
                      <a href={t("")} className="text-red-500 underline">
                        {t("section6.3_p12.13")}
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>{t("section6.3_p12.4")}</strong>
                  <ul className="list-disc pl-5">
                    <li>{t("section6.3_p12.5")}</li>
                    <li>
                      <a href={t("")} className="text-red-500 underline">
                        {t("section6.3_p12.13")}
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>{t("section6.3_p12.6")}</strong>
                  <ul className="list-disc pl-5">
                    <li>{t("section6.3_p12.7")}</li>
                    <li>
                      <a href={t("")} className="text-red-500 underline">
                        {t("")}
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>{t("section6.3_p12.8")}</strong>
                  <ul className="list-disc pl-5">
                    <li>{t("section6.3_p12.9")}</li>
                    <li>
                      <a href={t("")} className="text-red-500 underline">
                        {t("section6.3_p12.13")}
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>{t("section6.3_p12.11")}</strong>
                  <ul className="list-disc pl-5">
                    <li>{t("section6.3_p12.12")}</li>
                    <li>
                      <a href={t("")} className="text-red-500 underline">
                        {t("section6.3_p12.13")}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <h4 className="text-md font-semibold mb-1">
                {t("section6.4_title")}
              </h4>
              <p className="mb-4">{t("section6.4_p1")}</p>
              <p className="mb-4 font-semibold">{t("section6.4_p2")}</p>
              <p className="mb-4">{t("section6.4_p2_sales_fee")}</p>
              <p className="mb-4">{t("section6.4_p2_purchases_fee")}</p>
              <p className="mb-4">{t("section6.4_p2_rentals_landlord_fee")}</p>
              <p className="mb-4">{t("section6.4_p2_rentals_tenant_fee")}</p>
              <h4 className="text-md font-semibold mb-1">
                {t("section6.5_title")}
              </h4>
              <p className="mb-4">{t("section6.5_p1")}</p>
              <p className="mb-4">{t("section6.5_p2")}</p>

              <p className="mb-4">{t("section6.5_data_description")}</p>
              <ul className="list-disc pl-5 mb-4">
                <li>{t("section6.5_data_item1")}</li>
                <li>{t("section6.5_data_item2")}</li>
                <li>{t("section6.5_data_item3")}</li>
                <li>{t("section6.5_data_item4")}</li>
                <li>{t("section6.5_data_item5")}</li>
              </ul>

              <h5 className="text-md font-semibold mb-1">
                {t("section6.5_legal_basis_title")}
              </h5>
              <p className="mb-4">{t("section6.5_legal_basis_description")}</p>

              <h5 className="text-md font-semibold mb-1">
                {t("section6.5_data_retention_title")}
              </h5>
              <p className="mb-4">
                {t("section6.5_data_retention_description")}
              </p>

              <h5 className="text-md font-semibold mb-1">
                {t("section6.5_data_recipients_title")}
              </h5>
              <p className="mb-4">
                {t("section6.5_data_recipients_description")}
              </p>

              <h5 className="text-md font-semibold mb-1">
                {t("section6.5_data_subjects_rights_title")}
              </h5>
              <ul className="list-disc pl-5 mb-4">
                <li>
                  <strong>{t("section6.5_rights_access_title")}:</strong>{" "}
                  {t("section6.5_rights_access_description")}
                </li>
                <li>
                  <strong>{t("section6.5_rights_erasure_title")}:</strong>{" "}
                  {t("section6.5_rights_erasure_description")}
                </li>
                <li>
                  <strong>{t("section6.5_rights_rectification_title")}:</strong>{" "}
                  {t("section6.5_rights_rectification_description")}
                </li>
                <li>
                  <strong>{t("section6.5_rights_opposition_title")}:</strong>{" "}
                  {t("section6.5_rights_opposition_description")}
                </li>
                <li>
                  <strong>{t("section6.5_rights_limitation_title")}:</strong>{" "}
                  {t("section6.5_rights_limitation_description")}
                </li>
              </ul>

              <h5 className="text-md font-semibold mb-1">
                {t("section6.5_exercising_rights_title")}
              </h5>
              <p className="mb-4">
                {t("section6.5_exercising_rights_description")}{" "}
                <a
                  href="mailto:contact@beytty.com"
                  className="text-red-500 underline"
                >
                  contact@beytty.com
                </a>
                . {t("section6.5_exercising_rights_additional")}
              </p>

              <p className="mb-4">{t("section6.5_dispute_clause")}</p>

              <h4 className="text-lg font-semibold mb-2">
                {t("section7_title")}
              </h4>
              <p className="mb-4">{t("section7_p1")}</p>

              <h4 className="text-lg font-semibold mb-2">
                {t("section8_title")}
              </h4>
              <p className="mb-4">{t("section8_p1")}</p>

              <h5 className="text-md font-semibold mb-1">
                {t("section8.1_partner_responsibility_title")}
              </h5>
              <p className="mb-4">
                {t("section8.1_partner_responsibility_description")}
              </p>

              <h5 className="text-md font-semibold mb-1">
                {t("section8.2_limitations_of_liability_title")}
              </h5>
              <p className="mb-4">
                {t("section8.2_limitations_of_liability_description")}
              </p>

              <h4 className="text-lg font-semibold mb-2">
                {t("section9_title")}
              </h4>
              <p className="mb-4">{t("section9_p1")}</p>

              <h4 className="text-lg font-semibold mb-2">
                {t("section10_title")}
              </h4>
              <p className="mb-4">
                {t("section10_p1")}
                <a
                  href="mailto:contact@beytty.com"
                  className="text-red-500 underline"
                >
                  contact@beytty.com
                </a>
                .
              </p>

              <h4 class="text-lg font-semibold mb-2">
                {t("terms_of_sale_title")}
              </h4>
              <h5 class="text-md font-semibold mb-1">{t("1section1_title")}</h5>
              <p class="mb-4">{t("section1_description")}</p>

              <h5 class="text-md font-semibold mb-1">{t("1section2_title")}</h5>
              <p class="mb-4">{t("section2_description")}</p>

              <h4 class="text-lg font-semibold mb-2">{t("1section3_title")}</h4>
              <p class="mb-4">{t("section3_description")}</p>

              <h4 class="text-lg font-semibold mb-2">{t("1section4_title")}</h4>
              <p class="mb-4">{t("section4_description")}</p>

              <h4 class="text-lg font-semibold mb-2">{t("1section5_title")}</h4>
              <p class="mb-4">{t("section5_description")}</p>
              <ul class="list-disc list-inside mb-4">
                <li>{t("section5_list_item1")}</li>
                <li>{t("section5_list_item2")}</li>
                <li>{t("section5_list_item3")}</li>
                <li>{t("section5_list_item4")}</li>
              </ul>
              <p class="mb-4">{t("section5_additional_description")}</p>

              <h4 class="text-lg font-semibold mb-2">{t("1section6_title")}</h4>
              <p class="mb-4">{t("section6_description")}</p>
              <ul class="list-disc list-inside mb-4">
                <li>{t("section6_list_item1")}</li>
                <li>{t("section6_list_item2")}</li>
                <li>{t("section6_list_item3")}</li>
                <li>{t("section6_list_item4")}</li>
              </ul>
              <p class="mb-4">{t("section6_compliance_clause")}</p>

              <h4 class="text-lg font-semibold mb-2">{t("1section7_title")}</h4>
              <p class="mb-4">{t("section7_description")}</p>
              <ul class="list-disc list-inside mb-4">
                <li>{t("section7_list_item1")}</li>
                <li>{t("section7_list_item2")}</li>
                <li>{t("section7_list_item3")}</li>
                <li>{t("section7_list_item4")}</li>
              </ul>
              <p class="mb-4">{t("section7_compliance_clause")}</p>

              <h4 class="text-lg font-semibold mb-2">{t("1section8_title")}</h4>
              <p class="mb-4">{t("section8_description")}</p>
              <ul class="list-disc list-inside mb-4">
                <li>{t("section8_list_item1")}</li>
                <li>{t("section8_list_item2")}</li>
                <li>{t("section8_list_item3")}</li>
                <li>{t("section8_list_item4")}</li>
              </ul>
              <p class="mb-4">{t("section8_compliance_clause")}</p>

              <h4 class="text-lg font-semibold mb-2">{t("1section9_title")}</h4>
              <p class="mb-4">{t("section9_description")}</p>

              <h4 class="text-lg font-semibold mb-2">
                {t("1section10_title")}
              </h4>
              <p class="mb-4">{t("section10_description")}</p>

              <h4 class="text-lg font-semibold mb-2">{t("section11_title")}</h4>
              <p class="mb-4">{t("section11_description")}</p>

              <h4 class="text-lg font-semibold mb-2">{t("section12_title")}</h4>
              <p class="mb-4">{t("section12_description")}</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTerms} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
}
