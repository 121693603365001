import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState, useRef } from "react";
import { db } from "../firebase";
import { Link, useNavigate } from "react-router-dom";
import ListingItem from "../components/ListingItem";
import { GrPrevious, GrNext } from "react-icons/gr";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ListingPlaceholder from "../components/ListingPlaceholder";
import LocationSearchAutocomplete from "../components/LocationSearchAutocomplete";
import LocationSearchAutocompleteLg from "../components/LocationSearchAutocompleteLg";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";



export default function Home() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const sliderRefSm = useRef(null);
  const sliderRefMd = useRef(null);
  const sliderRefLg = useRef(null);
  const salesliderRef = useRef(null);
  const salesliderRefSm = useRef(null);
  const salesliderRefMd = useRef(null);
  const salesliderRefLg = useRef(null);
  const rentsliderRef = useRef(null);
  const rentsliderRefSm = useRef(null);
  const rentsliderRefMd = useRef(null);
  const rentsliderRefLg = useRef(null);
  const [userCity, setUserCity] = useState("");
  const [userLat, setUserLat] = useState(null);
  const [userLon, setUserLon] = useState(null);
  const { t } = useTranslation();


  useEffect(() => {
    let mounted = true; // Track component mount status

    const fetchCity = async (lat, lon) => {
      try {
        const response = await axios.get(
          `https://api.bigdatacloud.net/data/reverse-geocode-client`,
          {
            params: {
              latitude: lat,
              longitude: lon,
              localityLanguage: "en",
            },
          }
        );
        if (mounted && response.data && response.data.city) {
          setUserCity("" + response.data.city);
          setUserLat(lat);
          setUserLon(lon);
        }
      } catch (error) {
        console.error("Error fetching the city:", error);
      }
    };

    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            fetchCity(latitude, longitude);
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    getLocation();

    return () => {
      mounted = false; // Cleanup flag on unmount
    };
  }, []);


  const navigateToResults = (filterType) => {
    navigate("/results", { state: { filterType } });
  };

  const [selectedLocation, setSelectedLocation] = useState(null);



  // Function to calculate distance between two lat/lon points using Haversine formula
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      0.5 -
      Math.cos(dLat) / 2 +
      (Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        (1 - Math.cos(dLon))) /
        2;
    return R * 2 * Math.asin(Math.sqrt(a));
  };

  const [Listings, setListings] = useState(null);

  useEffect(() => {
    async function fetchListings() {
      try {
        setLoading(true);
        const listingsRef = collection(db, "listings");
        const q = query(
          listingsRef,
          where("status", "==", "approved"),
          orderBy("timestamp", "desc"),
          limit(10)
        );
        const querySnap = await getDocs(q);
        const listings = [];
        querySnap.forEach((doc) => {
          listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });

        if (userLat !== null && userLon !== null) {
          listings.forEach((listing) => {
            const { latitude, longitude } = listing.data; // Assuming latitude and longitude are separate fields in listing data
            if (latitude !== undefined && longitude !== undefined) {
              listing.distance = calculateDistance(
                userLat,
                userLon,
                latitude,
                longitude
              );
            } else {
            }
          });

          listings.sort((a, b) => a.distance - b.distance);
        }
        setListings(listings);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    fetchListings();
  }, [userCity, userLat, userLon]);





  return (
    <div>
      <Helmet>
        <title>
          Beytty - Real Estate Agents, Apartments, Home Loans & Property
          Valuations
        </title>
        <meta
          name="description"
          content="Discover Beytty, the premier real estate platform in Morocco. Find your dream home, connect with top agents, and explore the best properties for sale or rent."
        />
        <meta
          name="keywords"
          content="real estate, Morocco, property, buy, sell, rent, homes, offices, villas, apartments, land, properties for sale, properties for rent, new homes, luxury real estate, Moroccan real estate, real estate agents in Morocco, Beytty, property listings, vacation rentals, commercial real estate, investment properties, real estate market, real estate services, real estate platform, Marrakech, Casablanca, Rabat, Tangier, Agadir, Fes, property management, home loans, mortgage, real estate solutions, Morocco property investment, real estate deals, sell your home in Morocco, Moroccan property"
        />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Beytty",
            url: "https://beytty.com",
            logo: "https://beytty.com/apple-touch-icon.png",
            sameAs: [
              "https://www.facebook.com/beyttydotcom",
              "https://twitter.com/beyttydotcom",
              "https://www.instagram.com/beyttydotcom",
            ],
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+212-617-890-216",
              contactType: "Customer Service",
              areaServed: "MA",
              availableLanguage: "French",
            },
          })}
        </script>

        <meta
          property="og:title"
          content="Welcome to Beytty - Your Real Estate Solution in Morocco"
        />
        <meta
          property="og:description"
          content="Discover Beytty, the premier real estate platform in Morocco. Find your dream home, connect with top agents, and explore the best properties for sale or rent."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.beytty.com" />
        <meta property="og:image" content="/apple-touch-icon.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Welcome to Beytty - Your Real Estate Solution in Morocco"
        />
        <meta
          name="twitter:description"
          content="Discover Beytty, the premier real estate platform in Morocco. Find your dream home, connect with top agents, and explore the best properties for sale or rent."
        />
        <meta name="twitter:image" content="/apple-touch-icon.png" />

        <title>
          Beytty - Agents Immobiliers, Appartements, Prêts Immobiliers &
          Estimations de Valeur
        </title>
        <meta
          name="description"
          content="Découvrez Beytty, la première plateforme immobilière au Maroc. Trouvez la maison de vos rêves, connectez-vous avec les meilleurs agents et explorez les meilleures propriétés à vendre ou à louer."
        />
        <meta
          name="keywords"
          content="immobilier maroc, Maroc, propriété, acheter, vendre, louer, maisons, bureaux, villas, appartements, terrains, propriétés à vendre, propriétés à louer, maisons neuves, immobilier de luxe, immobilier marocain, agents immobiliers au Maroc, Beytty, annonces immobilières, locations de vacances, immobilier commercial, biens d'investissement, marché immobilier, services immobiliers, plateforme immobilière, Marrakech, Casablanca, Rabat, Tanger, Agadir, Fès, gestion de propriété, prêts immobiliers, hypothèque, solutions immobilières, investissement immobilier au Maroc, offres immobilières au maroc, vendre une maison au Maroc, propriété marocaine"
        />

        <meta
          property="og:title"
          content="Bienvenue chez Beytty - Votre Solution Immobilière au Maroc"
        />
        <meta
          property="og:description"
          content="Découvrez Beytty, la première plateforme immobilière au Maroc. Trouvez la maison de vos rêves, connectez-vous avec les meilleurs agents et explorez les meilleures propriétés à vendre ou à louer."
        />
        <meta
          name="twitter:title"
          content="Bienvenue chez Beytty - Votre Solution Immobilière au Maroc"
        />
        <meta
          name="twitter:description"
          content="Découvrez Beytty, la première plateforme immobilière au Maroc. Trouvez la maison de vos rêves, connectez-vous avec les meilleurs agents et explorez les meilleures propriétés à vendre ou à louer."
        />
      </Helmet>

      <div className="relative bg-gray-200 h-[18rem] sm:h-[29rem] flex overflow-hidden">
        <img
          alt="Woman visiting a luxurious villa in Morocco, experiencing modern real estate opportunities with Beytty"
          src={process.env.PUBLIC_URL + "/heroLG.webp"}
          className="hidden bg-gray-200 lg:flex object-cover w-full h-full"
        />
        <img
          alt="Woman visiting a luxurious villa in Morocco, experiencing modern real estate opportunities with Beytty"
          src={process.env.PUBLIC_URL + "/hero.webp"}
          className="flex bg-gray-200 lg:hidden object-cover w-full h-full"
        />

        <h1 className="md:hidden w-5/6 left-[8%] sm:left-[5%] text-white text-right custom-font-title absolute capitalize inset-0 flex items-center justify-end mb-24 text-three sm:text-4xl ">
          <strong className="flex-col ml-auto">
            {t("buysell")}
            <br />
            {t("Rent")} <span className="text-gray-300"> {t("yourhome")}</span>
          </strong>{" "}
        </h1>
        <div className="absolute md:hidden w-5/6 max-w-xl top-1/2 left-1/2 transform -translate-x-1/2">
          <div className="relative">
            <div className="flex sm:mb-20 w-full bg-white rounded-xl">
              <LocationSearchAutocomplete
                setSelectedLocation={setSelectedLocation}
              />
            </div>
          </div>
        </div>

        <div className="hidden md:flex w-full justify-center mx-auto items-center max-w-7xl inset-0  absolute px-4 top-[26%] transform ">
          <h1 className="hidden px-4 md:flex w-full text-white max-w-7xl items-center justify-center -top-[1%] text-right custom-font-title absolute capitalize  text-5xl">
            <strong className=" flex-col ml-auto">
              {t("buysell")}
              <br />
              {t("Rent")} <span className="text-gray-300">{t("yourhome")}</span>
            </strong>{" "}
          </h1>
          <div className="flex w-2/3 lg:w-1/2 ml-auto ">
            <div className="flex w-full bg-white rounded-xl">
              <LocationSearchAutocompleteLg
                setSelectedLocation={setSelectedLocation}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="hidden lg:block mx-auto pt-4 pb-4 space-y-6 max-w-7xl">
          <div className="flex mx-6 justify-between my-2 text-2xl">
            <div>
              {userLat !== null && userLon !== null ? (
                <>
                  <h2 className="custom-font font-black">
                    {" "}
                    {t("homesnearyou")}
                  </h2>
                  <h3 className="text-sm text-gray-500 opacity-80">
                    {t("checkoutlistingsnearyou")} {userCity}.
                  </h3>
                </>
              ) : (
                <>
                  <h2 className="custom-font font-black">
                    {t("discovernewhomes")}{" "}
                  </h2>
                  <h3 className="text-sm text-gray-500 opacity-80">
                    {t("checkoutnewlistings")}{" "}
                  </h3>
                </>
              )}
            </div>
            <div className="flex space-x-4 justify-end">
              <GrPrevious
                className="cursor-pointer hover:opacity-50"
                onClick={() => {
                  if (sliderRefLg.current) {
                    sliderRefLg.current.slickPrev();
                  }
                }}
              />
              <GrNext
                className="cursor-pointer hover:opacity-50"
                onClick={() => {
                  if (sliderRefLg.current) {
                    sliderRefLg.current.slickNext();
                  }
                }}
              />
            </div>
          </div>
          {loading ? ( // Check if loading state is true
            <div className="m-6 mb-6 space-x-2">
              <div className="flex gap-2 justify-between my-2">
                <ListingPlaceholder />
                <ListingPlaceholder />
                <ListingPlaceholder />
                <ListingPlaceholder />
              </div>
            </div>
          ) : (
            Listings &&
            Listings.length > 0 && (
              <div className="m-6 mb-6">
                <Slider
                  ref={sliderRefLg} // Attach the ref to the Slider component
                  infinite={Listings.length > 1} // Infinite scrolling only if more than one listing
                  arrows={false}
                  speed={500}
                  slidesToShow={4}
                  slidesToScroll={1}
                  draggable={false}
                >
                  {Listings.map((listing) => (
                    <ListingItem
                      key={listing.id}
                      listing={listing.data}
                      id={listing.id}
                    />
                  ))}
                </Slider>
              </div>
            )
          )}
        </div>
        <div className="hidden md:block lg:hidden mx-auto pt-4 pb-4 space-y-6 max-w-7xl">
          <div className="flex mx-2 justify-between my-2 px-4 text-2xl">
            <div>
              {userLat !== null && userLon !== null ? (
                <>
                  <h2 className="custom-font font-black">
                    {" "}
                    {t("homesnearyou")}
                  </h2>
                  <h3 className="text-sm text-gray-500 opacity-80">
                    {t("checkoutlistingsnearyou")} {userCity}.
                  </h3>
                </>
              ) : (
                <>
                  <h2 className="custom-font font-black">
                    {t("discovernewhomes")}{" "}
                  </h2>
                  <h3 className="text-sm text-gray-500 opacity-80">
                    {t("checkoutnewlistings")}{" "}
                  </h3>
                </>
              )}
            </div>
            <div className="flex space-x-4 justify-end">
              <GrPrevious
                className="cursor-pointer hover:opacity-50"
                onClick={() => {
                  if (sliderRefMd.current) {
                    sliderRefMd.current.slickPrev();
                  }
                }}
              />
              <GrNext
                className="cursor-pointer hover:opacity-50"
                onClick={() => {
                  if (sliderRefMd.current) {
                    sliderRefMd.current.slickNext();
                  }
                }}
              />
            </div>
          </div>
          {loading ? ( // Check if loading state is true
            <div className="m-6 mb-6 space-x-2">
              <div className="flex gap-2 justify-between my-2">
                <ListingPlaceholder />
                <ListingPlaceholder />
                <ListingPlaceholder />
              </div>
            </div>
          ) : (
            Listings &&
            Listings.length > 0 && (
              <div className="m-6 mb-6">
                <Slider
                  ref={sliderRefMd} // Attach the ref to the Slider component
                  infinite={Listings.length > 1} // Infinite scrolling only if more than one listing
                  arrows={false}
                  speed={500}
                  slidesToShow={3}
                  slidesToScroll={1}
                >
                  {Listings.map((listing) => (
                    <ListingItem
                      key={listing.id}
                      listing={listing.data}
                      id={listing.id}
                    />
                  ))}
                </Slider>
              </div>
            )
          )}
        </div>
        <div className="hidden sm:block md:hidden mx-auto pt-4 pb-4 space-y-6 max-w-7xl">
          <div className="flex mx-6 justify-between my-2 text-2xl">
            <div>
              {userLat !== null && userLon !== null ? (
                <>
                  <h2 className="custom-font font-black">
                    {" "}
                    {t("homesnearyou")}
                  </h2>
                  <h3 className="text-sm text-gray-500 opacity-80">
                    {t("checkoutlistingsnearyou")} {userCity}.
                  </h3>
                </>
              ) : (
                <>
                  <h2 className="custom-font font-black">
                    {t("discovernewhomes")}{" "}
                  </h2>
                  <h3 className="text-sm text-gray-500 opacity-80">
                    {t("checkoutnewlistings")}{" "}
                  </h3>
                </>
              )}
            </div>
            <div className="flex space-x-4 justify-end">
              <GrPrevious
                className="cursor-pointer hover:opacity-50"
                onClick={() => {
                  if (sliderRefSm.current) {
                    sliderRefSm.current.slickPrev();
                  }
                }}
              />
              <GrNext
                className="cursor-pointer hover:opacity-50"
                onClick={() => {
                  if (sliderRefSm.current) {
                    sliderRefSm.current.slickNext();
                  }
                }}
              />
            </div>
          </div>
          {loading ? ( // Check if loading state is true
            <div className="m-6 mb-6 space-x-2">
              <div className="flex gap-2 justify-between my-2">
                <ListingPlaceholder />
                <ListingPlaceholder />
              </div>
            </div>
          ) : (
            Listings &&
            Listings.length > 0 && (
              <div className="m-6 mb-6">
                <Slider
                  ref={sliderRefSm} // Attach the ref to the Slider component
                  infinite={Listings.length > 1} // Infinite scrolling only if more than one listing
                  arrows={false}
                  speed={500}
                  slidesToShow={2}
                  slidesToScroll={1}
                >
                  {Listings.map((listing) => (
                    <ListingItem
                      key={listing.id}
                      listing={listing.data}
                      id={listing.id}
                    />
                  ))}
                </Slider>
              </div>
            )
          )}
        </div>
        <div className="block sm:hidden w-full">
          <div className="flex mx-auto mt-6 px-10 max-w-screen justify-between my-2 text-xl">
            <div>
              {userLat !== null && userLon !== null ? (
                <>
                  <p className="custom-font font-black"> {t("homesnearyou")}</p>
                  <p className="text-sm text-gray-500 opacity-80">
                    {t("checkoutlistingsnearyou")} {userCity}.
                  </p>
                </>
              ) : (
                <>
                  <p className="custom-font font-black">
                    {t("discovernewhomes")}{" "}
                  </p>
                  <p className="text-sm text-gray-500 opacity-80">
                    {t("checkoutnewlistings")}{" "}
                  </p>
                </>
              )}
            </div>
            <div className="flex mt-2 space-x-4 justify-end">
              <GrPrevious
                className="cursor-pointer focus:opacity-50"
                onClick={() => {
                  if (sliderRef.current) {
                    sliderRef.current.slickPrev();
                  }
                }}
              />
              <GrNext
                className="cursor-pointer focus:opacity-50"
                onClick={() => {
                  if (sliderRef.current) {
                    sliderRef.current.slickNext();
                  }
                }}
              />
            </div>
          </div>
          {loading ? ( // Check if loading state is true
            <div className="m-6 mb-6  space-x-2">
              <div className="flex gap-2 px-4 justify-between my-2">
                <ListingPlaceholder />
              </div>
            </div>
          ) : (
            Listings &&
            Listings.length > 0 && (
              <div className="mb-8 mx-auto px-8 max-w-screen">
                <div className="mx-auto">
                  <Slider
                    ref={sliderRef} // Attach the ref to the Slider component
                    infinite={true}
                    dots={false}
                    arrows={false}
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                  >
                    {Listings.map((listing) => (
                      <ListingItem
                        key={listing.id}
                        listing={listing.data}
                        id={listing.id}
                      />
                    ))}
                  </Slider>
                </div>
              </div>
            )
          )}
        </div>
      </div>

      <div className="lg:block  hidden bg-gray-50 mt-4 ">
        <div className="mx-auto p-8  flex max-w-7xl ">
          <ul className=" grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-2">
            <li
              className="cursor-pointer border hover:shadow-2xl shadow-lg px-8 items-center flex flex-col rounded-xl bg-white transition duration-300"
              onClick={() => {
                window.scrollTo(0, 0); // Scroll to the top
                navigateToResults("sale");
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/image1.png"}
                alt="Vector illustration of a home for sale, representing real estate listings on Beytty"
                className=" bg-gray-200 mt-8 flex object-cover w-1/2"
              />
              <h1 className="mt-8 text-center text-2xl mb-4 custom-font font-bold">
                {t("buyahome")}
              </h1>
              <p className="text-center mb-4">{t("buyahometext")}</p>
              <button
                type="button"
                className="mt-auto w-2/3 font-semibold mb-8 border-2 border-red-500 rounded-xl text-red-500 hover:bg-red-600 hover:text-white px-4 py-2 transition duration-300"
                onClick={() => {
                  window.scrollTo(0, 0); // Scroll to the top
                  navigateToResults("sale");
                }}
              >
                {t("buy")}
              </button>
            </li>
            <li
              className="cursor-pointer border hover:shadow-2xl shadow-lg px-8 items-center flex flex-col rounded-xl bg-white transition duration-300"
              onClick={() => {
                window.scrollTo(0, 0); // Scroll to the top
                navigateToResults("rent");
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/image2.png"}
                alt="Vector illustration of a home for rent, representing real estate listings on Beytty"
                className=" bg-gray-200 mt-8 w-1/2 "
              />
              <h1 className="mt-8 text-center text-2xl mb-4 custom-font font-bold">
                {t("rentahome")}
              </h1>
              <p className="text-center mb-4">{t("rentahometext")}</p>
              <button
                type="button"
                className="mt-auto w-2/3 mb-8 border-2 border-red-500 rounded-xl text-red-500 hover:bg-red-600 hover:text-white font-semibold px-4 py-2 transition duration-300"
                onClick={() => {
                  window.scrollTo(0, 0); // Scroll to the top
                  navigateToResults("rent");
                }}
              >
                {t("rent")}
              </button>
            </li>
            <li
              className="cursor-pointer border hover:shadow-2xl shadow-lg px-8 items-center flex flex-col rounded-xl bg-white transition duration-300"
              onClick={() => {
                window.scrollTo(0, 0); // Scroll to the top
                navigate("/sell");
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/image3.png"}
                alt="Vector illustration of a sold home, representing real estate listings on Beytty"
                className=" bg-gray-200 mt-8 flex object-cover w-1/2"
              />
              <h1 className="mt-8 text-center text-2xl mb-4 custom-font font-bold">
                {t("sellahome")}
              </h1>

              <p className="text-center mb-4">{t("sellahometext")}</p>
              <button
                type="button"
                className="mt-auto w-2/3 font-semibold mb-8 border-2 border-red-500 rounded-xl text-red-500 hover:bg-red-600 hover:text-white px-4 py-2 transition duration-300"
                onClick={() => {
                  window.scrollTo(0, 0); // Scroll to the top
                  navigate("/sell");
                }}
              >
                {t("sell")}
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div className="lg:hidden bg-gray-50 mt-4">
        <div className="mx-auto p-8 flex max-w-7xl ">
          <ul className="w-full grid gap-6 grid-cols-1  gap-y-2">
            <li
              className="cursor-pointer border space-x-8  hover:shadow-2xl  shadow-lg px-8 items-center flex  rounded-xl bg-white transition duration-300"
              onClick={() => {
                window.scrollTo(0, 0); // Scroll to the top
                navigateToResults("sale");
              }}
            >
              <div className="items-center flex sm:p-4 h-full w-1/2 justify-center">
                <img
                  src={process.env.PUBLIC_URL + "/image1.png"}
                  alt="Vector illustration of a home for sale, representing real estate listings on Beytty"
                  className=" bg-gray-200  flex object-cover w-full md:w-2/3"
                />
              </div>
              <div className="w-1/2 ">
                <h1 className="mt-8 w-full text-2xl mb-4 custom-font font-bold">
                  {t("buyahome")}
                </h1>
                <p className="hidden sm:flex mb-4">{t("buyahometext")}</p>

                <button
                  type="button"
                  className="mt-auto font-semibold mb-8 border-2 w-full border-red-500 rounded-xl text-red-500 hover:bg-red-600 hover:text-white px-4 py-2 transition duration-300"
                  onClick={() => {
                    window.scrollTo(0, 0); // Scroll to the top
                    navigateToResults("sale");
                  }}
                >
                  {t("buy")}
                </button>
              </div>
            </li>

            <li
              className="cursor-pointer border space-x-8  hover:shadow-2xl shadow-lg px-8 items-center flex rounded-xl bg-white transition duration-300"
              onClick={() => {
                window.scrollTo(0, 0); // Scroll to the top
                navigateToResults("rent");
              }}
            >
              <div className="items-center flex sm:p-4  h-full w-1/2 justify-center">
                <img
                  src={process.env.PUBLIC_URL + "/image2.png"}
                  alt="Vector illustration of a home for rent, representing real estate listings on Beytty"
                  className=" bg-gray-200 justify-center items-center flex object-cover w-full md:w-2/3"
                />
              </div>
              <div className="w-1/2 justify-end">
                <h1 className="mt-8 w-full text-2xl mb-4 custom-font font-bold">
                  {t("rentahome")}
                </h1>
                <p className="hidden sm:flex mb-4">{t("rentahometext")}</p>

                <button
                  type="button"
                  className="mt-auto font-semibold mb-8 border-2 border-red-500 rounded-xl text-red-500 hover:bg-red-600 hover:text-white px-4 py-2 transition w-full duration-300"
                  onClick={() => {
                    window.scrollTo(0, 0); // Scroll to the top
                    navigateToResults("rent");
                  }}
                >
                  {t("rent")}
                </button>
              </div>
            </li>
            <li
              className="cursor-pointer border space-x-8 hover:shadow-2xl shadow-lg px-8 items-center flex rounded-xl bg-white transition duration-300"
              onClick={() => {
                window.scrollTo(0, 0); // Scroll to the top
                navigate("/sell");
              }}
            >
              <div className="items-center flex sm:p-4  h-full w-1/2 justify-center">
                <img
                  src={process.env.PUBLIC_URL + "/image3.png"}
                  alt="Vector illustration of a sold home, representing real estate listings on Beytty"
                  className=" bg-gray-200  flex object-cover w-full md:w-2/3"
                />
              </div>
              <div className="w-1/2 justify-end">
                <h1 className="mt-8 text-2xl ww-full mb-4 custom-font font-bold">
                  {t("sellahome")}
                </h1>
                <p className="hidden sm:flex mb-4">{t("sellahometext")}</p>

                <button
                  type="button"
                  className="mt-auto font-semibold mb-8 w-full border-2 border-red-500 rounded-xl text-red-500 hover:bg-red-600 hover:text-white px-4 py-2 transition duration-300"
                  onClick={() => {
                    window.scrollTo(0, 0); // Scroll to the top
                    navigate("/sell");
                  }}
                >
                  {t("sell")}
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
