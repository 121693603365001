// src/pages/NotFound.js
import React from "react";
import { useTranslation } from "react-i18next";

function NotFound() {
 const { t } = useTranslation();

  return (
    <div className="mx-auto max-w-7xl w-full px-4 h-screen">
      <div className="mt-[20%]">
        <h1 className="w-full mx-auto justify-center text-[1rem] md:text-[2rem] xl:text-[3rem] custom-font -mb-4 font-semibold flex">
          Oops !
        </h1>
        <h2 className="w-full mb-4 mx-auto justify-center text-[4rem] md:text-[6rem] xl:text-[8rem] custom-font font-semibold flex">
          404
        </h2>
        <p className="w-full lg:text-lg text-center mx-auto justify-center flex">
          {t("404message")}
        </p>
      </div>
    </div>
  );
}

export default NotFound;
