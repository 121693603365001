import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  arrayRemove,
  documentId,
  limit,
} from "firebase/firestore";
import { Tooltip } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import Spinner from "../components/Spinner";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { getAuth } from "firebase/auth";
import Contact from "../components/Contact";
import { IoMdClose } from "react-icons/io";
import { MdOutlineVilla } from "react-icons/md";
import { LuSofa } from "react-icons/lu";
import { LuParkingSquare } from "react-icons/lu";
import { MdOutlineConstruction } from "react-icons/md";
import Moment from "react-moment";
import MyPin from "../assets/svg/MyPin.svg";
import ReactMapGL, { Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { TbElevator } from "react-icons/tb";
import { TbAirConditioning } from "react-icons/tb";
import { GiPoliceOfficerHead } from "react-icons/gi";
import { FaSwimmingPool } from "react-icons/fa";
import { MdBalcony } from "react-icons/md";
import { TbRulerMeasure } from "react-icons/tb";
import { PiStairsDuotone } from "react-icons/pi";
import ListingItem from "../components/ListingItem";
import { GrPrevious, GrNext } from "react-icons/gr";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ListingPlaceholder from "../components/ListingPlaceholder";
import { TbGridDots } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/fr";
import { PiHeartFill, PiHeartDuotone } from "react-icons/pi";
import axios from "axios";
import { CgGym } from "react-icons/cg";
import { PiMosqueFill } from "react-icons/pi";
import { FaHospital } from "react-icons/fa";
import { MdSchool } from "react-icons/md";
import { IoShareOutline } from "react-icons/io5";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { FaCopy } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaMessage } from "react-icons/fa6";
import { PiBathtubBold } from "react-icons/pi";
import { LuBedDouble } from "react-icons/lu";
import { Helmet } from "react-helmet";
import Bar from "../components/Bar";
import {
  BsFillSunFill,
  BsFillSunriseFill,
  BsFillSunsetFill,
  BsSun,
} from "react-icons/bs";
import {
  BsFillHouseHeartFill,
  BsHouseCheckFill,
  BsHouseGearFill,
} from "react-icons/bs";
import { estimatePrice } from "../utils/priceEstimator";
import { ImPriceTags } from "react-icons/im";
import beyttyicon from "../assets/svg/beyttyicon.svg";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import { IoWarningOutline } from "react-icons/io5";
import FinancingCalculator from "../components/FinancingCalculator";
import { MdPark } from "react-icons/md";
import { WiDaySunny } from "react-icons/wi";

export default function Listing() {
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);
  const [isFavorite, setIsFavorite] = useState(false);
  const auth = getAuth();
  const params = useParams();
  const sliderRef = useRef(null);
  const sliderRefSm = useRef(null);
  const sliderRefMd = useRef(null);
  const sliderRefLg = useRef(null);
  const [listing, setListing] = useState(null);
  const [similarListings, setSimilarListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const contactFormRef = useRef(null);
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const [contactLandlord, setContactLandlord] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nearbySchools, setNearbySchools] = useState([]);
  const [nearbyGyms, setNearbyGyms] = useState([]);
  const [nearbyHospitals, setNearbyHospitals] = useState([]);
  const [nearbyMosques, setNearbyMosques] = useState([]);
  const [openSharing, setOpenSharing] = useState(false);
  const [estimatedPrice, setEstimatedPrice] = useState(null);
  const [weatherData, setWeatherData] = useState({});

  const weatherImages = {
    Clear: "/sunny.webp", // Image for sunny weather
    Clouds: "/cloudy.webp", // Image for cloudy weather
    Rain: "/rainimg.webp", // Image for rainy weather
    Snow: "/snowy.webp", // Image for snowy weather
    // Default fallback for other conditions
    Thunderstorm: "/rainimg.jpg", // Treat thunderstorm as rainy
    Drizzle: "/rainimg.webp", // Treat drizzle as rainy
    Mist: "/cloudy.webp", // Treat mist as cloudy
    // Add any other weather conditions you'd like to handle
  };

  useEffect(() => {
    if (listing && listing.latitude && listing.longitude) {
      fetchWeatherData(listing.latitude, listing.longitude);
    }
  }, [listing]);

  const fetchWeatherData = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://api.openweathermap.org/data/2.5/weather`,
        {
          params: {
            lat: latitude,
            lon: longitude,
            units: "metric",
            appid: "8247cbd146fb49da6e5f01a4e9a70537",
          },
        }
      );

      const { temp } = response.data.main;
      const { main: condition } = response.data.weather[0];

      const translatedCondition =
        t(`weatherConditions.${condition}`) || condition;

      // Set the background image based on the weather condition
      const backgroundImage = weatherImages[condition] || "/cloudyimg.jpg"; // Use cloudy as a default fallback

      setWeatherData({
        temperature: temp,
        condition: translatedCondition,
        backgroundImage,
      });
    } catch (error) {
      console.error("Error fetching weather data:", error);
    }
  };

  const handleOpenSharing = () => {
    setOpenSharing(true);
  };

  const handleCloseSharing = () => {
    setOpenSharing(false);
  };

  useEffect(() => {
    let isMounted = true;

    const checkFavoriteStatus = async () => {
      if (auth.currentUser) {
        const userRef = doc(db, "users", auth.currentUser.uid);
        const agentRef = doc(db, "agents", auth.currentUser.uid);
        const adminRef = doc(db, "admins", auth.currentUser.uid);

        const [userDoc, agentDoc, adminDoc] = await Promise.all([
          getDoc(userRef),
          getDoc(agentRef),
          getDoc(adminRef),
        ]);

        if (userDoc.exists() || agentDoc.exists() || adminDoc.exists()) {
          const userData = userDoc.exists()
            ? userDoc.data()
            : agentDoc.exists()
            ? agentDoc.data()
            : adminDoc.data();

          if (
            userData.favoriteListings &&
            userData.favoriteListings.includes(params.listingId)
          ) {
            if (isMounted) {
              setIsFavorite(true);
            }
          }
        } else {
          navigate("/sign-up");
        }
      }
    };

    checkFavoriteStatus();

    return () => {
      isMounted = false;
    };
  }, [auth.currentUser, db, params.listingId, navigate]);

  const toggleFavorite = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (!auth.currentUser) {
      navigate("/sign-in");
      return;
    }

    if (auth.currentUser) {
      const userRef = doc(db, "users", auth.currentUser.uid);
      const agentRef = doc(db, "agents", auth.currentUser.uid);
      const adminRef = doc(db, "admins", auth.currentUser.uid);

      const [userDoc, agentDoc, adminDoc] = await Promise.all([
        getDoc(userRef),
        getDoc(agentRef),
        getDoc(adminRef),
      ]);

      if (userDoc.exists() || agentDoc.exists() || adminDoc.exists()) {
        const ref = userDoc.exists()
          ? userRef
          : agentDoc.exists()
          ? agentRef
          : adminRef;
        const userData = userDoc.exists()
          ? userDoc.data()
          : agentDoc.exists()
          ? agentDoc.data()
          : adminDoc.data();

        if (
          userData.favoriteListings &&
          userData.favoriteListings.includes(params.listingId)
        ) {
          await updateDoc(ref, {
            favoriteListings: arrayRemove(params.listingId),
          });
          setIsFavorite(false);
        } else {
          await updateDoc(ref, {
            favoriteListings: arrayUnion(params.listingId),
          });
          setIsFavorite(true);
        }
      } else {
        navigate("/sign-up");
      }
    }
  };

  const [viewport, setViewport] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 14,
  });

  useEffect(() => {
    const handleScroll = () => {
      if (contactFormRef.current) {
        const contactFormPosition =
          contactFormRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (contactFormPosition <= windowHeight) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getOrdinalSuffix = (number) => {
    if (number === 0) {
      return t("groundfloor"); // Translation key for "Rdc"
    }

    const j = number % 10;
    const k = number % 100;

    if (j === 1 && k !== 11) {
      return `${number}${t("firstfloor")}`; // Translation key for "1st Floor"
    }
    if (j === 2 && k !== 12) {
      return `${number}${t("secondfloor")}`; // Translation key for "2nd Floor"
    }
    if (j === 3 && k !== 13) {
      return `${number}${t("thirdfloor")}`; // Translation key for "3rd Floor"
    }
    return `${number}${t("otherfloors")}`; // Translation key for "th Floor"
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    document.body.classList.toggle("popup-open");
  };

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    let isMounted = true;

    if (isPopupOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isPopupOpen]);

  useEffect(() => {
    let isMounted = true;

    const fetchUser = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, "admins", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists() && isMounted) {
          setIsAdmin(true);
        }
      }
      if (isMounted) {
        setLoadingUser(false);
      }
    };
    fetchUser();

    return () => {
      isMounted = false;
    };
  }, [auth.currentUser]);

  useEffect(() => {
    let isMounted = true;

    const fetchListing = async () => {
      const docRef = doc(db, "beyttylistings", params.listingId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const listingData = docSnap.data();
        listingData.id = params.listingId;

        if (listingData.status === "approved" || isAdmin) {
          if (isMounted) {
            setListing(listingData);
            setViewport({
              latitude: listingData.latitude,
              longitude: listingData.longitude,
              zoom: 13,
            });

            const {
              listingType,
              type, // Assuming this is the new field you're referring to
              latitude,
              longitude,
              size,
              condition,
              sunExposure,
              yearBuilt,
            } = listingData;

            const currentListingId = listingData.id;
            const currentLat = listingData.latitude;
            const currentLng = listingData.longitude;

            // Call price estimation function
            const estimatedPrice = estimatePrice({
              listingType,
              latitude,
              longitude,
              size,
              yearBuilt,
              sunExposure,
              condition,
            });

            setEstimatedPrice(estimatedPrice);

            // Pass both listingType and type here
            fetchSimilarListings(
              listingType,
              type,
              currentLat,
              currentLng,
              currentListingId
            );
          }
        } else {
          navigate("/");
        }
      } else {
        navigate("/");
      }

      if (isMounted) {
        setLoading(false);
      }
    };

    if (!loadingUser) {
      fetchListing();
    }

    return () => {
      isMounted = false;
    };
  }, [params.listingId, isAdmin, loadingUser, navigate]);

  useEffect(() => {
    let isMounted = true;

    const fetchNearbyPlaces = async (latitude, longitude) => {
      const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
      const categories = {
        schools: "school",
        gyms: "park",
        hospitals: "hospital",
        mosques: "mosque",
      };

      const fetchCategoryPlaces = async (category) => {
        const url = `https://api.mapbox.com/search/searchbox/v1/category/${categories[category]}?access_token=${accessToken}&language=en&limit=5&proximity=${longitude},${latitude}&country=MA`;
        try {
          const response = await axios.get(url);
          return response.data.features;
        } catch (error) {
          return [];
        }
      };

      const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371; // Radius of the Earth in kilometers
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(lat1 * (Math.PI / 180)) *
            Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in kilometers
        return distance.toFixed(2); // Return distance with two decimals
      };

      const processPlaces = (places) => {
        return places
          .filter(
            (place) =>
              Array.isArray(place.geometry.coordinates) &&
              place.geometry.coordinates.length === 2
          ) // Ensure valid coordinates
          .map((place) => {
            const [longitude, latitude] = place.geometry.coordinates; // Extract coordinates from geometry
            const distance = calculateDistance(
              latitude, // Latitude from the API
              longitude, // Longitude from the API
              listing.latitude, // Listing's latitude
              listing.longitude // Listing's longitude
            );
            return {
              ...place,
              distance,
            };
          })
          .sort((a, b) => a.distance - b.distance)
          .slice(0, 3); // Limit to the 3 closest places
      };

      const [schools, gyms, hospitals, mosques] = await Promise.all([
        fetchCategoryPlaces("schools"),
        fetchCategoryPlaces("gyms"),
        fetchCategoryPlaces("hospitals"),
        fetchCategoryPlaces("mosques"),
      ]);

      if (isMounted) {
        setNearbySchools(processPlaces(schools));
        setNearbyGyms(processPlaces(gyms));
        setNearbyHospitals(processPlaces(hospitals));
        setNearbyMosques(processPlaces(mosques));
      }
    };

    if (listing) {
      fetchNearbyPlaces(listing.latitude, listing.longitude);
    }

    return () => {
      isMounted = false;
    };
  }, [listing]);

  // Function to calculate the distance between two geographic points (in kilometers)
  const calculateDistance = (lat1, lng1, lat2, lng2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLng = ((lng2 - lng1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  // Function to fetch and sort similar listings by proximity to the current listing
  const fetchSimilarListings = async (
    listingType,
    listingTypeValue,
    currentLat,
    currentLng,
    currentListingId
  ) => {
    const q = query(
      collection(db, "listings"),
      where("status", "==", "approved"), // Ensure the listings are approved
      where("listingType", "==", listingType), // Filter by the listingType
      where("type", "==", listingTypeValue), // Filter by the type (e.g., house, apartment)
      where(documentId(), "!=", currentListingId), // Exclude the current listing
      limit(5) // Limit the number of results to 5
    );

    const querySnapshot = await getDocs(q);
    const similarListingsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Add distance to each listing by calculating distance from current listing
    const listingsWithDistance = similarListingsData.map((listing) => {
      const { latitude, longitude } = listing; // Assuming each listing has latitude and longitude fields
      const distance = calculateDistance(
        currentLat,
        currentLng,
        latitude,
        longitude
      );
      return { ...listing, distance };
    });

    // Sort the listings by distance (ascending: closest first)
    const sortedListings = listingsWithDistance.sort(
      (a, b) => a.distance - b.distance
    );

    // Set the sorted listings with distance information
    setSimilarListings(sortedListings);
  };
  if (loading || loadingUser) {
    return <Bar loading={loading} />;
  }

  const images = listing.imgUrls.map((url) => ({
    original: url,
    thumbnail: url,
    originalHeight: 300,
    originalWidth: 400,
  }));

  // Function to handle different sharing methods
  const handleShareMethod = (method) => {
    const listingLink = `https://beytty.com/listingdetails/${listing.id}`;
    const message = `Checkout this listing on Beytty: ${listingLink}`;

    switch (method) {
      case "copy":
        navigator.clipboard.writeText(listingLink);
        alert("Link copied to clipboard!");
        break;
      case "whatsapp":
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`
        );
        break;
      case "email":
        window.location.href = `mailto:?subject=Check out this listing on beytty.com&body=${encodeURIComponent(
          message
        )}`;
        break;
      case "message":
        // Replace with actual SMS handling logic
        handleSmsShare(message);
        break;
      default:
        break;
    }

    // Close the dialog after handling the share method
    handleCloseSharing();
  };

  // Example function to handle SMS sharing
  const handleSmsShare = (message) => {
    window.location.href = `sms:?body=${encodeURIComponent(message)}`;
  };

  // Calculate price difference percentage
  let priceIndicator = "";
  const priceDifferencePercentage =
    ((listing.regularPrice - estimatedPrice) / estimatedPrice) * 100;

  if (priceDifferencePercentage <= -20) {
    priceIndicator = t("priceIndicator.veryGoodPrice"); // Translation key for Very Good Price
  } else if (priceDifferencePercentage <= -10) {
    priceIndicator = t("priceIndicator.goodPrice"); // Translation key for Good Price
  } else if (priceDifferencePercentage >= 20) {
    priceIndicator = t("priceIndicator.expensivePrice"); // Translation key for Expensive Price
  } else {
    priceIndicator = t("priceIndicator.fairPrice"); // Translation key for Fair Price
  }

  return (
    <main>
      <Helmet>
        <title>Property Listing Details - Beytty</title>
        <meta
          name="description"
          content="View detailed information about this property listing on Beytty. Explore features, images, and other details to help you decide if this is your next home, office, or villa."
        />
        <meta
          name="keywords"
          content="property listing, real estate, property details, home for sale, office for rent, villa listing, Beytty, Morocco"
        />
        <meta property="og:title" content="Property Listing Details - Beytty" />
        <meta
          property="og:description"
          content="View detailed information about this property listing on Beytty. Explore features, images, and other details to help you decide if this is your next home, office, or villa."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://www.beytty.com/listingdetails/${params.listingId}`}
        />
        <meta property="og:image" content="/apple-touch-icon.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Property Listing Details - Beytty"
        />
        <meta
          name="twitter:description"
          content="View detailed information about this property listing on Beytty. Explore features, images, and other details to help you decide if this is your next home, office, or villa."
        />
        <meta name="twitter:image" content="/apple-touch-icon.png" />

        <title>Détails de l'annonce immobilière - Beytty</title>
        <meta
          name="description"
          content="Consultez des informations détaillées sur cette annonce immobilière sur Beytty. Découvrez les caractéristiques, les images et d'autres détails pour vous aider à décider si c'est votre prochaine maison, bureau ou villa."
        />
        <meta
          name="keywords"
          content="annonce immobilière au maroc, immobilier, détails de la propriété, maison à vendre, bureau à louer, annonce de villa, Beytty, Maroc"
        />
        <meta
          property="og:title"
          content="Détails de l'annonce immobilière - Beytty"
        />
        <meta
          property="og:description"
          content="Consultez des informations détaillées sur cette annonce immobilière sur Beytty. Découvrez les caractéristiques, les images et d'autres détails pour vous aider à décider si c'est votre prochaine maison, bureau ou villa."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://beytty.com/listingdetails/${params.listingId}`}
        />
        <meta
          property="og:image"
          content={`https://beytty.com/listingdetails/${params.listingId}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Détails de l'annonce immobilière - Beytty"
        />
        <meta
          name="twitter:description"
          content="Consultez des informations détaillées sur cette annonce immobilière sur Beytty. Découvrez les caractéristiques, les images et d'autres détails pour vous aider à décider si c'est votre prochaine maison, bureau ou villa."
        />
        <meta
          name="twitter:image"
          content={`https://beytty.com/listingdetails/${params.listingId}`}
        />
      </Helmet>

      <div className="md:hidden bg-gray-100 mb-4">
        <div
          style={{
            overflow: "hidden",
            objectFit: "cover",
            position: "relative",
          }}
          className="relative flex bg-gray-200 "
        >
          <ImageGallery
            items={images}
            showThumbnails={false}
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={false}
            disableArrowKeys={true}
            disableSwipe={false}
            onClick={() => togglePopup()}
            onSlide={(currentIndex) => setCurrentImageIndex(currentIndex)}
            additionalClass="w-full"
            renderItem={(item) => (
              <div
                className="w-full"
                style={{
                  height: "250px",
                  width: "100%",
                  overflow: "hidden",
                  objectFit: "cover",
                }}
              >
                <img
                  src={item.original}
                  alt={item.originalAlt}
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
              </div>
            )}
          />
          {listing.imgUrls.length > 1 && (
            <button className="absolute flex rounded-xl top-4 left-4 px-2 py-1 text-sm bg-white text-black cursor-pointer">
              <div className="flex items-center">
                <div
                  className={`w-3 h-3 ${
                    listing.type === "rent" ? "bg-yellow-500" : "bg-green-600"
                  } rounded-full mr-2`}
                ></div>
                <p className="flex text-black opacity-80 capitalize text-center font-light">
                  {listing.type === "rent" ? t("forrent") : t("forsale")}
                </p>
              </div>
            </button>
          )}
          {listing.imgUrls.length > 1 && (
            <button className="absolute flex rounded top-4 right-4 px-2 py-1 bg-black bg-opacity-70 text-black cursor-pointer">
              <div className="flex items-center">
                <p className="flex text-sm font-semibold  text-white  opacity-80 ">
                  {currentImageIndex + 1} of {listing.imgUrls.length}
                </p>
              </div>
            </button>
          )}
        </div>
      </div>

      <div className="hidden md:block max-w-7xl px-8 py-8 mx-auto">
        <div className="w-full items-center px-2 flex justify-end gap-4 mb-4">
          <p
            className="text-sm underline items-center flex cursor-pointer gap-1"
            onClick={handleOpenSharing}
          >
            <IoShareOutline
              className="cursor-pointer"
              onClick={handleOpenSharing}
            />
            {t("share")}
          </p>
          <button className="flex rounded-xl underline items-center gap-1 text-sm transition ease-in-out duration-100 cursor-pointer">
            {isFavorite ? (
              <PiHeartFill
                className="text-black  text-xl transition-all ease-in-out duration-100 cursor-pointer"
                onClick={toggleFavorite}
              />
            ) : (
              <PiHeartDuotone
                className="text-black hover:opacity-100 opacity-70 bg-opacity-50 text-xl transition-all ease-in-out duration-100 hover:text-red-500  cursor-pointer"
                onClick={toggleFavorite}
              />
            )}{" "}
            {t("save")}
          </button>{" "}
        </div>

        <div className="hidden md:grid grid-cols-2 gap-2">
          <div className="col-span-2 md:col-span-1 relative h-[400px] lg:h-[500px]">
            {/* Main Image with rounded top-left and bottom-left corners */}
            <img
              src={listing.imgUrls[0]}
              alt="Main Listing"
              className="w-full bg-gray-200 h-full cursor-pointer object-cover rounded-tl-2xl rounded-bl-2xl"
              onClick={togglePopup}
            />
            {listing.imgUrls.length > 1 && (
              <button className="absolute flex rounded-xl top-4 left-4 px-2 py-2 bg-white text-black cursor-pointer">
                <div className="flex items-center">
                  <div
                    className={`w-3 h-3 ${
                      listing.type === "rent" ? "bg-yellow-500" : "bg-green-600"
                    } rounded-full mr-2`}
                  ></div>
                  <p className="flex text-black opacity-80 capitalize text-center font-light">
                    {listing.type === "rent" ? t("forrent") : t("forsale")}
                  </p>
                </div>
              </button>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 grid grid-cols-2 grid-rows-2 h-[400px] lg:h-[500px] gap-2 relative">
            {/* 4 Images in the Right Side */}
            {listing.imgUrls.slice(1, 5).map((url, index) => (
              <img
                key={index}
                src={url}
                alt={`Listing Image ${index + 2}`}
                onClick={togglePopup}
                className={`w-full bg-gray-200 cursor-pointer h-full object-cover ${
                  index === 1 ? "rounded-tr-2xl" : ""
                }${index === 3 ? " rounded-br-2xl" : ""}`}
              />
            ))}
            {/* Add button on the bottom right of the last image */}
            {listing.imgUrls.length > 5 && (
              <button
                className="absolute flex rounded-xl transition ease-in-out duration-100 bottom-4 right-4 px-3 py-2 hover:bg-gray-200 text-sm bg-white text-black cursor-pointer"
                onClick={togglePopup}
              >
                <TbGridDots className="mr-2 text-xl" /> {t("seeall")}{" "}
                {listing.imgUrls.length} photos
              </button>
            )}
          </div>
        </div>
      </div>

      <Dialog
        className="justify-center relative"
        open={openSharing}
        onClose={handleCloseSharing}
      >
        <DialogContent>
          <DialogContentText>
            <div className="bg-white py-4 rounded-xl max-w-md">
              <h2 className="text-2xl mb-8 text-black">
                {t("sharethislisting")}
              </h2>
              <div className="flex gap-2">
                <div className="flex w-2/4 md:w-1/4">
                  <img
                    src={listing.imgUrls || "/placeholder-image.jpg"}
                    alt="Listing Image"
                    className="h-16 w-24 px-1  object-cover rounded-xl mb-2"
                  />
                </div>
                <p className="w-full text-xs md:text-sm text-black">
                  {listing.address.slice(0, 25)}
                  {listing.address.length > 25 && "..."} • {listing.bedrooms}{" "}
                  {t("beds")} • {listing.bathrooms}
                  {t("baths")}
                </p>
              </div>
              <div className="mt-8 grid grid-cols-2 text-right grid-rows-1 gap-4 text-md ">
                {/* Copy Link */}
                <div
                  className="flex items-center col-span-3 md:col-span-1 row-span-1 text-black py-3 hover:bg-gray-100 px-8 text-right cursor-pointer rounded-xl border-2"
                  onClick={() => handleShareMethod("copy")}
                >
                  <FaCopy className="text-lg text-stone-700 lg:text-xl mr-2" />
                  {t("copylink")}
                </div>
                {/* Whatsapp */}
                <div
                  className="flex items-center col-span-3 md:col-span-1 row-span-1 text-black py-3 hover:bg-gray-100 px-8 text-right cursor-pointer rounded-xl border-2"
                  onClick={() => handleShareMethod("whatsapp")}
                >
                  <IoLogoWhatsapp className="text-lg text-green-700 lg:text-xl mr-2" />
                  Whatsapp
                </div>
                {/* Email */}
                <div
                  className="flex items-center col-span-3 md:col-span-1 row-span-1 text-black py-3 hover:bg-gray-100 px-8 text-right cursor-pointer rounded-xl border-2"
                  onClick={() => handleShareMethod("email")}
                >
                  <MdEmail className="text-lg text-yellow-500 lg:text-xl mr-2" />
                  Email
                </div>
                {/* Message */}
                <div
                  className="flex items-center col-span-3 md:col-span-1 row-span-1 text-black py-3 hover:bg-gray-100 px-8 text-right cursor-pointer rounded-xl border-2"
                  onClick={() => handleShareMethod("message")}
                >
                  <FaMessage className="text-md text-blue-300 lg:text-lg mr-2" />
                  Message
                </div>
              </div>{" "}
              <div className="absolute top-4 right-4 ">
                <IoMdClose
                  className="text-xl text-gray-400 hover:text-black cursor-pointer"
                  onClick={handleCloseSharing}
                />
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {isPopupOpen && (
        <div className="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-90 overflow-hidden flex justify-center backdrop-filter backdrop-blur-xl  items-center z-50">
          <div className="w-full">
            <button
              className="absolute top-0 right-0 p-2 z-50"
              onClick={togglePopup}
            >
              <IoMdClose className="w-10 md:w-12 h-12 md:mr-8  text-white" />
            </button>
            {/* Display all images in the popup */}
            <div className="">
              <div className="">
                <ImageGallery
                  showPlayButton={false}
                  showFullscreenButton={false}
                  items={images}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="hidden space-x-4 md:flex max-w-7xl px-8 mx-auto">
        <div className="flex justify-between w-2/3 mx-auto">
          <div className="mx-auto w-full">
            <div className="flex w-full mx-auto justify-between mb-3 text-black">
              <div className="flex justify-start w-full">
                <div className="flex flex-col items-start">
                  <span className="text-3xl custom-font lg:text-4xl font-bold mr-2">
                    {listing.regularPrice
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    DH
                  </span>
                  {listing.type === "sale" && estimatedPrice != null && (
                    <>
                      {/* Price Indicator */}
                      <span
                        className={`mt-1 mb-1 px-2 items-center flex gap-1 py-1 text-sm rounded-lg ${
                          priceIndicator === t("priceIndicator.veryGoodPrice")
                            ? "bg-green-100 text-green-600"
                            : priceIndicator === t("priceIndicator.goodPrice")
                            ? "bg-yellow-100 text-yellow-600"
                            : priceIndicator ===
                              t("priceIndicator.expensivePrice")
                            ? "bg-red-100 text-red-600"
                            : "bg-gray-100 text-gray-600"
                        }`}
                      >
                        {priceIndicator ===
                          t("priceIndicator.veryGoodPrice") && (
                          <span>
                            <FaRegThumbsUp />
                          </span>
                        )}
                        {priceIndicator === t("priceIndicator.goodPrice") && (
                          <span>
                            <FaRegThumbsUp />
                          </span>
                        )}
                        {priceIndicator === t("priceIndicator.fairPrice") && (
                          <span>
                            <FaRegThumbsUp />
                          </span>
                        )}
                        {priceIndicator ===
                          t("priceIndicator.expensivePrice") && (
                          <span>
                            {" "}
                            <IoWarningOutline />
                          </span>
                        )}
                        {priceIndicator}
                      </span>
                      <Tooltip title={t("beyttyEstimatedPrice")} arrow>
                        <span className="mb-2 flex-col items-center gap-1 p-2 text-sm bg-red-50 mt-1 rounded-lg ">
                          <div className="flex items-center mb-1 gap-1">
                            <img
                              src={beyttyicon}
                              alt="Beytty Icon"
                              className="w-4 h-4 -mr-0.5 mb-1"
                            />
                            <span className="font-semibold">Beytty</span>
                            Estimation :
                          </div>

                          <p className="font-semibold">
                            {(
                              Math.floor((estimatedPrice * 0.95) / 1000) * 1000
                            ).toLocaleString("en-US")}{" "}
                            DH -{" "}
                            {(
                              Math.ceil((estimatedPrice * 1.05) / 1000) * 1000
                            ).toLocaleString("en-US")}{" "}
                            DH
                          </p>
                        </span>
                      </Tooltip>
                    </>
                  )}

                  <span className="lg:text-lg opacity-60">
                    {listing.address}
                  </span>
                  <span className="text-xs mt-1 font-light opacity-60">
                    REF : {listing.referenceCode}
                  </span>
                </div>
                <div className="flex justify-end space-x-6  w-full">
                  {listing.listingType != "land" &&
                    listing.listingType != "office" &&
                    listing.listingType != "commercial" && (
                      <div className="flex flex-col items-start">
                        <span className="text-3xl custom-font lg:text-4xl font-bold mr-2">
                          {+listing.bedrooms > 1 ? `${listing.bedrooms}` : "1"}
                        </span>
                        <span className="text-lg ">
                          {" "}
                          {listing.bedrooms === 1 ? t("Bed") : t("Beds")}
                        </span>
                      </div>
                    )}
                  {listing.listingType != "land" &&
                    listing.listingType != "office" &&
                    listing.listingType != "commercial" && (
                      <div className="flex flex-col items-start">
                        <span className="text-3xl custom-font lg:text-4xl font-bold mr-2">
                          {+listing.bathrooms > 1
                            ? `${listing.bathrooms}`
                            : "1"}
                        </span>
                        <span className="text-lg ">
                          {" "}
                          {listing.bathrooms === 1 ? t("Bath") : t("Baths")}
                        </span>
                      </div>
                    )}
                  <div className="flex flex-col items-start">
                    <span className="text-3xl custom-font lg:text-4xl font-bold mr-2">
                      {listing.size}
                    </span>
                    <span className="text-lg ">m²</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 grid grid-cols-2 lg:grid-cols-3 grid-rows-1 gap-4 text-md ">
              <div className="flex items-center col-span-3 md:col-span-1 row-span-1 bg-gray-100  p-4 rounded-xl border-2">
                <MdOutlineVilla className="text-xl lg:text-2xl mr-2" />
                {listing.listingType === "apartment"
                  ? t("apartment")
                  : listing.listingType === "villa"
                  ? t("villa")
                  : listing.listingType === "riad"
                  ? t("riad")
                  : listing.listingType === "land"
                  ? t("land")
                  : listing.listingType === "office"
                  ? t("office")
                  : listing.listingType === "commercial"
                  ? t("commercial")
                  : listing.listingType === "farmhouse"
                  ? t("farmhouse")
                  : listing.listingType}{" "}
              </div>{" "}
              {listing.type === "sale" && (
                <div className="flex items-center  col-span-3 md:col-span-1 row-span-1 bg-gray-100 p-4 rounded-xl border-2">
                  <>
                    <TbRulerMeasure className="text-2xl mr-2" />
                    {(
                      Math.round(
                        (listing.regularPrice || listing.regularPrice) /
                          listing.size /
                          100
                      ) * 100
                    ).toFixed(0)}{" "}
                    DH/m²
                  </>
                </div>
              )}
              {listing.furnished && (
                <div className="flex items-center capitalize col-span-3 md:col-span-1 row-span-1 bg-gray-100  p-4 rounded-xl border-2">
                  <li className="flex items-center whitespace-nowrap">
                    <LuSofa className="text-xl lg:text-2xl mr-2" />
                    {listing.furnished ? t("furnished") : "Not furnished"}
                    {/* Button or additional content for the second cell */}
                  </li>
                </div>
              )}
              {listing.parking && (
                <div className="flex items-center capitalize col-span-3 md:col-span-1 row-span-1 bg-gray-100  p-4 rounded-xl border-2">
                  <LuParkingSquare className="text-xl lg:text-2xl mr-2" />
                  {listing.parking ? t("parking") : "No parking"}
                  {/* Button or additional content for the third cell */}
                </div>
              )}
              {listing.listingType != "land" && (
                <div className="flex items-center  col-span-3 md:col-span-1 row-span-1 bg-gray-100  p-4 rounded-xl border-2">
                  <MdOutlineConstruction className="text-xl lg:text-2xl mr-2" />
                  {t("builtin")} {listing.yearBuilt}
                  {/* Button or additional content for the fourth cell */}
                </div>
              )}
              {listing.secured && (
                <div className="flex items-center capitalize col-span-3 md:col-span-1 row-span-1 bg-gray-100  p-4 rounded-xl border-2">
                  <li className="flex items-center whitespace-nowrap">
                    <GiPoliceOfficerHead className="text-xl lg:text-2xl mr-2" />
                    {listing.secured ? t("secured") : "Not secured"}
                    {/* Button or additional content for the second cell */}
                  </li>
                </div>
              )}
              {listing.airCondition && (
                <div className="flex items-center capitalize col-span-3 md:col-span-1 row-span-1 bg-gray-100  p-4 rounded-xl border-2">
                  <TbAirConditioning className="text-xl lg:text-2xl mr-2" />
                  {t(listing.airCondition ? "Air" : "NoAir")}{" "}
                  {/* Button or additional content for the third cell */}
                </div>
              )}
              {listing.balcony && (
                <div className="flex items-center capitalize col-span-3 md:col-span-1 row-span-1 bg-gray-100  p-4 rounded-xl border-2">
                  <MdBalcony className="text-xl lg:text-2xl mr-2" />
                  {listing.balcony ? t("balcony") : "No balcony"}
                  {/* Button or additional content for the third cell */}
                </div>
              )}
              {listing.elevator && (
                <div className="flex items-center capitalize col-span-3 md:col-span-1 row-span-1 bg-gray-100  p-4 rounded-xl border-2">
                  <TbElevator className="text-xl lg:text-2xl mr-2" />
                  {listing.elevator ? t("elevator") : "No elevator"}
                  {/* Button or additional content for the third cell */}
                </div>
              )}
              {listing.floor !== undefined &&
                listing.listingType != "land" && ( // Ensure listing.floor is defined before rendering
                  <div className="flex items-center capitalize col-span-1 row-span-1 bg-gray-100 p-4 rounded-xl border-2">
                    <PiStairsDuotone className="text-xl lg:text-2xl mr-2" />
                    {getOrdinalSuffix(listing.floor)}
                    {/* Additional content if needed */}
                  </div>
                )}
              {listing.pool && (
                <div className="flex items-center capitalize col-span-3 md:col-span-1 row-span-1 bg-gray-100  p-4 rounded-xl border-2">
                  <FaSwimmingPool className="md:text-md lg:text-2xl mr-2" />
                  {listing.pool ? t("pool") : "No Pool"}
                  {/* Button or additional content for the third cell */}
                </div>
              )}
              {listing.condition && (
                <div
                  className={`flex items-center col-span-3 md:col-span-1 row-span-1  p-4 rounded-xl border-2
                   ${
                     listing.condition === "brandnew"
                       ? "border-blue-300 bg-blue-100"
                       : ""
                   }
                   ${
                     listing.condition === "perfect"
                       ? "border-green-300 bg-green-100"
                       : ""
                   }
                   ${
                     listing.condition === "good"
                       ? "border-orange-200 bg-orange-100"
                       : ""
                   }
                   ${
                     listing.condition === "renovation"
                       ? "border-red-200 bg-red-100"
                       : ""
                   }
                 `}
                >
                  {listing.condition === "brandnew" && (
                    <>
                      <BsFillHouseHeartFill className="md:text-md lg:text-2xl mr-2" />
                      <span>{t("Brandnew")}</span>
                    </>
                  )}
                  {listing.condition === "perfect" && (
                    <>
                      <BsFillHouseHeartFill className="md:text-md lg:text-2xl mr-2" />
                      <span>{t("perfect")}</span>
                    </>
                  )}
                  {listing.condition === "good" && (
                    <>
                      <BsHouseCheckFill className="md:text-md lg:text-2xl mr-2" />
                      <span>{t("Goodcondition")}</span>
                    </>
                  )}
                  {listing.condition === "renovation" && (
                    <>
                      <BsHouseGearFill className="md:text-md lg:text-2xl mr-2" />
                      <span>{t("Needsrenovation")}</span>
                    </>
                  )}
                </div>
              )}
              {listing.sunExposure && (
                <div
                  className={`flex items-center col-span-3 md:col-span-1 row-span-1  p-4 rounded-xl border-2
                   ${
                     listing.sunExposure === "allday"
                       ? "border-yellow-500 bg-yellow-100"
                       : ""
                   }
                   ${
                     listing.sunExposure === "morning"
                       ? "border-yellow-400 bg-yellow-100"
                       : ""
                   }
                   ${
                     listing.sunExposure === "afternoon"
                       ? "border-yellow-300 bg-yellow-100"
                       : ""
                   }
                   ${
                     listing.sunExposure === "nosun"
                       ? "border-yellow-200 bg-yellow-100"
                       : ""
                   }
                 `}
                >
                  {listing.sunExposure === "allday" && (
                    <>
                      <BsFillSunFill className="md:text-md lg:text-2xl mr-2" />
                      <span>{t("Allday")}</span>
                    </>
                  )}
                  {listing.sunExposure === "morning" && (
                    <>
                      <BsFillSunriseFill className="md:text-md lg:text-2xl mr-2" />
                      <span>{t("Morning")}</span>
                    </>
                  )}
                  {listing.sunExposure === "afternoon" && (
                    <>
                      <BsFillSunsetFill className="md:text-md lg:text-2xl mr-2" />
                      <span>{t("Afternoon")}</span>
                    </>
                  )}
                  {listing.sunExposure === "nosun" && (
                    <>
                      <BsSun className="md:text-md lg:text-2xl mr-2" />
                      <span>{t("Nosun")}</span>
                    </>
                  )}
                </div>
              )}
            </div>{" "}
            <div className="mt-10">
              <div className="h-[1px] mb-8 bg-gray-200"></div>
              <h1 className="text-2xl ">Description</h1>
              <p className="mt-4">{listing.description}</p>
              <p className="mt-4">
                {t("listingcreated")} : {}
                <Moment
                  locale={i18n.language}
                  className="font-semibold "
                  fromNow
                >
                  {listing.timestamp?.toDate()}
                </Moment>
              </p>
              {listing.lastEdited && (
                <p>
                  {t("lastupdate")} : {}
                  <Moment
                    locale={i18n.language}
                    className="font-semibold"
                    fromNow
                  >
                    {listing.lastEdited.toDate()}
                  </Moment>
                </p>
              )}
              {listing.tourLink &&
                listing.tourLink.includes("matterport.com/show") && (
                  <>
                    <div className="h-[1px] mt-8 mb-8 bg-gray-200"></div>
                    <h1 className="mt-8 mb-6 text-2xl">{t("3dTour")}</h1>
                    <div>
                      <iframe
                        src={listing.tourLink}
                        width="100%"
                        height="500px"
                        frameBorder="0"
                        allowFullScreen
                        title="3D Tour"
                      ></iframe>
                    </div>
                  </>
                )}
              <div className="h-[1px] mt-8 mb-8 bg-gray-200"></div>
              <h1 className="mt-8 mb-6 text-2xl "> {t("location")}</h1>
              <div className=" bg-gray-100 w-full h-[400px]">
                <ReactMapGL
                  {...viewport}
                  width="100%"
                  height="100%"
                  mapStyle="mapbox://styles/mohamedmakdad/clsqz4sct00xn01pf8o9vg38g"
                  onMove={(evt) => {
                    setViewport(evt.viewport);
                  }}
                  mapboxApiAccessToken={
                    process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
                  }
                >
                  <Marker
                    latitude={listing.latitude}
                    longitude={listing.longitude}
                    offsetLeft={0}
                    offsetTop={0}
                  >
                    <div
                      onMouseEnter={() => setIsMessageOpen(true)}
                      onMouseLeave={() => setIsMessageOpen(false)}
                    >
                      <div className="w-28 h-28 relative mx-auto mt-auto flex rounded-full bg-red-600 opacity-20 items-center justify-center"></div>
                      {isMessageOpen && (
                        <div className="absolute w-full left-1/2 transform -translate-x-1/2 bottom-full bg-white p-2 rounded-md shadow-md border border-gray-300 text-sm">
                          <div className="absolute w-4 h-4 bg-white transform rotate-45 -bottom-2 left-1/2 -translate-x-1/2 border-b border-r border-gray-300"></div>
                          <span className="block">{t("exactlocation")}</span>
                        </div>
                      )}
                      <img
                        className="absolute flex top-6 right-9"
                        src={MyPin}
                        alt="Beytty Marker"
                        style={{
                          width: "40px",
                          height: "40px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </Marker>
                </ReactMapGL>
              </div>

              <div className="h-[1px] mt-8 mb-8 bg-gray-200"></div>
              <h2 className="mt-8 mb-6 text-2xl">{t("nearbyplaces")}</h2>
              <div className="p-4 border rounded-xl mt-4 shadow-lg">
                <h3 className="flex gap-2 mb-4 text-xl items-center">
                  <MdSchool className="text-2xl" />
                  {t("schools")}
                </h3>
                <ul>
                  {nearbySchools.length > 0 ? (
                    nearbySchools.map((place, index) => (
                      <li
                        className="flex justify-between items-center w-full"
                        key={index}
                      >
                        <h4 className="w-full">• {place.properties.name}</h4>
                        <h4 className="w-full text-right">
                          {place.distance} km
                        </h4>
                      </li>
                    ))
                  ) : (
                    <li className="w-full text-center">{t("noData")}</li>
                  )}
                </ul>
              </div>

              <div className="p-4 border rounded-xl mt-4 shadow-lg">
                <h3 className="flex gap-2 mb-4 text-xl items-center">
                  <MdPark className="text-2xl" />
                  {t("parks")}
                </h3>
                <ul>
                  {nearbyGyms.length > 0 ? (
                    nearbyGyms.map((place, index) => (
                      <li
                        className="flex justify-between items-center w-full"
                        key={index}
                      >
                        <h4 className="w-full">• {place.properties.name}</h4>
                        <h4 className="w-full text-right">
                          {place.distance} km
                        </h4>
                      </li>
                    ))
                  ) : (
                    <li className="w-full text-center">{t("noData")}</li>
                  )}
                </ul>
              </div>

              <div className="p-4 border rounded-xl mt-4 shadow-lg">
                <h3 className="flex gap-2 mb-4 text-xl items-center">
                  <FaHospital className="text-2xl" />
                  {t("health")}
                </h3>
                <ul>
                  {nearbyHospitals.length > 0 ? (
                    nearbyHospitals.map((place, index) => (
                      <li
                        className="flex justify-between items-center w-full"
                        key={index}
                      >
                        <h4 className="w-full">• {place.properties.name}</h4>
                        <h4 className="w-full text-right">
                          {place.distance} km
                        </h4>
                      </li>
                    ))
                  ) : (
                    <li className="w-full text-center">{t("noData")}</li>
                  )}
                </ul>
              </div>

              <div className="p-4 border rounded-xl mt-4 shadow-lg">
                <h3 className="flex gap-2 mb-4 text-xl items-center">
                  <PiMosqueFill className="text-2xl" />
                  {t("mosques")}
                </h3>
                <ul>
                  {nearbyMosques.length > 0 ? (
                    nearbyMosques.map((place, index) => (
                      <li
                        className="flex justify-between items-center w-full"
                        key={index}
                      >
                        <h4 className="w-full">• {place.properties.name}</h4>
                        <h4 className="w-full text-right">
                          {place.distance} km
                        </h4>
                      </li>
                    ))
                  ) : (
                    <li className="w-full text-center">{t("noData")}</li>
                  )}
                </ul>
              </div>

              {/* Weather Display */}
              <div className="h-[1px] mt-8 mb-8 bg-gray-200"></div>
              <h2 className="mt-8 mb-6 text-2xl">{t("currentweather")}</h2>
              <div
                className="p-4 text-white border rounded-xl mt-4 shadow-lg"
                style={{
                  backgroundImage: `url(${weatherData.backgroundImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <ul>
                  <li className="flex py-4 text-xl items-center w-full">
                    <h4 className="w-full">{weatherData.condition}</h4>
                    <h4 className="w-full justify-end flex">
                      {" "}
                      {weatherData.temperature}°C
                    </h4>
                  </li>
                </ul>
              </div>

              <div className="h-[1px] mb-8 mt-8 bg-gray-200"></div>
              {listing.listingType != "land" && (
                <div>
                  <h1 className="text-2xl mt-6 mb-2"> {t("summary")}</h1>
                  <ul className="list-disc">
                    {listing.listingType && (
                      <li className="flex items-center gap-2">
                        <MdOutlineVilla />
                        {listing.listingType === "apartment"
                          ? t("apartment")
                          : listing.listingType === "villa"
                          ? t("villa")
                          : listing.listingType === "riad"
                          ? t("riad")
                          : listing.listingType === "land"
                          ? t("land")
                          : listing.listingType === "office"
                          ? t("office")
                          : listing.listingType === "commercial"
                          ? t("commercial")
                          : listing.listingType === "farmhouse"
                          ? t("farmhouse")
                          : listing.listingType}{" "}
                        {listing.type === "rent" ? t("Forrent") : t("Forsale")}{" "}
                      </li>
                    )}{" "}
                    {listing.yearBuilt && (
                      <li className="flex items-center gap-2">
                        <MdOutlineConstruction /> {t("yearbuilt")}
                        {listing.yearBuilt}
                      </li>
                    )}
                    {listing.size > 0 && (
                      <li className="flex items-center gap-2">
                        <TbRulerMeasure /> {t("size")}
                        {listing.size} m²
                      </li>
                    )}
                    {(listing.floor ||
                      listing.floor === 0 ||
                      listing.floor === "rdc") && (
                      <li className="flex items-center gap-2">
                        <PiStairsDuotone />
                        {getOrdinalSuffix(listing.floor)}
                      </li>
                    )}{" "}
                    {listing.bedrooms > 0 && (
                      <li className="flex items-center gap-2">
                        <LuBedDouble /> {listing.bedrooms}
                        {listing.bathrooms === 1 ? t("Bed") : t("Beds")}
                      </li>
                    )}
                    {listing.bathrooms > 0 && (
                      <li className="flex items-center gap-2">
                        <PiBathtubBold /> {listing.bathrooms}
                        {listing.bathrooms === 1 ? t("Bath") : t("Baths")}
                      </li>
                    )}
                    {listing.parking && (
                      <li className="flex items-center gap-2">
                        <LuParkingSquare /> {t("Parking")}
                      </li>
                    )}
                    {listing.furnished && (
                      <li className="flex items-center gap-2">
                        <LuSofa /> {t("furnished")}
                      </li>
                    )}
                    {listing.secured && (
                      <li className="flex items-center gap-2">
                        <GiPoliceOfficerHead /> {t("secured")}
                      </li>
                    )}
                    {listing.elevator && (
                      <li className="flex items-center gap-2">
                        <TbElevator /> {t("elevator")}
                      </li>
                    )}
                    {listing.airCondition && (
                      <li className="flex items-center gap-2">
                        <TbAirConditioning /> {t("air")}
                      </li>
                    )}
                    {listing.balcony && (
                      <li className="flex items-center gap-2">
                        <TbElevator /> {t("balcony")}
                      </li>
                    )}
                    {listing.pool && (
                      <li className="flex items-center gap-2">
                        <FaSwimmingPool /> {t("pool")}
                      </li>
                    )}
                  </ul>
                  <div className="h-[1px] mt-8 mb-4 bg-gray-200"></div>
                </div>
              )}
              {listing.type === "sale" && (
                <>
                  <div className="mt-10">
                    <FinancingCalculator
                      regularPrice={listing?.regularPrice || 0}
                    />
                  </div>
                  <div className="h-[1px] mt-8 mb-4 bg-gray-200"></div>
                </>
              )}

              <div>
                <div className="hidden lg:block mx-auto pt-4 pb-4 ">
                  <div className="flex  justify-between my-2 text-2xl">
                    <h1 className="text-2xl ">{t("similar")}</h1>
                    <div className="flex space-x-4 justify-end">
                      <GrPrevious
                        className="cursor-pointer hover:opacity-50"
                        onClick={() => {
                          if (sliderRefLg.current) {
                            sliderRefLg.current.slickPrev();
                          }
                        }}
                      />
                      <GrNext
                        className="cursor-pointer hover:opacity-50"
                        onClick={() => {
                          if (sliderRefLg.current) {
                            sliderRefLg.current.slickNext();
                          }
                        }}
                      />
                    </div>
                  </div>
                  {loading ? ( // Check if loading state is true
                    <div className="m-6 mb-6 space-x-2">
                      <div className="flex gap-2 justify-between my-2">
                        <ListingPlaceholder />
                        <ListingPlaceholder />
                        <ListingPlaceholder />
                        <ListingPlaceholder />
                      </div>
                    </div>
                  ) : similarListings && similarListings.length > 0 ? (
                    <div className="mt-4 mb-6">
                      <Slider
                        ref={sliderRefLg}
                        infinite={similarListings.length > 1} // Infinite scrolling only if more than one listing
                        arrows={false}
                        speed={500}
                        slidesToShow={2}
                        slidesToScroll={1}
                        draggable={false}
                      >
                        {similarListings.map((listing) =>
                          listing.imgUrls && listing.imgUrls.length > 0 ? (
                            <ListingItem
                              key={listing.id}
                              listing={listing}
                              id={listing.id}
                            />
                          ) : (
                            <ListingPlaceholder key={listing.id} />
                          )
                        )}
                      </Slider>
                    </div>
                  ) : (
                    <p className="text-center text-gray-500 mt-4 mb-4">
                      {t("noSimilarListings")}
                    </p>
                  )}
                </div>
                <div className="hidden md:block lg:hidden mx-auto pt-4 pb-4">
                  <div className="flex justify-between my-2 text-2xl">
                    <h1 className="text-2xl">{t("similar")}</h1>
                    <div className="flex space-x-4 justify-end">
                      <GrPrevious
                        className="cursor-pointer hover:opacity-50"
                        onClick={() => {
                          if (sliderRefMd.current) {
                            sliderRefMd.current.slickPrev();
                          }
                        }}
                      />
                      <GrNext
                        className="cursor-pointer hover:opacity-50"
                        onClick={() => {
                          if (sliderRefMd.current) {
                            sliderRefMd.current.slickNext();
                          }
                        }}
                      />
                    </div>
                  </div>
                  {loading ? ( // Check if loading state is true
                    <div className="mt-4 mb-6 space-x-2">
                      <div className="flex gap-2 justify-between my-2">
                        <ListingPlaceholder />
                        <ListingPlaceholder />
                        <ListingPlaceholder />
                      </div>
                    </div>
                  ) : similarListings && similarListings.length > 0 ? (
                    <div className="mt-4 mb-6">
                      <Slider
                        ref={sliderRefMd} // Attach the ref to the Slider component
                        infinite={similarListings.length > 1} // Infinite scrolling only if more than one listing
                        arrows={false}
                        speed={500}
                        slidesToShow={2}
                        slidesToScroll={1}
                      >
                        {similarListings.map((listing) =>
                          listing.imgUrls && listing.imgUrls.length > 0 ? (
                            <ListingItem
                              key={listing.id}
                              listing={listing}
                              id={listing.id}
                            />
                          ) : (
                            <ListingPlaceholder key={listing.id} />
                          )
                        )}
                      </Slider>
                    </div>
                  ) : (
                    <p className="text-center text-gray-500 mt-4 mb-4">
                      {t("noSimilarListings")}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/3">
          <div className="ml-6 sticky mb-2 top-24">
            <div className=" w-full rounded-md flex justify-center items-center">
              {contactLandlord && (
                <Contact userRef={listing.userRef} listing={listing} />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex md:hidden px-4 justify-between w-full mx-auto">
        <div className="mx-auto w-full">
          <div className="flex w-full mx-auto justify-between mb-3 text-black">
            <div className="flex justify-start w-full">
              <div className="flex flex-col items-start">
                <span className="text-xl custom-font md:text-4xl font-bold mr-2">
                  {listing.regularPrice
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  DH
                </span>
                {listing.type === "sale" && estimatedPrice != null && (
                  <>
                    {/* Price Indicator */}
                    <span
                      className={`mt-1 mb-1 px-2 items-center text-xs flex gap-1 py-1  rounded-lg ${
                        priceIndicator === t("priceIndicator.veryGoodPrice")
                          ? "bg-green-100 text-green-600"
                          : priceIndicator === t("priceIndicator.goodPrice")
                          ? "bg-yellow-100 text-yellow-600"
                          : priceIndicator ===
                            t("priceIndicator.expensivePrice")
                          ? "bg-red-100 text-red-600"
                          : "bg-gray-100 text-gray-600"
                      }`}
                    >
                      {priceIndicator === t("priceIndicator.veryGoodPrice") && (
                        <span>
                          <FaRegThumbsUp />
                        </span>
                      )}
                      {priceIndicator === t("priceIndicator.goodPrice") && (
                        <span>
                          <FaRegThumbsUp />
                        </span>
                      )}
                      {priceIndicator === t("priceIndicator.fairPrice") && (
                        <span>
                          <FaRegThumbsUp />
                        </span>
                      )}
                      {priceIndicator ===
                        t("priceIndicator.expensivePrice") && (
                        <span>
                          {" "}
                          <IoWarningOutline />
                        </span>
                      )}
                      {priceIndicator}
                    </span>
                    <span className="mb-2 flex-col items-center gap-1 p-1 text-xs bg-red-50  mt-1 rounded-lg ">
                      <div className="flex items-center mb-1 gap-1">
                        <img
                          src={beyttyicon}
                          alt="Beytty Icon"
                          className="w-4 h-4 -mr-0.5 mb-1"
                        />
                        <span className="font-semibold">Beytty</span>
                        Estimation :
                      </div>

                      <p className="font-semibold">
                        {(
                          Math.floor((estimatedPrice * 0.9) / 1000) * 1000
                        ).toLocaleString("en-US")}{" "}
                        DH -{" "}
                        {(
                          Math.ceil((estimatedPrice * 1.1) / 1000) * 1000
                        ).toLocaleString("en-US")}{" "}
                        DH
                      </p>
                    </span>
                  </>
                )}
                <span className="text-sm opacity-50">{listing.address}</span>
                <span className="text-small mt-1 font-light opacity-60">
                  REF : {listing.referenceCode}
                </span>
              </div>
              <div className="flex justify-end space-x-2  w-full">
                {listing.listingType != "land" &&
                  listing.listingType != "office" &&
                  listing.listingType != "commercial" && (
                    <div className="flex flex-col items-start">
                      <span className="text-xl custom-font md:text-4xl font-bold mr-2">
                        {+listing.bedrooms > 1 ? `${listing.bedrooms}` : "1"}
                      </span>
                      <span className="text-sm ">
                        {listing.bathrooms === 1 ? t("Bed") : t("Beds")}
                      </span>
                    </div>
                  )}
                {listing.listingType != "land" &&
                  listing.listingType != "office" &&
                  listing.listingType != "commercial" && (
                    <div className="flex flex-col items-start">
                      <span className="text-xl custom-font md:text-4xl font-bold mr-2">
                        {+listing.bathrooms > 1 ? `${listing.bathrooms}` : "1"}
                      </span>
                      <span className="text-sm ">
                        {listing.bathrooms === 1 ? t("Bath") : t("Baths")}
                      </span>
                    </div>
                  )}
                <div className="flex flex-col items-start">
                  <span className="text-xl custom-font md:text-4xl font-bold mr-2">
                    {listing.size}
                  </span>
                  <span className="text-sm ">m²</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 grid grid-rows-1 grid-cols-2 gap-4 text-sm ">
            {/* Content for the grid cells */}
            <div className="flex items-center col-span-1 row-span-1 bg-gray-100  p-4 rounded-xl border-2">
              <MdOutlineVilla className="text-xl lg:text-2xl mr-2" />
              {listing.listingType === "apartment"
                ? t("apartment")
                : listing.listingType === "villa"
                ? t("villa")
                : listing.listingType === "riad"
                ? t("riad")
                : listing.listingType === "land"
                ? t("land")
                : listing.listingType === "office"
                ? t("office")
                : listing.listingType === "commercial"
                ? t("commercial")
                : listing.listingType === "farmhouse"
                ? t("farmhouse")
                : listing.listingType}{" "}
              {/* Button or additional content for the first cell */}
            </div>
            {listing.type === "sale" && (
              <div className="flex items-center  col-span-1 row-span-1 bg-gray-100 p-4 rounded-xl border-2">
                <>
                  <TbRulerMeasure className="text-2xl mr-2" />
                  {(
                    Math.round(
                      (listing.regularPrice || listing.regularPrice) /
                        listing.size /
                        100
                    ) * 100
                  ).toFixed(0)}{" "}
                  DH/m²
                </>
              </div>
            )}

            {listing.furnished && (
              <div className="flex items-center capitalize col-span-1 row-span-1 bg-gray-100  p-4 rounded-xl border-2">
                <li className="flex items-center whitespace-nowrap">
                  <LuSofa className="text-xl lg:text-2xl mr-2" />
                  {listing.furnished ? t("furnished") : "Not furnished"}
                  {/* Button or additional content for the second cell */}
                </li>
              </div>
            )}

            {listing.parking && (
              <div className="flex items-center capitalize col-span-1 row-span-1 bg-gray-100  p-4 rounded-xl border-2">
                <LuParkingSquare className="text-xl lg:text-2xl mr-2" />
                {listing.parking ? t("parking") : "No parking"}
                {/* Button or additional content for the third cell */}
              </div>
            )}
            {listing.listingType != "land" && (
              <div className="flex items-center  col-span-1 row-span-1 bg-gray-100  p-4 rounded-xl border-2">
                <MdOutlineConstruction className="text-xl lg:text-2xl mr-2" />
                {t("builtin")} {listing.yearBuilt}
                {/* Button or additional content for the fourth cell */}
              </div>
            )}

            {listing.secured && (
              <div className="flex items-center capitalize col-span-1 row-span-1 bg-gray-100  p-4 rounded-xl border-2">
                <li className="flex items-center whitespace-nowrap">
                  <GiPoliceOfficerHead className="text-xl lg:text-2xl mr-2" />
                  {listing.secured ? t("secured") : "Not secured"}
                  {/* Button or additional content for the second cell */}
                </li>
              </div>
            )}
            {listing.airCondition && (
              <div className="flex items-center capitalize col-span-1 row-span-1 bg-gray-100  p-4 rounded-xl border-2">
                <TbAirConditioning className="text-xl lg:text-2xl mr-2" />
                {t(listing.airCondition ? "air" : "noAir")}{" "}
                {/* Button or additional content for the third cell */}
              </div>
            )}

            {listing.balcony && (
              <div className="flex items-center capitalize col-span-1 row-span-1 bg-gray-100  p-4 rounded-xl border-2">
                <MdBalcony className="text-xl lg:text-2xl mr-2" />
                {listing.balcony ? t("balcony") : "No balcony"}
                {/* Button or additional content for the third cell */}
              </div>
            )}
            {listing.elevator && (
              <div className="flex items-center capitalize col-span-1 row-span-1 bg-gray-100  p-4 rounded-xl border-2">
                <TbElevator className="text-xl lg:text-2xl mr-2" />
                {listing.elevator ? t("elevator") : "No elevator"}
                {/* Button or additional content for the third cell */}
              </div>
            )}

            {listing.floor !== undefined &&
              listing.listingType != "land" && ( // Ensure listing.floor is defined before rendering
                <div className="flex items-center capitalize col-span-1 row-span-1 bg-gray-100 p-4 rounded-xl border-2">
                  <PiStairsDuotone className="text-xl lg:text-2xl mr-2" />
                  {getOrdinalSuffix(listing.floor)}
                  {/* Additional content if needed */}
                </div>
              )}

            {listing.pool && (
              <div className="flex items-center capitalize col-span-1 row-span-1 bg-gray-100  p-4 rounded-xl border-2">
                <FaSwimmingPool className="md:text-md lg:text-2xl mr-2" />
                {listing.pool ? t("pool") : "No Pool"}
                {/* Button or additional content for the third cell */}
              </div>
            )}
            {listing.condition && (
              <div
                className={`flex items-center capitalize col-span-1 row-span-1  p-4 rounded-xl border-2
                  ${
                    listing.condition === "brandnew"
                      ? "border-blue-300 bg-blue-100"
                      : ""
                  }
                   ${
                     listing.condition === "perfect"
                       ? "border-green-300 bg-green-100"
                       : ""
                   }
                   ${
                     listing.condition === "good"
                       ? "border-orange-200 bg-orange-100"
                       : ""
                   }
                   ${
                     listing.condition === "renovation"
                       ? "border-red-200 bg-red-100"
                       : ""
                   }
                 `}
              >
                {listing.condition === "brandnew" && (
                  <>
                    <BsFillHouseHeartFill className="md:text-md lg:text-2xl mr-2" />
                    <span>{t("Brandnew")}</span>
                  </>
                )}
                {listing.condition === "perfect" && (
                  <>
                    <BsFillHouseHeartFill className="md:text-md lg:text-2xl mr-2" />
                    <span>{t("perfect")}</span>
                  </>
                )}
                {listing.condition === "good" && (
                  <>
                    <BsHouseCheckFill className="md:text-md lg:text-2xl mr-2" />
                    <span>{t("Goodcondition")}</span>
                  </>
                )}
                {listing.condition === "renovation" && (
                  <>
                    <BsHouseGearFill className="md:text-md lg:text-2xl mr-2" />
                    <span>{t("Needsrenovation")}</span>
                  </>
                )}
              </div>
            )}
            {listing.sunExposure && (
              <div
                className={`flex items-center capitalize col-span-1 row-span-1  p-4 rounded-xl border-2
                   ${
                     listing.sunExposure === "allday"
                       ? "border-yellow-500 bg-yellow-100"
                       : ""
                   }
                   ${
                     listing.sunExposure === "morning"
                       ? "border-yellow-400 bg-yellow-100"
                       : ""
                   }
                   ${
                     listing.sunExposure === "afternoon"
                       ? "border-yellow-300 bg-yellow-100"
                       : ""
                   }
                   ${
                     listing.sunExposure === "nosun"
                       ? "border-yellow-200 bg-yellow-100"
                       : ""
                   }
                 `}
              >
                {listing.sunExposure === "allday" && (
                  <>
                    <BsFillSunFill className="md:text-md lg:text-2xl mr-2" />
                    <span>{t("Allday")}</span>
                  </>
                )}
                {listing.sunExposure === "morning" && (
                  <>
                    <BsFillSunriseFill className="md:text-md lg:text-2xl mr-2" />
                    <span>{t("Morning")}</span>
                  </>
                )}
                {listing.sunExposure === "afternoon" && (
                  <>
                    <BsFillSunsetFill className="md:text-md lg:text-2xl mr-2" />
                    <span>{t("Afternoon")}</span>
                  </>
                )}
                {listing.sunExposure === "nosun" && (
                  <>
                    <BsSun className="md:text-md lg:text-2xl mr-2" />
                    <span>{t("Nosun")}</span>
                  </>
                )}
              </div>
            )}
          </div>{" "}
          <button
            className={`bg-white items-center flex w-full gap-4 text-white px-4 py-4 border-t mt-6 sticky-button ${
              !isVisible ? "hidden" : ""
            }`}
          >
            <div className="text-black text-left w-1/2 rounded-full h-12  ">
              <div className="font-semibold custom-font text-lg">
                {listing.regularPrice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                DH{" "}
              </div>
              <div className="text-sm underline text-slate-500 ">
                {t("totalbeforetaxes")}
              </div>
            </div>
            <button
              className="bg-custom-red w-1/2  text-white px-2 py-3
              
              
               rounded-xl"
              onClick={() =>
                contactFormRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                })
              }
            >
              {t("contact")}
            </button>{" "}
            <div className="flex items-center gap-3 justify-center">
              {isFavorite ? (
                <PiHeartFill
                  className="text-black text-2xl transition-all ease-in-out duration-100 cursor-pointer"
                  onClick={toggleFavorite}
                />
              ) : (
                <PiHeartDuotone
                  className="text-black opacity-70 bg-opacity-50 text-2xl transition-all ease-in-out duration-100  cursor-pointer"
                  onClick={toggleFavorite}
                />
              )}
              <IoShareOutline
                className="text-black  opacity-70 bg-opacity-50 text-2xl transition-all ease-in-out duration-100 hover:text-red-500  cursor-pointer"
                onClick={handleOpenSharing}
              />
            </div>
          </button>
          <div className="mt-10 text-sm">
            <div className="h-[1px] mb-8 bg-gray-200"></div>
            <h1 className="text-xl">Description</h1>
            <p className="mt-4">{listing.description}</p>
            <p className="mt-4">
              {t("listingcreated")} : {}
              <Moment locale={i18n.language} className="font-semibold " fromNow>
                {listing.timestamp?.toDate()}
              </Moment>
            </p>
            {listing.lastEdited && (
              <p>
                {t("lastupdate")} : {}
                <Moment
                  locale={i18n.language}
                  className="font-semibold"
                  fromNow
                >
                  {listing.lastEdited.toDate()}
                </Moment>
              </p>
            )}

            {listing.tourLink &&
              listing.tourLink.includes("matterport.com/show") && (
                <>
                  <div className="h-[1px] mt-8 mb-8 bg-gray-200"></div>
                  <h1 className="mt-8 mb-6 text-xl">{t("3dTour")}</h1>
                  <div>
                    <iframe
                      src={listing.tourLink}
                      width="100%"
                      height="400px"
                      frameBorder="0"
                      allowFullScreen
                      title="3D Tour"
                    ></iframe>
                  </div>
                </>
              )}
            <div className="h-[1px] mt-8 mb-8 bg-gray-200"></div>
            <h1 className="mt-8 mb-6 text-xl"> {t("location")}</h1>
            <div
              id="contactSection"
              className="mt-4 bg-gray-100  h-[250px] rounded-lg"
            >
              <ReactMapGL
                {...viewport}
                width="100%"
                height="100%"
                mapStyle="mapbox://styles/mohamedmakdad/clsqz4sct00xn01pf8o9vg38g"
                onMove={(evt) => {
                  setViewport(evt.viewport);
                }}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
              >
                <Marker
                  latitude={listing.latitude}
                  longitude={listing.longitude}
                  offsetLeft={-20}
                  offsetTop={-10}
                >
                  <div>
                    <div className="w-24 h-24 relative mx-auto mt-auto flex rounded-full bg-red-600 opacity-20 items-center justify-center"></div>
                    {isMessageOpen && (
                      <div className="absolute w-full left-1/2 transform -translate-x-1/2 bottom-full bg-white p-2 rounded-md shadow-md border border-gray-300 text-sm">
                        <div className="absolute w-4 h-4 bg-white transform rotate-45 -bottom-2 left-1/2 -translate-x-1/2 border-b border-r border-gray-300"></div>
                        <span className="block">{t("exactlocation")}</span>
                      </div>
                    )}
                    <img
                      className="absolute flex top-4 right-7"
                      src={MyPin}
                      alt="Beytty Marker"
                      style={{
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Marker>
              </ReactMapGL>
            </div>

            {/* Nearby Places Mobile */}
            <div className="h-[1px] mt-8 mb-8 bg-gray-200"></div>
            <h2 className="mt-8 mb-6 text-xl">{t("nearbyplaces")}</h2>
            <div className="p-4 border rounded-xl mt-4 shadow-lg">
              <h3 className="flex gap-2 mb-4 text-lg items-center">
                <MdSchool className="text-xl" />
                {t("schools")}
              </h3>
              <ul>
                {nearbySchools.length > 0 ? (
                  nearbySchools.map((place, index) => (
                    <li
                      className="flex justify-between items-center w-full"
                      key={index}
                    >
                      <h4 className="w-full">• {place.properties.name}</h4>
                      <h4 className="w-full text-right">{place.distance} km</h4>
                    </li>
                  ))
                ) : (
                  <li className="w-full text-center">{t("noData")}</li>
                )}
              </ul>
            </div>

            <div className="p-4 border rounded-xl mt-4 shadow-lg">
              <h3 className="flex gap-2 mb-4 text-lg items-center">
                <MdPark className="text-xl" />
                {t("parks")}
              </h3>
              <ul>
                {nearbyGyms.length > 0 ? (
                  nearbyGyms.map((place, index) => (
                    <li
                      className="flex justify-between items-center w-full"
                      key={index}
                    >
                      <h4 className="w-full">• {place.properties.name}</h4>
                      <h4 className="w-full text-right">{place.distance} km</h4>
                    </li>
                  ))
                ) : (
                  <li className="w-full text-center">{t("noData")}</li>
                )}
              </ul>
            </div>

            <div className="p-4 border rounded-xl mt-4 shadow-lg">
              <h3 className="flex gap-2 mb-4 text-lg items-center">
                <FaHospital className="text-xl" />
                {t("health")}
              </h3>
              <ul>
                {nearbyHospitals.length > 0 ? (
                  nearbyHospitals.map((place, index) => (
                    <li
                      className="flex justify-between items-center w-full"
                      key={index}
                    >
                      <h4 className="w-full">• {place.properties.name}</h4>
                      <h4 className="w-full text-right">{place.distance} km</h4>
                    </li>
                  ))
                ) : (
                  <li className="w-full text-center">{t("noData")}</li>
                )}
              </ul>
            </div>

            <div className="p-4 border rounded-xl mt-4 shadow-lg">
              <h3 className="flex gap-2 mb-4 text-lg items-center">
                <PiMosqueFill className="text-xl" />
                {t("mosques")}
              </h3>
              <ul>
                {nearbyMosques.length > 0 ? (
                  nearbyMosques.map((place, index) => (
                    <li
                      className="flex justify-between items-center w-full"
                      key={index}
                    >
                      <h4 className="w-full">• {place.properties.name}</h4>
                      <h4 className="w-full text-right">{place.distance} km</h4>
                    </li>
                  ))
                ) : (
                  <li className="w-full text-center">{t("noData")}</li>
                )}
              </ul>
            </div>

            {/* Weather Display */}
            <div className="h-[1px] mt-8 mb-8 bg-gray-200"></div>
            <h2 className="mt-8 mb-6 text-xl">{t("currentweather")}</h2>
            <div
              className="p-4 text-white border rounded-xl mt-4 shadow-lg"
              style={{
                backgroundImage: `url(${weatherData.backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <ul>
                <li className="flex py-4 text-xl items-center w-full">
                  <h4 className="w-full">{weatherData.condition}</h4>
                  <h4 className="w-full justify-end flex">
                    {" "}
                    {weatherData.temperature}°C
                  </h4>
                </li>
              </ul>
            </div>

            {listing.listingType != "land" && (
              <div>
                {" "}
                <div className="h-[1px] mt-8 mb-8 bg-gray-200"></div>
                <h1 className="text-xl  mb-6"> {t("summary")}</h1>
                <ul className="list-disc">
                  {listing.listingType && (
                    <li className="flex items-center gap-2">
                      <MdOutlineVilla />
                      {listing.listingType === "apartment"
                        ? t("apartment")
                        : listing.listingType === "villa"
                        ? t("villa")
                        : listing.listingType === "riad"
                        ? t("riad")
                        : listing.listingType === "land"
                        ? t("land")
                        : listing.listingType === "office"
                        ? t("office")
                        : listing.listingType === "commercial"
                        ? t("commercial")
                        : listing.listingType === "farmhouse"
                        ? t("farmhouse")
                        : listing.listingType}{" "}
                      {listing.type === "rent" ? t("forrent") : t("forsale")}{" "}
                    </li>
                  )}{" "}
                  {listing.yearBuilt && (
                    <li className="flex items-center gap-2">
                      <MdOutlineConstruction /> {t("yearbuilt")}
                      {listing.yearBuilt}
                    </li>
                  )}
                  {listing.size > 0 && (
                    <li className="flex items-center gap-2">
                      <TbRulerMeasure /> {t("size")}
                      {listing.size} m²
                    </li>
                  )}
                  {(listing.floor ||
                    listing.floor === 0 ||
                    listing.floor === "rdc") && (
                    <li className="flex items-center gap-2">
                      <PiStairsDuotone /> {t("floor")}
                      {listing.floor === 0 ? "RDC" : `${listing.floor}`}
                    </li>
                  )}{" "}
                  {listing.bedrooms > 0 && (
                    <li className="flex items-center gap-2">
                      <LuBedDouble /> {listing.bedrooms}
                      {t("beds")}
                    </li>
                  )}
                  {listing.bathrooms > 0 && (
                    <li className="flex items-center gap-2">
                      <PiBathtubBold /> {listing.bathrooms}
                      {t("baths")}
                    </li>
                  )}
                  {listing.parking && (
                    <li className="flex items-center gap-2">
                      <LuParkingSquare /> {t("Parking")}
                    </li>
                  )}
                  {listing.furnished && (
                    <li className="flex items-center gap-2">
                      <LuSofa /> {t("furnished")}
                    </li>
                  )}
                  {listing.secured && (
                    <li className="flex items-center gap-2">
                      <GiPoliceOfficerHead /> {t("secured")}
                    </li>
                  )}
                  {listing.elevator && (
                    <li className="flex items-center gap-2">
                      <TbElevator /> {t("elevator")}
                    </li>
                  )}
                  {listing.airCondition && (
                    <li className="flex items-center gap-2">
                      <TbAirConditioning /> {t("air")}
                    </li>
                  )}
                  {listing.balcony && (
                    <li className="flex items-center gap-2">
                      <TbElevator /> {t("balcony")}
                    </li>
                  )}
                  {listing.pool && (
                    <li className="flex items-center gap-2">
                      <FaSwimmingPool /> {t("pool")}
                    </li>
                  )}
                </ul>
              </div>
            )}

            <div className="h-[1px] mt-8 mb-4 bg-gray-200"></div>

            <h1 className="mt-8 mb-6 text-xl">Contact</h1>

            <div
              ref={contactFormRef}
              className="mt-4 w-full border-gray-200 rounded-md flex justify-center  items-center"
            >
              {contactLandlord && (
                <Contact
                  userRef={listing.userRef}
                  Id={params.listingId}
                  listing={listing}
                />
              )}
            </div>
            <div className="h-[1px] mt-8 mb-4 bg-gray-200"></div>
            {listing.type === "sale" && (
              <>
                <div className="mt-10">
                  <FinancingCalculator
                    regularPrice={listing?.regularPrice || 0}
                  />
                </div>
                <div className="h-[1px] mt-8 mb-4 bg-gray-200"></div>
              </>
            )}

            <div>
              <div className="hidden sm:block md:hidden mx-auto pt-4 pb-4 ">
                <div className="flex justify-between my-2 text-2xl">
                  <h1 className="text-xl mb-6">{t("similar")}</h1>
                  <div className="flex space-x-4 justify-end">
                    <GrPrevious
                      className="cursor-pointer hover:opacity-50"
                      onClick={() => {
                        if (sliderRefSm.current) {
                          sliderRefSm.current.slickPrev();
                        }
                      }}
                    />
                    <GrNext
                      className="cursor-pointer hover:opacity-50"
                      onClick={() => {
                        if (sliderRefSm.current) {
                          sliderRefSm.current.slickNext();
                        }
                      }}
                    />
                  </div>
                </div>
                {loading ? ( // Check if loading state is true
                  <div className="mt-4 mb-6 space-x-2">
                    <div className="flex gap-2 justify-between my-2">
                      <ListingPlaceholder />
                      <ListingPlaceholder />
                    </div>
                  </div>
                ) : similarListings && similarListings.length > 0 ? (
                  <div className="mt-4 mb-6">
                    <Slider
                      ref={sliderRefSm} // Attach the ref to the Slider component
                      infinite={similarListings.length > 1} // Infinite scrolling only if more than one listing
                      arrows={false}
                      speed={500}
                      slidesToShow={1}
                      slidesToScroll={1}
                    >
                      {similarListings.map((listing) =>
                        listing.imgUrls && listing.imgUrls.length > 0 ? (
                          <ListingItem
                            key={listing.id}
                            listing={listing}
                            id={listing.id}
                          />
                        ) : (
                          <ListingPlaceholder key={listing.id} />
                        )
                      )}
                    </Slider>
                  </div>
                ) : (
                  <p className="text-center text-gray-500 mt-4 mb-4">
                    {t("noSimilarListings")}
                  </p>
                )}
              </div>

              <div className="block sm:hidden w-full">
                <div className="flex mx-auto mt-6 px-2 max-w-screen justify-between my-2 text-xl">
                  <h1 className="text-xl">{t("similar")}</h1>

                  <div className="flex mt-2 space-x-4 justify-end">
                    <GrPrevious
                      className="cursor-pointer focus:opacity-50"
                      onClick={() => {
                        if (sliderRef.current) {
                          sliderRef.current.slickPrev();
                        }
                      }}
                    />
                    <GrNext
                      className="cursor-pointer focus:opacity-50"
                      onClick={() => {
                        if (sliderRef.current) {
                          sliderRef.current.slickNext();
                        }
                      }}
                    />
                  </div>
                </div>
                {loading ? ( // Check if loading state is true
                  <div className="mt-4 mb-6 space-x-2">
                    <div className="flex gap-2 justify-between my-2">
                      <ListingPlaceholder />
                    </div>
                  </div>
                ) : similarListings && similarListings.length > 0 ? (
                  <div className="mb-8 mx-auto max-w-screen">
                    <div className="mx-auto">
                      <Slider
                        ref={sliderRef} // Attach the ref to the Slider component
                        infinite={similarListings.length > 1} // Infinite scrolling only if more than one listing
                        dots={false}
                        arrows={false}
                        speed={500}
                        slidesToShow={1}
                        slidesToScroll={1}
                      >
                        {similarListings.map((listing) =>
                          listing.imgUrls && listing.imgUrls.length > 0 ? (
                            <ListingItem
                              key={listing.id}
                              listing={listing}
                              id={listing.id}
                            />
                          ) : (
                            <ListingPlaceholder key={listing.id} />
                          )
                        )}
                      </Slider>
                    </div>
                  </div>
                ) : (
                  <p className="text-center text-gray-500 mt-4 mb-4">
                    {t("noSimilarListings")}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
