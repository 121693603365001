import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  TextField,
  Typography,
  Grid,
  CircularProgress,
  Button,
} from "@mui/material";

const FinancingCalculatorPage = () => {
  const { t } = useTranslation();
  const [propertyPrice, setPropertyPrice] = useState(0); // User-entered property price
  const [downPaymentAmount, setDownPaymentAmount] = useState(0);
  const [downPaymentPercent, setDownPaymentPercent] = useState(20);
  const [loanTerm, setLoanTerm] = useState(30);
  const [interestRate, setInterestRate] = useState(3.5);

  const calculateMonthlyPayment = () => {
    const principal = propertyPrice - downPaymentAmount;
    const monthlyInterestRate = interestRate / 100 / 12;
    const numberOfPayments = loanTerm * 12;

    const monthlyPayment =
      (principal * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

    return (Math.round(monthlyPayment * 10) / 10).toFixed(1);
  };

  const handleDownPaymentAmountChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setDownPaymentAmount(value);
    setDownPaymentPercent(((value / propertyPrice) * 100).toFixed(2));
  };

  const handleDownPaymentPercentChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setDownPaymentPercent(value);
    setDownPaymentAmount((propertyPrice * value) / 100);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("fr-MA", {
      style: "currency",
      currency: "MAD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(amount)
      .replace(".", ",");
  };

  const monthlyPayment = parseFloat(calculateMonthlyPayment());
  const taxAmount = monthlyPayment * 0.2;
  const paymentWithoutTax = monthlyPayment - taxAmount;

  return (
    <div className="max-w-7xl mb-8 md:flex gap-4 p-4 mx-auto">
      <div className="md:w-2/3 ">
        <Typography variant="h6" marginTop={4} marginBottom={2}>
          {t("financingCalculator.title")}
        </Typography>
        <Box
          className="financing-calculator flex flex-col md:flex-row items-center justify-between w-full bg-gray-50 mb-8"
          borderColor="gray.300"
          borderRadius={4}
          p={2}
        >
          <Box className="md:w-1/2">
            <Grid container spacing={2} marginTop={1} marginBottom={2}>
              {/* User Input for Property Price */}
              <Grid item xs={12}>
                <TextField
                  className="bg-white"
                  label={t("financingCalculator.propertyPriceLabel")}
                  type="number"
                  value={propertyPrice}
                  onChange={(e) => setPropertyPrice(parseFloat(e.target.value))}
                  fullWidth
                  variant="outlined"
                  inputProps={{ min: 0 }}
                />
              </Grid>

              <Grid item xs={8}>
                <TextField
                  className="bg-white"
                  label={t("financingCalculator.downPaymentLabel")}
                  type="number"
                  value={downPaymentAmount}
                  onChange={handleDownPaymentAmountChange}
                  fullWidth
                  variant="outlined"
                  inputProps={{ min: 0, max: propertyPrice }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  className="bg-white"
                  label="%"
                  type="number"
                  value={downPaymentPercent}
                  onChange={handleDownPaymentPercentChange}
                  fullWidth
                  variant="outlined"
                  inputProps={{ min: 0, max: 100 }}
                />
              </Grid>
            </Grid>

            <TextField
              className="bg-white"
              label={t("financingCalculator.loanTermLabel")}
              type="number"
              value={loanTerm}
              onChange={(e) => setLoanTerm(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              inputProps={{ min: 1 }}
            />

            <TextField
              className="bg-white"
              label={t("financingCalculator.interestRateLabel")}
              type="number"
              step="0.01"
              value={interestRate}
              onChange={(e) => setInterestRate(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              inputProps={{ min: 0 }}
            />
          </Box>

          <Box className="md:w-1/2">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop={2}
            >
              <Box position="relative" display="inline-flex">
                <CircularProgress
                  variant="determinate"
                  value={100}
                  size={145}
                  thickness={3}
                  style={{ color: "#de0000" }}
                />
                <CircularProgress
                  variant="determinate"
                  value={(taxAmount / monthlyPayment) * 100}
                  size={145}
                  thickness={3}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    color: "#ab0000",
                  }}
                />
                <Typography
                  variant="h6"
                  component="div"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  {formatCurrency(monthlyPayment)}
                </Typography>
                <Typography
                  variant="h10"
                  component="div"
                  style={{
                    position: "absolute",
                    top: "62%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  {t("monthly")}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              marginTop={1}
            >
              <Box display="flex" alignItems="center">
                <Box
                  bgcolor="#de0000"
                  borderRadius="50%"
                  width={10}
                  height={10}
                  marginRight={1}
                />
                <Typography variant="body2" style={{ marginRight: 8 }}>
                  {t("pricewithouttax")}: {formatCurrency(paymentWithoutTax)}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Box
                  bgcolor="#ab0000"
                  borderRadius="50%"
                  width={10}
                  height={10}
                  marginRight={1}
                />
                <Typography variant="body2" style={{ marginRight: 8 }}>
                  {t("tax")}: {formatCurrency(taxAmount)}
                </Typography>
              </Box>
              <p className="mt-8 px-4 text-gray-400 text-center text-small">
                {t("estimateDisclaimer")}
              </p>
              {/* Bank Link Button */}
              <div className="hidden">
                <Box display="flex" justifyContent="center" marginTop={2}>
                  <Button
                    variant="contained"
                    href="https://www.credithabitat.ma/cr%C3%A9dit-immobilier" // Replace with actual bank link
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      backgroundColor: "#de0000",
                      color: "#fff",
                      fontSize: "0.8rem", // Smaller text size
                    }}
                  >
                    {t("financingCalculator.bankOfferButton", "Discover Offer")}
                  </Button>
                </Box>
              </div>
            </Box>
          </Box>
        </Box>
        <div className="mt-4 border rounded-xl">
          <div className="p-4">
            <Typography variant="h6">
              {t("financingCalculator.propertyPriceTitle")}
            </Typography>
            <Typography variant="body2">
              {t("financingCalculator.propertyPriceDescription")}
            </Typography>

            <Typography variant="h6" marginTop={2}>
              {t("financingCalculator.downPaymentTitle")}
            </Typography>
            <Typography variant="body2">
              {t("financingCalculator.downPaymentDescription")}
            </Typography>

            <Typography variant="h6" marginTop={2}>
              {t("financingCalculator.loanTermTitle")}
            </Typography>
            <Typography variant="body2">
              {t("financingCalculator.loanTermDescription")}
            </Typography>

            <Typography variant="h6" marginTop={2}>
              {t("financingCalculator.interestRateTitle")}
            </Typography>
            <Typography variant="body2">
              {t("financingCalculator.interestRateDescription")}
            </Typography>
          </div>
        </div>
      </div>
      <div className="hidden md:w-1/3 mt-4 md:mt-20 rounded-xl">
        <div className="rounded-xl items-center p-4 bg-red-600">
          <p className="text-2xl mb-2 text-white font-demifont">
            {t("dreamHomeReady")}
          </p>
          <p className="text-md mb-8 text-white">{t("connectWithBank")}</p>
          <button
            className="bg-white p-2 rounded-xl hover:bg-gray-100 cursor-pointer"
            onClick={() =>
              window.open(
                "https://www.credithabitat.ma/crédit-immobilier",
                "_blank"
              )
            }
          >
            {t("starttheprocess")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinancingCalculatorPage;
