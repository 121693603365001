import React, { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";  // Import useNavigate
import OAuth from "../components/OAuth";
import { IoClose } from "react-icons/io5";


export default function SignIn({ onClose }) {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Initialize navigate
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { email, password } = formData;

  function onChange(e) {
    setFormData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  }

  async function onSubmit(e) {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");
    setLoading(true);

    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential.user) {
        onClose(); // Close modal when signed in
      }
    } catch (error) {
      setLoading(false);
      switch (error.code) {
        case "auth/user-not-found":
          setEmailError(t("error.userNotFound"));
          break;
        case "auth/wrong-password":
          setPasswordError(t("error.wrongPassword"));
          break;
        default:
          setPasswordError(t("error.incorrectCredentials"));
      }
    }
  }
  

  return (
    <div className="fixed z-50 inset-0 px-2 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <button
          className="text-2xl ml-auto flex justify-end"
          onClick={() => navigate(-1)} // Go back to the previous page
        >
          <IoClose/>
        </button>
        <h2 className="text-lg md:text-2xl mb-4 text-center">{t("signin")}</h2>

        <form onSubmit={onSubmit} className="space-y-4">
          <input
            type="email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            placeholder="Email"
            className="w-full px-4 py-2 border rounded"
          />
          {emailError && <p className="text-red-500 text-sm">{emailError}</p>}

          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              value={formData.password}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
              placeholder={t("password")}
              className="w-full px-4 py-2 border rounded"
            />
            <button
              type="button"
              className="absolute right-3 top-2 text-gray-500"
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {showPassword ? (
                <AiFillEye className="absolute top-1 right-3 text-md cursor-pointer" />
              ) : (
                <AiFillEyeInvisible className="absolute top-1 right-3 text-md cursor-pointer" />
              )}
            </button>
          </div>
          {passwordError && (
            <p className="text-red-500 text-sm">{passwordError}</p>
          )}

          <button
            type="submit"
            className="w-full bg-custom-red text-white py-2 rounded"
          >
            <button className="signin-button" disabled={loading}>
              {loading ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    margin: "auto",
                    background: "none",
                    display: "block",
                    shapeRendering: "auto",
                    width: "24px", // Adjusted for button size
                    height: "24px",
                  }}
                  viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid"
                >
                  <circle
                    strokeLinecap="round"
                    fill="none"
                    strokeDasharray="54.97787143782138 54.97787143782138"
                    stroke="#fff" // Adjust color if needed
                    strokeWidth="8"
                    r="35"
                    cy="50"
                    cx="50"
                  >
                    <animateTransform
                      values="0 50 50;360 50 50"
                      keyTimes="0;1"
                      dur="0.65s"
                      repeatCount="indefinite"
                      type="rotate"
                      attributeName="transform"
                    />
                  </circle>
                </svg>
              ) : (
                t("signin")
              )}
            </button>
          </button>
          <p className=" mx-auto flex justify-center">
            <Link
              to="/sign-up"
              className="text-black hover:text-red-700 transition duration-200 ease-in-out text-sm md:text-base"
            >
              {" "}
              {t("createnewaccount")}{" "}
            </Link>
          </p>
          <div className="flex items-center my-4 before:border-t before:flex-1 before:border-gray-300 after:border-t after:flex-1 after:border-gray-300">
            <p
              className="text-center
        font-semibold mx-4"
            >
              {t("or")}
            </p>
          </div>
          <OAuth />
        </form>
      </div>
    </div>
  );
}
